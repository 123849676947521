import {createMuiTheme} from '@material-ui/core';
import {ThemeOptions} from '@material-ui/core/styles/createMuiTheme';

export default createMuiTheme({
  palette: {
    primary: {
      50: '#FEE1E1',
      100: '#FDB5B5',
      200: '#FC8484',
      300: '#FB5252',
      400: '#FA2D2D',
      500: '#F90808',
      600: '#F80707',
      700: '#F70606',
      800: '#F60404',
      900: '#F50202',
      A100: '#FFFFFF',
      A200: '#FFE8E8',
      A400: '#FFB5B5',
      A700: '#FF9C9C',
    },
  },
  overrides: {},
} as ThemeOptions);
