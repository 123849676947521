import React, {FC, useEffect, useState} from 'react';
import {Redirect, Route, Switch} from 'react-router-dom';
import Header from './Header/Header';
import PersonalData from './PersonalData/PersonalData';
import './Admin.scss';
import LeftMenu from './LeftMenu/LeftMenu';
import PaymentData from './PaymentData/PaymentData';
import PromotionsData from './PromotionsData/PromotionsData';
import Auth from '../Providers/Auth';
import AdminContext from './AdminContext';
import Invoices from './Invoices/Invoices';
import ReferralProgram from './ReferralProgram/ReferralProgram';
import axios from 'axios';
import HeadNotify from '../Core/Form/HeadNotify/HeadNotify';
import NotFound from './NotFound/NotFound';
import IUser from '../Interfaces/IUser';
import {IHttpResponse} from '../Interfaces/IHttpResponse';
import Config from '../Providers/Config';
import Tracking from './Tracking/Tracking';
import TrackingForm from './Tracking/TrackingForm';
import CustomerZoneAuto from './CustomerZoneAuto/CustomerZoneAuto';
import CustomerZoneAutoForm from './CustomerZoneAuto/CustomerZoneAutoForm';
import CustomerZoneBalance from './CustomerZoneBalance/CustomerZoneBalance';
import CustomerZoneBalanceForm from './CustomerZoneBalance/CustomerZoneBalanceForm';
import InvoicesForm from './Invoices/InvoicesForm';
import Roles from './Roles/Roles';
import RolesForm from './Roles/RolesForm';
import {UserPermission} from '../Providers/UserPermission';
import CustomerZoneAutoView from './CustomerZoneAuto/CustomerZoneAutoView';
import CustomerZoneBalanceView from './CustomerZoneBalance/CustomerZoneBalanceView';
import IRole from '../Interfaces/IRole';
import Calculator from './Calculator/Calculator';
import {connect, useDispatch} from 'react-redux';
import setMobileMenu from '../Core/Store/Actions/App/setMobileMenu';
import setHeadNotify from '../Core/Store/Actions/App/setHeadNotify';
import {useTranslation} from 'react-i18next';
import UserStatus from '../Providers/UserStatus';
import Users from './Users/Users';
import UsersView from './Users/UsersView';
import UsersForm from './Users/UsersForm';
import BuyerIds from './BuyerIds/BuyerIds';
import BuyerIdsForm from './BuyerIds/BuyerIdsForm';
import BuyerIdsView from './BuyerIds/BuyerIdsView';

const Admin: FC<{
  appStore: any,
  setMobileMenu: any
}> = ({appStore, setMobileMenu}) => {
  const {t} = useTranslation();
  const dispatch = useDispatch();
  const [auth, setAuth] = useState(new Auth());
  const [ready, setReady] = useState(false);
  const [adminContextValue, setAdminContextValue] = useState(null);

  useEffect(() => {
    async function getUser() {
      return new Promise((resolve, reject) => {
        axios.get(Config.api('/user/get'))
          .then((_response) => {
            const response: IHttpResponse = _response.data;

            if (response.code !== 200) {
              console.error(response.message);
              reject();

              return;
            }

            resolve(IUser.parse(response.data));
          });
      });
    }

    //  Configure axios
    axios.defaults.headers.common = {
      'Authorization': `Bearer ${auth.token()}`,
      'Accept': 'application/json'
    };

    //  Interceptors
    axios.interceptors.response.use(null, (error) => {
      //  Unauthorized handler
      if (error?.response?.status === 401) {
        auth.clear();
        window.location.href = '/';

        return;
      }

      return Promise.reject(error);
    });

    getUser().then((user: IUser) => {
      const auth = new Auth();
      auth.user = user;

      setAuth(auth);
      setAdminContextValue({
        auth
      });
      setReady(true);

      // Show notify to user about not active account
      if (auth.user.status === UserStatus.NOT_ACTIVE)
        dispatch(setHeadNotify([{
          title: t('admin:user.account_not_active_title'),
          text: t('admin:user.account_not_active_description')
        }]));
    });
    // eslint-disable-next-line
  }, []);

  return (
    <div
      id="admin-component"
      className="d-flex w-100 admin-component"
    >
      {ready && <AdminContext.Provider value={adminContextValue}>
        <Switch>
          <>
            <LeftMenu
                appStore={appStore}
                setMobileMenu={setMobileMenu}
                className="flex-shrink-0"
            />

            <div className="main-content-wrap flex-grow-1 overflow-hidden">
              <Header setMobileMenu={setMobileMenu} />

              <div className="main-content">
                {appStore.headNotify?.length > 0 && <div>
                  <HeadNotify messages={appStore.headNotify} />
                </div>}

                <Switch>
                  {auth.user.role.permissions.indexOf(UserPermission.PROMOTIONS) !== -1 ?
                    <Route
                      exact={true}
                      path="/admin"
                      render={() => (
                        <Redirect to="/admin/promotions" />
                      )}
                    />
                    :
                    <Route
                      exact={true}
                      path="/admin"
                      render={() => (
                        <Redirect to="/admin/personal-data" />
                      )}
                    />
                  }

                  <Route
                      exact={true}
                      path="/admin/personal-data"
                  >
                    <PersonalData />
                  </Route>

                  {/*Payment Data*/}
                  {
                    IRole.checkPermission(auth.user.role, [
                      UserPermission.PAYMENT_DATA
                    ])
                    &&
                    <Route
                        exact={true}
                        path="/admin/payment-data"
                    >
                      <PaymentData />
                    </Route>
                  }

                  {/*Promotions*/}
                  {
                    IRole.checkPermission(auth.user.role, [
                      UserPermission.PROMOTIONS
                    ])
                    &&
                    <Route
                        exact={true}
                        path="/admin/promotions"
                    >
                      <PromotionsData />
                    </Route>
                  }

                  {/*region Invoices*/}
                  {
                    IRole.checkPermission(auth.user.role, [
                      UserPermission.INVOICES_FULL,
                      UserPermission.INVOICES_READONLY
                    ])
                    &&
                    <Route
                        exact={true}
                        path="/admin/invoices"
                    >
                      <Invoices />
                    </Route>
                  }

                  {
                    IRole.checkPermission(auth.user.role, [
                      UserPermission.INVOICES_FULL
                    ])
                    &&
                    <Route
                        exact={true}
                        path={['/admin/invoices/form', '/admin/invoices/form/:id']}
                    >
                      <InvoicesForm />
                    </Route>
                  }
                  {/*endregion*/}

                  {/*region Buyer IDs*/}
                  {
                    IRole.checkPermission(auth.user.role, [
                      UserPermission.BUYER_ID_FULL,
                      UserPermission.BUYER_ID_READONLY,
                    ])
                    &&
                    <Route
                        exact={true}
                        path="/admin/buyer-ids"
                    >
                      <BuyerIds />
                    </Route>
                  }

                  {
                    IRole.checkPermission(auth.user.role, [
                      UserPermission.BUYER_ID_FULL,
                      UserPermission.BUYER_ID_READONLY,
                    ])
                    &&
                    <Route
                        exact={true}
                        path={['/admin/buyer-ids/view/:id']}
                    >
                      <BuyerIdsView />
                    </Route>
                  }

                  {
                    IRole.checkPermission(auth.user.role, [
                      UserPermission.BUYER_ID_FULL,
                    ])
                    &&
                    <Route
                        exact={true}
                        path={['/admin/buyer-ids/form', '/admin/buyer-ids/form/:id']}
                    >
                      <BuyerIdsForm />
                    </Route>
                  }
                  {/*endregion*/}

                  {/*region Roles*/}
                  {
                    IRole.checkPermission(auth.user.role, [])
                    &&
                    <Route
                        exact={true}
                        path="/admin/roles"
                    >
                      <Roles />
                    </Route>
                  }

                  {
                    IRole.checkPermission(auth.user.role, [])
                    &&
                    <Route
                        exact={true}
                        path={['/admin/roles/form', '/admin/roles/form/:id']}
                    >
                      <RolesForm />
                    </Route>
                  }
                  {/*endregion*/}

                  {/*region Users*/}
                  {
                    IRole.checkPermission(auth.user.role, [
                      UserPermission.USERS_FULL,
                      UserPermission.USERS_READONLY
                    ])
                    &&
                    <Route
                        exact={true}
                        path="/admin/users"
                    >
                      <Users />
                    </Route>
                  }

                  {
                    IRole.checkPermission(auth.user.role, [
                      UserPermission.USERS_FULL,
                      UserPermission.USERS_READONLY,
                    ])
                    &&
                    <Route
                        exact={true}
                        path={['/admin/users/view/:id']}
                    >
                      <UsersView />
                    </Route>
                  }

                  {
                    IRole.checkPermission(auth.user.role, [
                      UserPermission.USERS_FULL
                    ])
                    &&
                    <Route
                        exact={true}
                        path={['/admin/users/form', '/admin/users/form/:id']}
                    >
                      <UsersForm />
                    </Route>
                  }
                  {/*endregion*/}

                  {/*Referral Program*/}
                  {
                    IRole.checkPermission(auth.user.role, [
                      UserPermission.REFERRAL_PROGRAM_FULL,
                      UserPermission.REFERRAL_PROGRAM_READONLY
                    ])
                    &&
                    <Route
                        exact={true}
                        path="/admin/referral-program"
                    >
                      <ReferralProgram />
                    </Route>
                  }

                  {/*region Tracking*/}
                  {
                    IRole.checkPermission(auth.user.role, [
                      UserPermission.TRACKINGS_FULL,
                      UserPermission.TRACKINGS_READONLY
                    ])
                    &&
                    <Route
                        exact={true}
                        path="/admin/tracking"
                    >
                      <Tracking />
                    </Route>
                  }

                  {
                    IRole.checkPermission(auth.user.role, [
                      UserPermission.TRACKINGS_FULL
                    ])
                    &&
                    <Route
                        exact={true}
                        path={['/admin/tracking/form', '/admin/tracking/form/:id']}
                    >
                      <TrackingForm />
                    </Route>
                  }
                  {/*endregion*/}

                  {/*region Calculator*/}
                  {
                    IRole.checkPermission(auth.user.role, [
                      UserPermission.CALCULATOR
                    ])
                    &&
                    <Route
                        exact={true}
                        path={['/admin/calculator']}
                    >
                      <Calculator />
                    </Route>
                  }
                  {/*endregion*/}

                  {/*region Customer Zone Auto*/}
                  {
                    IRole.checkPermission(auth.user.role, [
                      UserPermission.CUSTOMER_ZONE_AUTOS_FULL,
                      UserPermission.CUSTOMER_ZONE_AUTOS_READONLY
                    ])
                    &&
                    <Route
                        exact={true}
                        path="/admin/customer-zone/auto"
                    >
                      <CustomerZoneAuto />
                    </Route>
                  }

                  {
                    IRole.checkPermission(auth.user.role, [
                      UserPermission.CUSTOMER_ZONE_AUTOS_READONLY
                    ])
                    &&
                    <Route
                        exact={true}
                        path={['/admin/customer-zone/auto/view/:id']}
                    >
                      <CustomerZoneAutoView />
                    </Route>
                  }

                  {
                    IRole.checkPermission(auth.user.role, [
                      UserPermission.CUSTOMER_ZONE_AUTOS_FULL
                    ])
                    &&
                    <Route
                        exact={true}
                        path={['/admin/customer-zone/auto/form', '/admin/customer-zone/auto/form/:id']}
                    >
                      <CustomerZoneAutoForm />
                    </Route>
                  }
                  {/*endregion*/}

                  {/*region Customer Zone Balance*/}
                  {
                    IRole.checkPermission(auth.user.role, [
                      UserPermission.CUSTOMER_ZONE_BALANCES_FULL,
                      UserPermission.CUSTOMER_ZONE_BALANCES_READONLY
                    ])
                    &&
                    <Route
                        exact={true}
                        path="/admin/customer-zone/balance"
                    >
                      <CustomerZoneBalance />
                    </Route>
                  }

                  {
                    IRole.checkPermission(auth.user.role, [
                      UserPermission.CUSTOMER_ZONE_BALANCES_READONLY
                    ])
                    &&
                    <Route
                        exact={true}
                        path={['/admin/customer-zone/balance/view/:id']}
                    >
                      <CustomerZoneBalanceView />
                    </Route>
                  }

                  {
                    IRole.checkPermission(auth.user.role, [
                      UserPermission.CUSTOMER_ZONE_BALANCES_FULL
                    ])
                    &&
                    <Route
                        exact={true}
                        path={['/admin/customer-zone/balance/form', '/admin/customer-zone/balance/form/:id']}
                    >
                      <CustomerZoneBalanceForm />
                    </Route>
                  }
                  {/*endregion*/}

                  <Route
                      path="/admin/*"
                  >
                    <NotFound />
                  </Route>
                </Switch>
              </div>
            </div>
          </>
        </Switch>
      </AdminContext.Provider>}
    </div>
  );
}

const mapStateToProps = state => ({
  appStore: state.app
});
const mapDispatchToProps = dispatch => ({
  setMobileMenu: className => dispatch(setMobileMenu(className)),
});

export default connect(mapStateToProps, mapDispatchToProps)(Admin);
