import React, {FC, useEffect, useState} from 'react';
import './NewPassword.scss';
import {useDispatch} from 'react-redux';
import {useTranslation} from 'react-i18next';
import {Link, useHistory, useParams} from 'react-router-dom';
import Input from '../../Core/Form/Input/Input';
import Errors from '../../Core/Form/Errors/Errors';
import axios from 'axios';
import Config from '../../Providers/Config';
import {IHttpResponse} from '../../Interfaces/IHttpResponse';
import setBgImg from '../../Core/Store/Actions/Login/setBgImg';

interface INewPasswordState {

  errors: {
    error: string[]
    code: string[]
  }

}

const NewPassword: FC = () => {
  const {t} = useTranslation();
  const code = useParams().code;
  const history = useHistory();
  const dispatch = useDispatch();
  const [password, setPassword] = useState('');
  const [errors, setErrors] = useState(null);

  useEffect(() => {
    dispatch(setBgImg('/assets/images/sign-up/sign-up-bg-1.jpg'));
    // eslint-disable-next-line
  }, []);

  function onSetNewPassword(e): void {
    e.preventDefault();

    axios.post(Config.api('/auth/reset-password'), {
      code,
      password
    })
      .then((_response) => {
        const response: IHttpResponse = _response.data;

        if (response.code !== 200) {
          console.error(response.message);
          setErrors(response.data);

          return;
        }

        history.push(`/`);
      });
  }

  return (
    <div id="new-password-component">
      <div className="content">
        <Link
          to="/"
          className="go-back"
        >
          <img
            src={'/assets/images/arrow-back.png'}
            alt="arrow-back"
            height={9}
          />
          <span>
            {t('login:new_password.go_back')}
          </span>
        </Link>

        <div className="header">
          <h5 className="title">
            {t('login:new_password.title')}
          </h5>
          <p className="subtitle">
            {t('login:new_password.subtitle')}
          </p>
        </div>

        <form
          className="form"
          autoComplete="off"
          onSubmit={onSetNewPassword}
        >
          <Input
            label={t('login:new_password.password_label')}
            input={{
              type: 'password',
              name: 'password',
              id: 'password',
              placeholder: t('login:new_password.password_placeholder'),
              autoComplete: 'off',
              onChange: e => setPassword(e.target.value)
            }}
          />

          <Errors
            errors={errors}
            nested={true}
          />

          <div>
            <button className="w-100 th-submit">
              {t('login:new_password.set_password_btn')}
            </button>
          </div>
        </form>
        <Link
          to="/"
          className="go-back-mobile"
        >
          <img
            src={'/assets/images/arrow-back.png'}
            alt="arrow-back"
            height={9}
          />
          <span>
            {t('login:forgot_password.go_back')}
          </span>
        </Link>
      </div>
    </div>
  );
};

export default NewPassword;
