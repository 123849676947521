import React, {FC, useEffect, useState} from 'react';
import './ForgotPassword.scss';
import {useDispatch} from 'react-redux';
import {useTranslation} from 'react-i18next';
import Input from '../../Core/Form/Input/Input';
import {Link, useHistory} from 'react-router-dom';
import axios from 'axios';
import Config from '../../Providers/Config';
import {IHttpResponse} from '../../Interfaces/IHttpResponse';
import Errors from '../../Core/Form/Errors/Errors';
import setBgImg from '../../Core/Store/Actions/Login/setBgImg';

const ForgotPassword: FC = () => {
  const {t} = useTranslation();
  const history = useHistory();
  const dispatch = useDispatch();
  const [email, setEmail] = useState('');
  const [errors, setErrors] = useState(null);

  useEffect(() => {
    dispatch(setBgImg('/assets/images/sign-up/sign-up-bg-1.jpg'));
    // eslint-disable-next-line
  }, []);

  function onGetNewPassword(e): void {
    if (!e.target.checkValidity())
      return;

    e.preventDefault();

    axios.post(Config.api('/auth/forgot-password'), {email})
      .then((_response) => {
        const response: IHttpResponse = _response.data;

        if (response.code !== 200) {
          console.error(response.message);
          setErrors(response.data);

          return;
        }

        history.push(`/sent-password/${email}`);
      });
  }

  return (
    <div id="forgot-password">
      <div className="content">
        <Link
          to="/"
          className="go-back"
        >
          <img
            src={'/assets/images/arrow-back.png'}
            alt="arrow-back"
            height={9}
          />
          <span>
            {t('login:forgot_password.go_back')}
          </span>
        </Link>

        <div className="header">
          <h5 className="title">
            {t('login:forgot_password.title')}
          </h5>
          <p className="subtitle">
            {t('login:forgot_password.subtitle')}
          </p>
          <p className="subtitle">
            {t('login:forgot_password.subtitle_2')}
          </p>
        </div>

        <form
          className="form"
          autoComplete="off"
          onSubmit={onGetNewPassword}
        >
          <Input
            label="Email"
            input={{
              type: 'email',
              name: 'email',
              id: 'email',
              placeholder: 'john@gmail.com',
              autoComplete: 'off',
              required: true,
              onChange: e => setEmail(e.target.value)
            }}
          />
          <Errors errors={errors?.email} />

          <div>
            <button className="w-100 th-submit">
              {t('login:forgot_password.get_new_password')}
            </button>
          </div>
        </form>
        <Link
          to="/"
          className="go-back-mobile"
        >
          <img
            src={'/assets/images/arrow-back.png'}
            alt="arrow-back"
            height={9}
          />
          <span>
            {t('login:forgot_password.go_back')}
          </span>
        </Link>
      </div>
    </div>
  );
};

export default ForgotPassword;
