import React, {FC, useRef, useState} from 'react';
import './Share.scss';
import {useTranslation} from 'react-i18next';
import Input from '../../Core/Form/Input/Input';
import Notify from '../../Core/Ui/Notify/Notify';
import axios from 'axios';
import {IHttpResponse} from '../../Interfaces/IHttpResponse';
import Config from '../../Providers/Config';

const Share: FC<{
  className?: string;
  style?: any;
  module: string;
  share: any;
}> = (props) => {
  const {t} = useTranslation();
  const _eForm = useRef<HTMLFormElement>();
  const [email, setEmail] = useState('');
  const [saveNotifyType, setSaveNotifyType] = useState<string>();
  const [saveNotify, setSaveNotify] = useState(false);
  const [saveNotifyMessage, setSaveNotifyMessage] = useState();
  const [saveProcess, setSaveProcess] = useState(false);

  function onSubmit (e) {
    if (!_eForm.current.checkValidity())
      return;

    e.preventDefault();

    //  Set `Save Process` flag
    setSaveNotify(false);
    setSaveProcess(true);

    const formData = new FormData();
    //  Share
    formData.set('share', String(props.share));
    //  Module
    formData.set('module', String(props.module));
    //  Email
    formData.append('email', email);

    axios.post(Config.api('/share/add'), formData)
      .then((_response) => {
        const response: IHttpResponse = _response.data;

        setEmail('');
        setSaveNotify(true);
        setSaveProcess(false);

        if (response.code !== 200) {
          //  Unset `Save Process` flag
          setSaveNotifyType('error');
          setSaveNotifyMessage(response.data);

          return;
        }

        //  Unset `Save Process` flag
        setSaveNotifyType(null);
        setSaveNotifyMessage(null);
      });
  }

  return (
    <div
      id={'share-component'}
      className={`${props.className ?? ''}`}
      style={props.style}
    >
      <h3 className={'title'}>
        {t('admin:share.title')}
      </h3>
      <div className="content">
        <form
          ref={_eForm}
          onSubmit={onSubmit}
        >
          <div className="row">
            <div className="col-12 col-md-6">
              {/*Email*/}
              <Input
                label={t('admin:share.email')}
                input={{
                  type: 'email',
                  name: 'email',
                  id: 'email',
                  value: email,
                  required: true,
                  onChange: e => setEmail(e.target.value)
                }}
                bottomOffset={7}
              />
            </div>
          </div>

          <div className="row">
            <div className={'col-12 col-md-6'}>
              {/*Share notify*/}
              <Notify
                type={saveNotifyType ? saveNotifyType : 'save-form'}
                message={saveNotifyMessage}
                hide={!saveNotify}
              />
            </div>
          </div>

          <div className="row">
            <div className="col-12 col-md-6">
              {/*Save*/}
              <button
                type="submit"
                className="btn th-submit"
                disabled={saveProcess}
              >
                {t('admin:share.submit')}
              </button>
            </div>
          </div>
        </form>
      </div>
    </div>
  );
};

export default Share;
