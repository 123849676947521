import React from 'react';
import './Textarea.scss';
import FormControl from '@material-ui/core/FormControl';
import {FormHelperText, InputLabel, TextareaAutosize} from '@material-ui/core';

export default function InputComponent(props) {
  const components = props.components || {};

  return (
    <div
      className="form-field textarea"
      style={{
        ...props.style,
        marginBottom: props.bottomOffset
      }}
    >
      <div className="field">
        {props.hideLabel ?
          <FormControl error={props.error}>
            {components.textarea ? components.textarea :
              <TextareaAutosize
                {...props.textarea}
              />
            }
            <FormHelperText>
              {props.helperText}
            </FormHelperText>
          </FormControl>
        :
          <FormControl error={props.error}>
            <div className="label">
              {components.label ? components.label :
                <InputLabel shrink htmlFor="bootstrap-input">
                  {props.label}
                </InputLabel>
              }
            </div>
            {components.textarea ? components.textarea :
              <TextareaAutosize
                {...props.textarea}
              />
            }
            <FormHelperText>
              {props.helperText}
            </FormHelperText>
          </FormControl>
        }
      </div>
    </div>
  );
}
