import React from 'react';
import './Input.scss';
import FormControl from '@material-ui/core/FormControl';
import {FormHelperText, InputBase, InputLabel} from '@material-ui/core';

export default function InputComponent(props) {
  const components = props.components || {};

  return (
    <div
      className="form-field input"
      style={{
        ...props.style,
        marginBottom: props.bottomOffset
      }}
    >
      <div className="field">
        {props.hideLabel ?
          <FormControl error={props.error}>
            {components.input ? components.input :
              <InputBase
                {...props.input}
              />
            }
            <FormHelperText>
              {props.helperText}
            </FormHelperText>
          </FormControl>
        :
          <FormControl error={props.error}>
            <div className="label">
              {components.label ? components.label :
                <InputLabel shrink htmlFor="bootstrap-input">
                  {props.label}
                </InputLabel>
              }
            </div>
            {components.input ? components.input :
              <InputBase
                {...props.input}
              />
            }
            <FormHelperText>
              {props.helperText}
            </FormHelperText>
          </FormControl>
        }
      </div>
    </div>
  );
}
