import React, {FC, useCallback, useContext, useMemo, useRef, useState} from 'react';
import {useTranslation} from 'react-i18next';
import './Tracking.scss';
import TrackingProvider from '../../Providers/Tracking';
import AdminContext from '../AdminContext';
import IRole from '../../Interfaces/IRole';
import {UserPermission} from '../../Providers/UserPermission';
import Head from '../Head/Head';
import {RowActionMenuItemEdit, RowActionMenuItemView} from '../../Core/Form/Table/useRowActions';
import Table, {useRowActions} from '../../Core/Form/Table/Table';
import Config from '../../Providers/Config';
import Input from '../../Core/Form/Input/Input';
import Select from "../Invoices/Invoices";
import DownArrowIndicator from "../../Core/Form/Select/Indicators/DownArrowIndicator";
import {SelectStylesHideIndicatorSeparator} from "../../Core/Form/Select/Select";

const trackingProvider = new TrackingProvider();

const Tracking: FC = () => {
  const {t} = useTranslation();
  const [filter, setFilter] = useState<{
    vin?: string;
  }>({
    vin: '',
  });
  const context = useContext(AdminContext);
  const columns = useMemo(() => [
    {
      Header: t('admin:tracking.col_created_at'),
      accessor: 'createdAt',
      Cell: ({value}) => value?.format(Config.DATE_FORMAT) ?? ''
    },
    {
      Header: t('admin:tracking.tracking_id'),
      accessor: 'code'
    },
    {
      Header: t('admin:tracking.vin'),
      accessor: 'customerZoneAuto.data.vin'
    },
    {
      Header: t('admin:tracking.col_year_maker_model'),
      accessor: 'customerZoneAuto.data',
      id: 'year_maker_model',
      Cell: ({value}) => `${value.year} ${value.maker} ${value.model}`
    },
    {
      Header: t('admin:tracking.container_number'),
      accessor: 'customerZoneAuto.data.containerNumber',
    },
    {
      Header: t('admin:tracking.booking_number'),
      accessor: 'customerZoneAuto.data.bookingNumber',
    },
    // eslint-disable-next-line
  ], []);
  const [data, setData] = useState([]);
  const [pageCount, setPageCount] = useState(0);
  const [count, setCount] = useState(0);
  const fetchIdRef = useRef(0);
  const _tableRef = useRef<any>();
  const [loading, setLoading] = useState(false);
  const menuItems = useMemo(() => {
    const items = [];

    // Edit
    if (IRole.checkPermission(context.auth.user.role, [
      UserPermission.TRACKINGS_FULL
    ])) {
      items.push(<RowActionMenuItemEdit
        link={rowIndex => {
          const table = _tableRef.current;
          const row = table.page[rowIndex].original;

          return {
            to: `/admin/tracking/form/${row.id}`
          };
        }}
      />);
    }

    // View
    items.push(<RowActionMenuItemView
      href={rowIndex => {
        const table = _tableRef.current;
        const row = table.page[rowIndex].original;

        return {
          href: `https://gr8logistics.com/tracking/?tracking_code=${row.code}`,
          target: '_blank'
        };
      }}
    />)

    return items;
    // eslint-disable-next-line
  }, []);

  // Get table data
  const fetchData = useCallback(({pageIndex, pageSize, vin =''}) => {
    const fetchId = ++fetchIdRef.current;
    setLoading(true);

    if (fetchId !== fetchIdRef.current)
      return;

    (async () => {
      const list = (await trackingProvider.list({
        page_index: pageIndex,
        page_size: pageSize,
        vin:vin
      }));

      if (!list)
        return;

      setData(list.data);
      setCount(list.count);
      setPageCount(Math.ceil(list.count / pageSize));
      setLoading(false);
    })();
  }, []);

  return (
    <div id="tracking-table-component">
      <Head head={t('admin:tracking.head')} />

      <div className="row table-filter">




        <div className="col-12 col-md-6 col-lg-4 col-xl-3">
          <Input
              hideLabel={true}
              input={{
                placeholder: t('admin:filter.vin'),
                onChange: e => setFilter({...filter, vin: e.target.value})
              }}
          />
        </div>
        <div className="col-12 col-md-6 col-lg-2 col-xl-2 d-flex justify-content-center justify-content-md-start">
          <button
              className="btn btn-submit mb-control"
              onClick={() => {
                fetchData({pageIndex:0,pageSize:20,vin: filter.vin})
              }}
          >
            {t('buttons.search')}
          </button>
        </div>
      </div>

      <Table
        tableRef={_tableRef}
        table={{
          columns,
          data,
          initialState: {pageIndex: 0},
          manualPagination: true,
          pageCount
        }}
        count={count}
        tableHooks={[
          useRowActions({
            menuItems
          }),
        ].reverse()}
        fetchData={fetchData}
        loading={loading}
        toolbar={{
          top: {
            right: <>
              {IRole.checkPermission(context.auth.user.role, [
                UserPermission.TRACKINGS_FULL
              ]) && <div className="row">
                <div className="col-12 d-flex justify-content-end train-buttons"/>
              </div>}
            </>
          }
        }}
      />
    </div>
  );
}

export default Tracking;
