import React, {FC} from 'react';
import './ViewFiles.scss';

const ViewFiles: FC<{
  className?: string;
  style?: any;
  label: string;
  value: {
    url: string;
    originalName: string;
  }[];
  originalKeyName?: string;
}> = (props) => {
  function getOriginalKeyName(): string {
    return props.originalKeyName ? props.originalKeyName : 'originalName';
  }

  /**
   * Return file type
   *
   * @param file
   * @returns {string}
   */
  function type(file): string {
    //  Detect type
    if (file instanceof File) {
      if (file.type.match('image.*')) {
        return 'image';
      }
    } else if (typeof file === 'string') {
      const fileExt = ext(file);

      if ([
        'jpeg',
        'jpg',
        'png',
        'gif',
        'webp'
      ].indexOf(fileExt) !== -1) {
        return 'image';
      }
    }

    return null;
  }

  /**
   * Return file extension
   *
   * @param filename
   * @returns {string}
   */
  function ext(filename): string {
    if (!filename || typeof filename !== 'string') {
      return null;
    }

    let ext: any = filename.split(/#|\?/)[0].split('/').splice(-1, 1)[0].split('.');

    if (ext.length < 2) {
      return null;
    }

    ext = ext.pop().trim();
    ext = ext ? ext : null;

    return ext;
  }

  /**
   * Return file base name
   *
   * @param filename
   * @returns {string}
   */
  function basename(filename): string {
    if (!filename || typeof filename !== 'string') {
      return null;
    }

    return filename.split(/#|\?/)[0].split('/').splice(-1, 1)[0].split('.')[0];
  }

  /**
   * Return short basename
   *
   * @param filename
   * @returns {string}
   */
  function shortBasename(filename): string {
    let fileBasename = basename(filename);

    if (!fileBasename || typeof fileBasename !== 'string') {
      return null;
    }

    fileBasename = fileBasename.length > 35 ? fileBasename.substr(0, 34) + '...' : fileBasename;

    return fileBasename;
  }

  const files = (props.value ? props.value : []).map((file) => {
    return {
      url: file.url,
      type: type(file.url),
      originalName: file[getOriginalKeyName()],
      shortName: `${shortBasename(file[getOriginalKeyName()])}.${ext(file[getOriginalKeyName()])}`
    }
  });

  return (
    <div
      className={`view-value ${props.className ?? ''}`}
      style={props.style}
    >
      <div className="label">
        {props.label}
      </div>
      <div className="value">
        <div className="files">
          {files.map((file, i) => (
            <a
              key={i}
              href={file.url}
              target={'_blank'}
              className={'file'}
              rel="noopener noreferrer"
            >
              <div className="preview">
                {file.type === 'image' &&
                <div className="preview-img">
                  <img
                      src={file.url}
                      width={150}
                      alt={file.originalName}
                  />
                </div>}
                {!file.type && <div className="preview-doc">
                  <span className={'name'}>{file.shortName}</span>
                  <img
                      src={'/assets/images/icon-metal-paper-clip.svg'}
                      alt={file.originalName}
                  />
                </div>}
              </div>
            </a>
          ))}
        </div>
      </div>
    </div>
  );
}

export default ViewFiles;
