import React, {FC} from 'react';
import {Redirect} from 'react-router-dom';

const NotFound: FC = () => {
  return (
    <>
      <Redirect to="/" />
    </>
  );
};

export default NotFound;
