import React, {FC, useCallback, useMemo, useRef, useState} from 'react';
import {useTranslation} from 'react-i18next';
import {Link} from 'react-router-dom';
import './Roles.scss';
import RoleProvider from '../../Providers/Role';
import Head from '../Head/Head';
import {RowActionMenuItemEdit, RowActionMenuItemRemove} from '../../Core/Form/Table/useRowActions';
import Table, {useRowActions, useRowSelection} from '../../Core/Form/Table/Table';

const roleProvider = new RoleProvider();

const Roles: FC = () => {
  const {t} = useTranslation();
  const columns = useMemo(() => [
    {
      Header: t('admin:roles.name'),
      accessor: 'displayName',
    },
    // eslint-disable-next-line
  ], []);
  const [data, setData] = useState([]);
  const [pageCount, setPageCount] = useState(0);
  const [count, setCount] = useState(0);
  const fetchIdRef = useRef(0);
  const _tableRef = useRef<any>();
  const [loading, setLoading] = useState(false);
  const menuItems = useMemo(() => {
    const items = [];

    // Edit
    items.push(<RowActionMenuItemEdit
      link={rowIndex => {
        const table = _tableRef.current;
        const row = table.page[rowIndex].original;

        return {
          to: `/admin/roles/form/${row.id}`
        };
      }}
    />);

    // Remove
    items.push(<RowActionMenuItemRemove
      menuItem={{
        onClick: (e, rowIndex) => {
          const table = _tableRef.current;
          let pageIndex = table.state.pageIndex;
          const row = table.page[rowIndex].original;

          if (table.page.length === 1)
            pageIndex = --pageIndex < 0 ? 0 : pageIndex;

          roleProvider.delete(row.id).then(() => {
            fetchData({pageIndex, pageSize: table.state.pageSize});
          });
        }
      }}
    />);

    return items;
    // eslint-disable-next-line
  }, []);

  // Get table data
  const fetchData = useCallback(({pageIndex, pageSize}) => {
    const fetchId = ++fetchIdRef.current;
    setLoading(true);

    if (fetchId !== fetchIdRef.current)
      return;

    (async () => {
      const list = (await roleProvider.list({
        page_index: pageIndex,
        page_size: pageSize
      }));

      if (!list)
        return;

      setData(list.data);
      setCount(list.count);
      setPageCount(Math.ceil(list.count / pageSize));
      setLoading(false);
    })();
  }, []);

  return (
    <div id="roles-table-component">
      <Head head={t('admin:roles.head')} />

      <Table
        tableRef={_tableRef}
        table={{
          columns,
          data,
          initialState: {pageIndex: 0},
          manualPagination: true,
          pageCount
        }}
        count={count}
        tableHooks={[
          useRowSelection(),
          useRowActions({
            menuItems
          }),
        ].reverse()}
        fetchData={fetchData}
        loading={loading}
        toolbar={{
          top: {
            right: <>
              <div className="row">
                <div className="col-12 d-flex justify-content-end train-buttons">
                  <Link
                    to={'/admin/roles/form'}
                    className="btn btn-create"
                  >
                    {t('buttons.create')}
                  </Link>
                  <button
                    className="btn btn-remove"
                    onClick={() => {
                      const table = _tableRef.current;
                      const {page, pageCount, gotoPage, state: {selectedRowIds}} = table;
                      const ids = Object.keys(selectedRowIds).filter(key => table.rowsById[key].original.removable)
                        .map(key => table.rowsById[key].original.id);
                      let pageIndex = table.state.pageIndex;

                      if (ids.length === 0) {
                        table.page.forEach(row => row.toggleRowSelected(false));

                        return;
                      }

                      if (pageIndex === pageCount - 1 && page.length === Object.keys(selectedRowIds).length)
                        pageIndex = --pageIndex < 0 ? 0 : pageIndex;

                      roleProvider.delete(ids).then(() => {
                        if (pageIndex !== table.state.pageIndex)
                          gotoPage(pageIndex);
                        else
                          fetchData({pageIndex, pageSize: table.state.pageSize});
                      });
                    }}
                  >
                    {t('buttons.remove')}
                  </button>
                </div>
              </div>
            </>
          }
        }}
      />
    </div>
  );
}

export default Roles;
