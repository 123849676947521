import React, {FC, useEffect, useState} from 'react';
import {useTranslation} from 'react-i18next';
import {useHistory, useParams} from 'react-router-dom';
import './UsersView.scss';
import UserProvider from '../../Providers/User';
import IUser from '../../Interfaces/IUser';
import ViewValue from '../../Core/Form/ViewValue/ViewValue';
import Head from '../Head/Head';

const userProvider = new UserProvider();

const UsersView: FC = () => {
  const {t} = useTranslation();
  const roleGroupOptions = [
    {
      value: UserProvider.ROLE_GROUP_ADMIN,
      label: t('admin:users.role_group_admin')
    },
    {
      value: UserProvider.ROLE_GROUP_EMPLOYEE,
      label: t('admin:users.role_group_employee')
    },
    {
      value: UserProvider.ROLE_GROUP_DEALER,
      label: t('admin:users.role_group_dealer')
    },
    {
      value: UserProvider.ROLE_GROUP_CLIENT,
      label: t('admin:users.role_group_client')
    },
  ];
  const history = useHistory();
  const id = parseInt(useParams().id);
  const [user, setUser] = useState<IUser>(null);

  useEffect(() => {
    (async () => {
      if (!(id > 0)) {
        history.push('/admin/users');

        return;
      }

      const user = await userProvider.get(id);

      if (!user) {
        history.push('/admin/users');

        return;
      }

      setUser(user);
    })();
    // eslint-disable-next-line
  }, []);

  return (
    <>
      {user && <div id="users-view-component">
        <Head
            head={t('admin:users.form.header')}
            subhead={'VIN - Auto'}
        />

        <form id="users-form">
          <div className="row">
            {/*Email*/}
            <ViewValue
                className="col-12 col-md-6"
                label={t('admin:users.email')}
                value={user.email ?? null}
            />

            {/*First Name*/}
            <ViewValue
                className="col-12 col-md-6"
                label={t('admin:users.first_name')}
                value={user.firstName ?? null}
            />

            {/*Last Name*/}
            <ViewValue
                className="col-12 col-md-6"
                label={t('admin:users.last_name')}
                value={user.lastName ?? null}
            />

            {/*Role*/}
            <ViewValue
                className="col-12 col-md-6"
                label={t('admin:users.role')}
                value={user.role.displayName}
            />

            {/*Role group*/}
            <ViewValue
                className="col-12 col-md-6"
                label={t('admin:users.role_group')}
                value={roleGroupOptions.find(item => item.value === user.roleGroup)?.label ?? null}
            />

            {/*Buyer ID*/}
            <ViewValue
                className="col-12 col-md-6"
                label={t('admin:users.buyer_id')}
                value={user.buyerIds.map(buyerId => <div>
                  {`${buyerId.login} - ${t(`admin:auction.${buyerId.auction}`)}`}
                </div>)}
            />

            {/*Company*/}
            <ViewValue
                className="col-12 col-md-6"
                label={t('admin:users.company')}
                value={user.company ?? null}
            />

            {/*Country*/}
            <ViewValue
                className="col-12 col-md-6"
                label={t('admin:users.country')}
                value={user.country ?? null}
            />

            {/*City*/}
            <ViewValue
                className="col-12 col-md-6"
                label={t('admin:users.city')}
                value={user.city ?? null}
            />
          </div>

          <hr style={{marginTop: '29px'}} />
        </form>
      </div>}
    </>
  );
};

export default UsersView;
