import React, {FC} from 'react';
import {Redirect} from 'react-router-dom';

const NotFound: FC = () => {
  return (
    <>
      <Redirect to="/admin/personal-data" />
    </>
  );
}

export default NotFound;
