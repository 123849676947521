import React, {FC, useCallback, useContext, useMemo, useRef, useState} from 'react';
import {useTranslation} from 'react-i18next';
import {Link} from 'react-router-dom';
import './Users.scss';
import UserProvider from '../../Providers/User';
import AdminContext from '../AdminContext';
import IRole from '../../Interfaces/IRole';
import {UserPermission} from '../../Providers/UserPermission';
import Head from '../Head/Head';
import {
  RowActionMenuItemEdit,
  RowActionMenuItemRemove,
  RowActionMenuItemView
} from '../../Core/Form/Table/useRowActions';
import Table, {useRowActions, useRowSelection} from '../../Core/Form/Table/Table';
import Config from '../../Providers/Config';
import UsersFilter from './UsersFilter';
import IBuyerId from '../../Interfaces/IBuyerId';

const userProvider = new UserProvider();

const Users: FC = () => {
  const {t} = useTranslation();
  const context = useContext(AdminContext);
  const filterRef = useRef({});
  const columns = useMemo(() => [
    {
      Header: t('admin:users.created_at'),
      accessor: 'user.createdAt',
      Cell: ({value}) => value ? value.format(Config.DATE_FORMAT) : ''
    },
    {
      Header: t('admin:users.full_name'),
      accessor: 'user',
      Cell: ({value}) => `${value.firstName} ${value.lastName}`
    },
    {
      Header: t('admin:users.email'),
      accessor: 'user.email'
    },
    {
      Header: t('admin:users.role'),
      accessor: 'user.role.displayName'
    },
    {
      Header: t('admin:users.company'),
      accessor: 'user.company'
    },
    {
      Header: t('admin:users.buyer_id'),
      accessor: 'user.buyerIds',
      Cell: ({value}) => {
        const buyerIds: IBuyerId[] = value;

        return buyerIds.map(item => `${item.login} - ${t(`admin:auction.${item.auction}`)}`).join(', ');
      }
    },
    {
      Header: t('admin:users.customer_zone_auto_count'),
      accessor: 'customerZoneAutoCount',
      Cell: ({value}) => value > 0 ? value : ''
    },
    {
      Header: t('admin:users.invoice_count'),
      accessor: 'invoiceCount',
      Cell: ({value}) => value > 0 ? value : ''
    },
    // eslint-disable-next-line
  ], []);
  const [data, setData] = useState([]);
  const [pageCount, setPageCount] = useState(0);
  const [count, setCount] = useState(0);
  const fetchIdRef = useRef(0);
  const _tableRef = useRef<any>();
  const [loading, setLoading] = useState(false);
  const menuItems = useMemo(() => {
    const items = [];

    // Edit
    if (IRole.checkPermission(context.auth.user.role, [
      UserPermission.USERS_FULL
    ])) {
      items.push(<RowActionMenuItemEdit
        link={rowIndex => {
          const table = _tableRef.current;
          const row = table.page[rowIndex].original;

          return {
            to: `/admin/users/form/${row.user.id}`
          };
        }}
      />);
    }

    // View
    if (IRole.checkPermission(context.auth.user.role, [
      UserPermission.USERS_READONLY
    ])) {
      items.push(<RowActionMenuItemView
        link={rowIndex => {
          const table = _tableRef.current;
          const row = table.page[rowIndex].original;

          return {
            to: `/admin/users/view/${row.user.id}`
          };
        }}
      />)
    }

    // Remove
    if (IRole.checkPermission(context.auth.user.role, [
      UserPermission.USERS_FULL,
    ])) {
      items.push(<RowActionMenuItemRemove
        menuItem={{
          onClick: (e, rowIndex) => {
            const table = _tableRef.current;
            let pageIndex = table.state.pageIndex;
            const row = table.page[rowIndex].original;

            if (table.page.length === 1)
              pageIndex = --pageIndex < 0 ? 0 : pageIndex;

            userProvider.delete(row.user.id).then(() => {
              fetchData({pageIndex, pageSize: table.state.pageSize});
            });
          }
        }}
      />);
    }

    return items;
    // eslint-disable-next-line
  }, []);

  // Get table data
  const fetchData = useCallback(({pageIndex, pageSize}) => {
    const fetchId = ++fetchIdRef.current;
    setLoading(true);

    if (fetchId !== fetchIdRef.current)
      return;

    (async () => {
      const list = (await userProvider.list({
        page_index: pageIndex,
        page_size: pageSize,
        ...filterRef.current,
      }));

      if (!list)
        return;

      setData(list.data);
      setCount(list.count);
      setPageCount(Math.ceil(list.count / pageSize));
      setLoading(false);
    })();
  }, []);

  return (
    <div id="users-component">
      <Head
        head={t('admin:users.head')}
        hr={{style: {marginTop: 0}}}
      >
        <UsersFilter
          onFilter={filter => {
            filterRef.current = filter;
            const table = _tableRef.current;
            fetchData({pageIndex: table.state.pageIndex, pageSize: table.state.pageSize});
          }}
        />
      </Head>

      <Table
        tableRef={_tableRef}
        table={{
          columns,
          data,
          initialState: {pageIndex: 0},
          manualPagination: true,
          pageCount
        }}
        count={count}
        tableHooks={[
          useRowSelection(),
          useRowActions({
            menuItems
          }),
        ].reverse()}
        fetchData={fetchData}
        loading={loading}
        toolbar={{
          top: {
            right: <>
              {IRole.checkPermission(context.auth.user.role, [
                UserPermission.USERS_FULL
              ]) && <div className="row">
                <div className="col-12 d-flex justify-content-end train-buttons">
                  <Link
                      to={'/admin/users/form'}
                      className="btn btn-create"
                  >
                    {t('buttons.create')}
                  </Link>
                  <button
                      className="btn btn-remove"
                      onClick={() => {
                        const table = _tableRef.current;
                        const {page, pageCount, gotoPage, state: {selectedRowIds}} = table;
                        const ids = Object.keys(selectedRowIds).map(key => table.rowsById[key].original.user.id);
                        let pageIndex = table.state.pageIndex;

                        if (ids.length === 0)
                          return;

                        if (pageIndex === pageCount - 1 && page.length === Object.keys(selectedRowIds).length)
                          pageIndex = --pageIndex < 0 ? 0 : pageIndex;

                        userProvider.delete(ids).then(() => {
                          if (pageIndex !== table.state.pageIndex)
                            gotoPage(pageIndex);
                          else
                            fetchData({pageIndex, pageSize: table.state.pageSize});
                        });
                      }}
                  >
                    {t('buttons.remove')}
                  </button>
                </div>
              </div>}
            </>
          }
        }}
      />
    </div>
  );
}

export default Users;
