import React, {FC, useContext} from 'react';
import {useTranslation} from 'react-i18next';
import './ReferralProgram.scss';
import ActivatePromoCode from './ActivatePromoCode/ActivatePromoCode';
import PromoCodeHistory from './PromoCodeHistory/PromoCodeHistory';
import IRole from '../../Interfaces/IRole';
import {UserPermission} from '../../Providers/UserPermission';
import AdminContext from '../AdminContext';
import Head from '../Head/Head';

const ReferralProgram: FC = () => {
  const {t} = useTranslation();
  const context = useContext(AdminContext);

  return (
    <div id="referral-program-component">
      <Head head={t('admin:referral_program.head')} />

      <ul
        className="nav nav-tabs"
        role="tablist"
      >
        {
          IRole.checkPermission(context.auth.user.role, [
            UserPermission.REFERRAL_PROGRAM_FULL
          ])
            ?
            <li className="nav-item">
              <a
                className="nav-link active"
                id="tab-promotions"
                data-toggle="tab"
                href="#tab-content-activate-promo-code"
                role="tab"
                aria-controls="tab-activate-promo-code"
                aria-selected="true"
              >
                <span>
                  {t('admin:referral_program.tab_activate_promo_code')}
                </span>
              </a>
            </li>
            :
            ''
        }
        <li className="nav-item">
          <a
            className={
              ' nav-link ' + (
                IRole.checkPermission(context.auth.user.role, [
                  UserPermission.REFERRAL_PROGRAM_FULL
                ])
                  ?
                  ''
                  :
                  ' active '
              )
            }
            id="tab-promo-code-history"
            data-toggle="tab"
            href="#tab-content-promo-code-history"
            role="tab"
            aria-controls="promo-code-history"
            aria-selected="false"
          >
            <span className="desktop d-none d-md-block">
              {t('admin:referral_program.tab_promo_code_history')}
            </span>
            <span className="mobile d-block d-md-none">
              {t('admin:referral_program.tab_promo_code_history_mobile')}
            </span>
          </a>
        </li>
      </ul>

      <div className="tab-content">
        {
          IRole.checkPermission(context.auth.user.role, [
            UserPermission.REFERRAL_PROGRAM_FULL
          ])
            ?
            <div
              className="tab-pane fade show active"
              id="tab-content-activate-promo-code"
              role="tabpanel"
              aria-labelledby="tab-promotions"
            >
              <ActivatePromoCode />
            </div>
            :
            ''
        }
        <div
          className={
            'tab-pane fade ' +
            IRole.checkPermission(context.auth.user.role, [
              UserPermission.REFERRAL_PROGRAM_FULL
            ])
              ?
              ''
              :
              ' active '
          }
          id="tab-content-promo-code-history"
          role="tabpanel"
          aria-labelledby="tab-referrals-history"
        >
          <PromoCodeHistory />
        </div>
      </div>
    </div>
  );
}

export default ReferralProgram;
