import React, {FC, useCallback, useContext, useEffect, useMemo, useRef, useState} from 'react';
import {useTranslation} from 'react-i18next';
import {Link} from 'react-router-dom';
import './CustomerZoneAuto.scss';
import CustomerZoneAutoProvider from '../../Providers/CustomerZoneAuto';
import AdminContext from '../AdminContext';
import IRole from '../../Interfaces/IRole';
import {UserPermission} from '../../Providers/UserPermission';
import Head from '../Head/Head';
import Table, {useRowActions, useRowSelection} from '../../Core/Form/Table/Table';
import {
  RowActionMenuItemEdit,
  RowActionMenuItemRemove,
  RowActionMenuItemView,
} from '../../Core/Form/Table/useRowActions';
import CustomerZoneAutoFilter from './CustomerZoneAutoFilter';
import Config from '../../Providers/Config';
import Cars from '../../Providers/Cars';
import ISelectOption from '../../Interfaces/ISelectOption';
import IDestinationPort from '../../Interfaces/IDestinationPort';
import DestinationPortProvicer from '../../Providers/DestinationPort';
import User from '../../Providers/User';

const customerZoneAutoProvider = new CustomerZoneAutoProvider();
const destinationPortProvider = new DestinationPortProvicer();

const CustomerZoneAuto: FC = () => {
  const {t} = useTranslation();
  const context = useContext(AdminContext);
  const user = context.auth.user;
  const auctionOptions = Cars.auctions().map(item => ({
    value: item,
    label: t(`admin:auction.${item}`),
  }));
  const noYesOptions = [
    {
      value: '0',
      label: t('admin:customer_zone_auto.no'),
    },
    {
      value: '1',
      label: t('admin:customer_zone_auto.yes'),
    },
  ];
  const titleStatusOptions = Cars.titleStatuses.map(item => ({
    value: item,
    label: t(`admin:title_status.${item}`),
  }));
  const destinationPortsOptionsRef = useRef<ISelectOption[]>();
  const readyRef = useRef(false);
  const filterRef = useRef({});
  const columns = useMemo(() => {
    let columns = [
      {
        Header: t('admin:customer_zone_auto.id'),
        accessor: 'id',
      },
      {
        Header: t('admin:customer_zone_auto.auto_purchase_date'),
        accessor: 'data.autoPurchaseDate',
        Cell: ({value}) => value?.format(Config.DATE_FORMAT) ?? '',
      },
      {
        Header: t('admin:customer_zone_auto.dealer'),
        accessor: 'id',
        id: 'dealer',
        Cell: ({value}) => fetchDataRef.current.addons[value].dealer ?? '',
      },
      {
        Header: t('admin:customer_zone_auto.buyer_id'),
        accessor: 'id',
        id: 'buyerId',
        Cell: ({value}) => fetchDataRef.current.addons[value].buyer_id ?? '',
      },
      {
        Header: t('admin:customer_zone_auto.col_photo'),
        accessor: 'data',
        id: 'photo',
        Cell: ({value}) =>
            value.photosAuction.length + value.photosBeforeLoading.length +
            value.photosAfterUploading.length,
      },
      {
        Header: t('admin:customer_zone_auto.year'),
        accessor: 'data.year',
      },
      {
        Header: t('admin:customer_zone_auto.model'),
        accessor: 'data.model',
      },
      {
        Header: t('admin:customer_zone_auto.maker'),
        accessor: 'data.maker',
      },
      {
        Header: t('admin:customer_zone_auto.vin'),
        accessor: 'data.vin',
      },
      {
        Header: t('admin:customer_zone_auto.auction'),
        accessor: 'data.auction',
        Cell: ({value}) => auctionOptions.find(item => item.value === value)?.label ?? '',
      },
      {
        Header: t('admin:customer_zone_auto.lot'),
        accessor: 'data.lot',
      },
      {
        Header: t('admin:customer_zone_auto.purchase_city'),
        accessor: 'id',
        id: 'purchase_city',
        Cell: ({value}) => fetchDataRef.current.addons[value].purchase_city ?? '',
      },
    ];

    if (![User.ROLE_GROUP_DEALER].includes(user.roleGroup)) {
      columns = columns.concat([
        {
          Header: t('admin:customer_zone_auto.shipping_company'),
          accessor: 'data.shippingCompany',
        },
      ]);
    }

    columns = columns.concat([
      {
        Header: t('admin:customer_zone_auto.departure_port'),
        accessor: 'id',
        id: 'departure_port',
        Cell: ({value}) => fetchDataRef.current.addons[value].departure_port ?? '',
      },
    ]);

    if (![User.ROLE_GROUP_DEALER].includes(user.roleGroup)) {
      columns = columns.concat([
        {
          Header: t('admin:customer_zone_auto.dispatch'),
          accessor: 'data.dispatch',
          Cell: ({value}) => noYesOptions.find(item => item.value === value)?.label ?? '',
        },
        {
          Header: t('admin:customer_zone_auto.self_delivery'),
          accessor: 'data.selfDelivery',
          Cell: ({value}) => noYesOptions.find(item => item.value === value)?.label ?? '',
        },
        {
          Header: t('admin:customer_zone_auto.delivery_company'),
          accessor: 'data.deliveryCompany',
        },
        {
          Header: t('admin:customer_zone_auto.dispatch_date'),
          accessor: 'data.dispatchDate',
          Cell: ({value}) => value?.format(Config.DATE_FORMAT) ?? '',
        },
        {
          Header: t('admin:customer_zone_auto.delivery_price'),
          accessor: 'data.deliveryPrice',
        },
        {
          Header: t('admin:customer_zone_auto.delivery_notes'),
          accessor: 'data.deliveryNotes',
        },
      ]);
    }

    columns = columns.concat([
      {
        Header: t('admin:customer_zone_auto.odometer'),
        accessor: 'data.odometer',
      },
      {
        Header: t('admin:customer_zone_auto.condition'),
        accessor: 'data.condition',
      },
      {
        Header: t('admin:customer_zone_auto.keys'),
        accessor: 'data.keys',
        Cell: ({value}) => noYesOptions.find(item => item.value === value)?.label ?? '',
      },
      {
        Header: t('admin:customer_zone_auto.title_status'),
        accessor: 'data.titleStatus',
        Cell: ({value}) => titleStatusOptions.find(item => item.value === value)?.label ?? '',
      },
      {
        Header: t('admin:customer_zone_auto.auction_contact'),
        accessor: 'data.auctionContact',
      },
      {
        Header: t('admin:customer_zone_auto.title_notes'),
        accessor: 'data.titleNotes',
      },
      {
        Header: t('admin:customer_zone_auto.invoices'),
        accessor: 'invoices',
        Cell: ({value}) => value?.map(invoice => <a
            key={invoice}
            href={Config.api(`/invoices/get-pdf/${invoice}?api_token=${context.auth.user.token}`)}
            target={'blank'}
            className="btn th-action th-action-open mr-1 mb-1"
        >
          {t('admin:invoices.invoice')}
        </a>) ?? '',
      },
      {
        Header: t('admin:customer_zone_auto.client_payment_date'),
        accessor: 'data.clientPaymentDate',
        Cell: ({value}) => value?.format(Config.DATE_FORMAT) ?? '',
      },
      {
        Header: t('admin:customer_zone_auto.auction_payment_date'),
        accessor: 'data.auctionPaymentDate',
        Cell: ({value}) => value?.format(Config.DATE_FORMAT) ?? '',
      },
      {
        Header: t('admin:customer_zone_auto.notes'),
        accessor: 'data.notes',
      },
      {
        Header: t('admin:customer_zone_auto.destination_port'),
        accessor: 'data.destinationPort',
        Cell: ({value}) => destinationPortsOptionsRef.current.find(item => item.value === value)?.label ?? '',
      },
      {
        Header: t('admin:customer_zone_auto.date_of_loading'),
        accessor: 'data.dateOfLoading',
        Cell: ({value}) => value?.format(Config.DATE_FORMAT) ?? '',
      },
      {
        Header: t('admin:customer_zone_auto.sail_date'),
        accessor: 'data.sailDate',
        Cell: ({value}) => value?.format(Config.DATE_FORMAT) ?? '',
      },
      {
        Header: t('admin:customer_zone_auto.container_number'),
        accessor: 'data.containerNumber',
      },
      {
        Header: t('admin:customer_zone_auto.booking_number'),
        accessor: 'data.bookingNumber',
      },
      {
        Header: t('admin:customer_zone_auto.eta'),
        accessor: 'data.eta',
        Cell: ({value}) => value?.format(Config.DATE_FORMAT) ?? '',
      },
    ]);

    return columns;
    // eslint-disable-next-line
  }, []);
  const fetchDataRef = useRef<any>();
  const [data, setData] = useState([]);
  const [pageCount, setPageCount] = useState(0);
  const [count, setCount] = useState(0);
  const fetchIdRef = useRef(0);
  const _tableRef = useRef<any>();
  const [loading, setLoading] = useState(false);
  const menuItems = useMemo(() => {
    const items = [];

    // Edit
    if (IRole.checkPermission(context.auth.user.role, [
      UserPermission.CUSTOMER_ZONE_AUTOS_FULL,
    ])) {
      items.push(<RowActionMenuItemEdit
          link={rowIndex => {
            const table = _tableRef.current;
            const row = table.page[rowIndex].original;

            return {
              to: `/admin/customer-zone/auto/form/${row.id}`,
            };
          }}
      />);
    }

    // View
    if (IRole.checkPermission(context.auth.user.role, [
      UserPermission.CUSTOMER_ZONE_AUTOS_READONLY,
    ])) {
      items.push(<RowActionMenuItemView
          link={rowIndex => {
            const table = _tableRef.current;
            const row = table.page[rowIndex].original;

            return {
              to: `/admin/customer-zone/auto/view/${row.id}`,
            };
          }}
      />);
    }

    // Remove
    if (IRole.checkPermission(context.auth.user.role, [
      UserPermission.CUSTOMER_ZONE_AUTOS_FULL,
    ])) {
      items.push(<RowActionMenuItemRemove
          menuItem={{
            onClick: (e, rowIndex) => {
              const table = _tableRef.current;
              let pageIndex = table.state.pageIndex;
              const row = table.page[rowIndex].original;

              if (table.page.length === 1)
                pageIndex = --pageIndex < 0 ? 0 : pageIndex;

              customerZoneAutoProvider.delete(row.id).then(() => {
                fetchData({pageIndex, pageSize: table.state.pageSize});
              });
            },
          }}
      />);
    }

    return items;
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    (async () => {
      // Destination ports
      destinationPortsOptionsRef.current = (((await destinationPortProvider.list()) ?? []) as IDestinationPort[]).map(
          item => ({
            value: item.id,
            label: `${item.displayCity}, ${item.displayCountry}`,
          }));
      readyRef.current = true;
      const table = _tableRef.current;
      fetchData({pageIndex: table.state.pageIndex, pageSize: table.state.pageSize,});
    })();
    // eslint-disable-next-line
  }, []);

  // Get table data
  const fetchData = useCallback(({pageIndex, pageSize}) => {
    // Wait until all data load
    if (!readyRef.current)
      return;

    const fetchId = ++fetchIdRef.current;
    setLoading(true);

    if (fetchId !== fetchIdRef.current)
      return;

    (async () => {
      const list = (await customerZoneAutoProvider.list({
        page_index: pageIndex,
        page_size: pageSize,
        ...filterRef.current,
      }));

      if (!list)
        return;

      fetchDataRef.current = list;
      setData(list.data);
      setCount(list.count);
      setPageCount(Math.ceil(list.count / pageSize));
      setLoading(false);
    })();
  }, []);

  return (
      <div id="customer-zone-auto-component">
        <Head
            head={t('admin:customer_zone_auto.head')}
            hr={{style: {marginTop: 0}}}
        >
          <CustomerZoneAutoFilter
              onFilter={filter => {
                filterRef.current = filter;
                const table = _tableRef.current;
                fetchData({pageIndex: table.state.pageIndex, pageSize: table.state.pageSize,});
              }}
          />
        </Head>

        <Table
            tableRef={_tableRef}
            table={{
              columns,
              data,
              initialState: {pageIndex: 0},
              manualPagination: true,
              pageCount,
            }}
            count={count}
            tableHooks={[
              useRowSelection(),
              useRowActions({
                menuItems,
              }),
            ].reverse()}
            fetchData={fetchData}
            loading={loading}
            toolbar={{
              top: {
                right: <>
                  {IRole.checkPermission(context.auth.user.role, [
                    UserPermission.CUSTOMER_ZONE_AUTOS_FULL,
                  ]) && <div className="row">
                    <div
                        className="col-12 d-flex justify-content-end train-buttons">
                      <Link
                          to={'/admin/customer-zone/auto/form'}
                          className="btn btn-create"
                      >
                        {t('buttons.create')}
                      </Link>
                      <button
                          className="btn btn-remove"
                          onClick={() => {
                            const table = _tableRef.current;
                            const {page, pageCount, gotoPage, state: {selectedRowIds}} = table;
                            const ids = Object.keys(selectedRowIds).map(key => table.rowsById[key].original.id);
                            let pageIndex = table.state.pageIndex;

                            if (ids.length === 0) {
                              table.page.forEach(
                                  row => row.toggleRowSelected(false));

                              return;
                            }

                            if (pageIndex === pageCount - 1 && page.length ===
                                Object.keys(selectedRowIds).length)
                              pageIndex = --pageIndex < 0 ? 0 : pageIndex;

                            customerZoneAutoProvider.delete(ids).then(() => {
                              if (pageIndex !== table.state.pageIndex)
                                gotoPage(pageIndex);
                              else
                                fetchData({
                                  pageIndex,
                                  pageSize: table.state.pageSize,
                                });
                            });
                          }}
                      >
                        {t('buttons.remove')}
                      </button>
                    </div>
                  </div>}
                </>,
              },
            }}
        />
      </div>
  );
};

export default CustomerZoneAuto;
