import axios from 'axios';
import Config from './Config';
import IAuctionCity from '../Interfaces/IAuctionCity';

class City {

  // Load list
  async list(data: any = {}): Promise<IAuctionCity[]|false> {
    const response = (await axios.get(Config.api('/auction/city/list'), {params: data})).data;

    if (!response.success) {
      console.error(response.message);

      return false;
    }

    //  Skip empty
    if (!response.data)
      return false;

    const responseData = response.data;

    return responseData.map(item => IAuctionCity.parse(item));
  }

  // Search
  async search(data: any = {}): Promise<IAuctionCity[]|false> {
    const response = (await axios.get(Config.api('/auction/city/search'), {params: data})).data;

    if (!response.success) {
      console.error(response.message);

      return false;
    }

    //  Skip empty
    if (!response.data)
      return false;

    const responseData = response.data;

    return responseData.map(item => IAuctionCity.parse(item));
  }

}

export default class Auction {

  public static readonly COPART = 'copart';
  public static readonly IAAI = 'iaai';
  public static readonly MANHEIM = 'manheim';
  public static readonly GR8AUTO = 'gr8auto';
  public static readonly IMPACTAUTO = 'impactauto';

  public static readonly City = City;

}
