import axios from 'axios';
import Config from './Config';
import IUser from '../Interfaces/IUser';

export default class User {

  public static readonly ROLE_GROUP_ADMIN = 'admin';
  public static readonly ROLE_GROUP_EMPLOYEE = 'employee';
  public static readonly ROLE_GROUP_DEALER = 'dealer';
  public static readonly ROLE_GROUP_CLIENT = 'client';

  // Load list
  async list(data: any = {}): Promise<{
    data: {
      user: IUser,
      customerZoneAutoCount: number,
      invoiceCount: number
    }[],
    count: number
  }|false> {
    const response = (await axios.get(Config.api('/user/list'), {params: data})).data;

    if (!response.success) {
      console.error(response.message);

      return false;
    }

    //  Skip empty
    if (!response.data)
      return false;

    const responseData = response.data;

    return {
      data: responseData.data.map(item => ({
        user: IUser.parse(item.user),
        customerZoneAutoCount: item.customer_zone_autos_count,
        invoiceCount: item.invoice_count
      })),
      count: responseData.count
    };
  }

  // Remove by ID
  async delete(id: number|number[]): Promise<boolean> {
    const response = (await axios.post(Config.api('/user/delete'), {id})).data;

    if (!response.success) {
      console.error(response.message);

      return false;
    }

    return true;
  }

  // Load form
  async get(id): Promise<IUser> {
    const response = (await axios.get(Config.api(`/user/get/${id}`))).data;

    if (!response.success) {
      console.error(response.message);

      return null;
    }

    //  Skip empty
    if (!response.data)
      return null;

    return IUser.parse(response.data);
  }

  // Search
  async search(data: any = {}): Promise<IUser[]|false> {
    const response = (await axios.get(Config.api('/user/search'), {params: data})).data;

    if (!response.success) {
      console.error(response.message);

      return false;
    }

    //  Skip empty
    if (!response.data)
      return false;

    const responseData = response.data;

    return responseData.map(item => IUser.parse(item));
  }

}
