import React, {FC, useEffect, useState} from 'react';
import {useTranslation} from 'react-i18next';
import {useHistory, useParams} from 'react-router-dom';
import './CustomerZoneBalanceView.scss';
import axios from 'axios';
import Config from '../../Providers/Config';
import ICustomerZoneAuto from '../../Interfaces/ICustomerZoneAuto';
import ViewValue from '../../Core/Form/ViewValue/ViewValue';
import Head from '../Head/Head';
import CustomerZoneBalanceProvider from '../../Providers/CustomerZoneBalance';
import CustomerZoneAutoProvider from '../../Providers/CustomerZoneAuto';
import Auction from '../../Providers/Auction';
import Cars from '../../Providers/Cars';
import ICustomerZoneBalance from '../../Interfaces/ICustomerZoneBalance';
import DeparturePortProvider from '../../Providers/DeparturePort';

const customerZoneBalanceProvider = new CustomerZoneBalanceProvider();
const customerZoneAutoProvider = new CustomerZoneAutoProvider();
const auctionCityProvider = new Auction.City();
const departurePortProvider = new DeparturePortProvider();

const CustomerZoneBalanceForm: FC = () => {
  const {t} = useTranslation();
  const auctionOptions = Cars.auctions().map(item => ({
    value: item,
    label: t(`admin:auction.${item}`)
  }));
  const orderTypeOptions = [
    {
      value: 'auto',
      label: t('admin:order_type.auto')
    },
  ];
  const history = useHistory();
  const id = parseInt(useParams().id);
  const [customerZoneBalance, setCustomerZoneBalance] = useState<ICustomerZoneBalance>(null);
  const [customerZoneAuto, setCustomerZoneAuto] = useState<ICustomerZoneAuto>(null);
  const [buyerId, setBuyerId] = useState('');
  const [purchaseCity, setPurchaseCity] = useState('');
  const [departurePort, setDeparturePort] = useState('');

  // Search Buyer ID based on input
  const buyerIdLoad = async id => {
    if (!(id > 0))
      return null;

    const valueResponse = (await axios.get(Config.api(`/buyer-id/search?id=${id}`))).data;
    const item = valueResponse.success ? valueResponse.data[0] : null;

    return item ? `${item.login} - ${t(`admin:auction.${item.auction}`)}` : null;
  };

  // Search auction cities based on input
  const purchaseCityLoad = async id => {
    if (!(id > 0))
      return null;

    const valueResponse = await auctionCityProvider.search({id});
    const item = valueResponse?.[0];

    return item ? `${item.city} – ${item.port}` : null;
  };

  // Get departure port
  const departurePortLoad = async id => {
    if (!(id > 0))
      return null;

    const item = await departurePortProvider.get(id);

    return item ? `${item.displayCity}${item.displayState ? `, ${item.displayState}` : ''}` : null;
  };

  useEffect(() => {
    (async () => {
      const customerZoneBalance = id > 0 ? await customerZoneBalanceProvider.get(id) : ICustomerZoneBalance.parse({});

      if (!customerZoneBalance) {
        history.push('/admin/customer-zone/balance');

        return;
      }

      const customerZoneAuto = await customerZoneAutoProvider.get(customerZoneBalance.customerZoneAutoId);
      setCustomerZoneAuto(customerZoneAuto);
      setBuyerId(await buyerIdLoad(customerZoneAuto.buyerId));
      setPurchaseCity(await purchaseCityLoad(customerZoneAuto.data.purchaseCity));
      setDeparturePort(await departurePortLoad(customerZoneAuto.data.departurePort));
      setCustomerZoneBalance(customerZoneBalance);
    })();
    // eslint-disable-next-line
  }, []);

  return (
    <>
      {customerZoneBalance && <div
          id="customer-zone-balance-component"
      >
        <Head
            head={t('admin:customer_zone_balance.form.head')}
            subhead={'VIN - Auto'}
        />

        <form id="customer-zone-view-component">
          <div className="row">
            <div className="col-12 col-sm-6 col-md-6 col-lg-4 col-xl-3">
              {/*Auto*/}
              <ViewValue
                  label={t('admin:customer_zone_balance.auto')}
                  value={`${customerZoneAuto.data.year} ${customerZoneAuto.data.maker} ${customerZoneAuto.data.model}`}
              />
            </div>
            <div className="col-12 col-sm-6 col-md-6 col-lg-4 col-xl-3">
              {/*Buyer ID*/}
              <ViewValue
                  label={t('admin:customer_zone_balance.buyer_id')}
                  value={buyerId}
              />
            </div>
            <div className="col-12 col-sm-6 col-md-6 col-lg-4 col-xl-3">
              {/*Lot*/}
              <ViewValue
                  label={t('admin:customer_zone_balance.lot')}
                  value={customerZoneAuto.data.lot}
              />
            </div>
            <div className="col-12 col-sm-6 col-md-6 col-lg-4 col-xl-3">
              {/*VIN*/}
              <ViewValue
                  label={t('admin:customer_zone_balance.vin')}
                  value={customerZoneAuto.data.vin}
              />
            </div>
            <div className="col-12 col-sm-6 col-md-6 col-lg-4 col-xl-3">
              {/*Auto purchase date*/}
              <ViewValue
                  label={t('admin:customer_zone_balance.auto_purchase_date')}
                  value={customerZoneAuto.data.autoPurchaseDate?.format(Config.DATE_FORMAT)}
              />
            </div>
            <div className="col-12 col-sm-6 col-md-6 col-lg-4 col-xl-3">
              {/*Date of Loading*/}
              <ViewValue
                  label={t('admin:customer_zone_balance.date_of_loading')}
                  value={customerZoneAuto.data.dateOfLoading?.format(Config.DATE_FORMAT)}
              />
            </div>
            <div className="col-12 col-sm-6 col-md-6 col-lg-4 col-xl-3">
              {/*Auction*/}
              <ViewValue
                  label={t('admin:customer_zone_balance.auction')}
                  value={auctionOptions.find(item => item.value === customerZoneAuto.data.auction)?.label}
              />
            </div>
            <div className="col-12 col-sm-6 col-md-6 col-lg-4 col-xl-3">
              {/*Order type*/}
              <ViewValue
                  label={t('admin:customer_zone_balance.order_type')}
                  value={orderTypeOptions.find(item => item.value === customerZoneAuto.data.orderType)?.label}
              />
            </div>
            <div className="col-12 col-sm-6 col-md-6 col-lg-4 col-xl-3">
              {/*Purchase city*/}
              <ViewValue
                  label={t('admin:customer_zone_balance.purchase_city')}
                  value={purchaseCity}
              />
            </div>
            <div className="col-12 col-sm-6 col-md-6 col-lg-4 col-xl-3">
              {/*Point of loading*/}
              <ViewValue
                  label={t('admin:customer_zone_balance.departure_port')}
                  value={departurePort}
              />
            </div>
            <div className="col-12 col-sm-6 col-md-6 col-lg-4 col-xl-3">
              {/*Container number*/}
              <ViewValue
                  label={t('admin:customer_zone_balance.container_number')}
                  value={customerZoneAuto.data.containerNumber}
              />
            </div>
            <div className="col-12 col-sm-6 col-md-6 col-lg-4 col-xl-3">
              {/*Notes*/}
              <ViewValue
                  label={t('admin:customer_zone_balance.notes')}
                  value={customerZoneAuto.data.notes}
              />
            </div>
          </div>

          <div className="row">
            <div className="col-12 col-sm-6 col-md-6 col-lg-4 col-xl-3">
              {/*Auto price*/}
              <ViewValue
                  label={t('admin:customer_zone_balance.auto_price')}
                  value={customerZoneAuto.data.autoPrice}
              />
            </div>
            <div className="col-12 col-sm-6 col-md-6 col-lg-4 col-xl-3">
              {/*Delivery by land*/}
              <ViewValue
                  label={t('admin:customer_zone_balance.delivery_by_land')}
                  value={customerZoneBalance.data.deliveryByLand}
              />
            </div>
            <div className="col-12 col-sm-6 col-md-6 col-lg-4 col-xl-3">
              {/*Freight*/}
              <ViewValue
                  label={t('admin:customer_zone_balance.freight')}
                  value={customerZoneBalance.data.freight}
              />
            </div>
            <div className="col-12 col-sm-6 col-md-6 col-lg-4 col-xl-3">
              {/*Agent reward*/}
              <ViewValue
                  label={t('admin:customer_zone_balance.agent_reward')}
                  value={customerZoneBalance.data.agentReward}
              />
            </div>
            <div className="col-12 col-sm-6 col-md-6 col-lg-4 col-xl-3">
              {/*Auction Storage fee*/}
              <ViewValue
                  label={t('admin:customer_zone_balance.auction_storage_fee')}
                  value={customerZoneBalance.data.auctionStorageFee}
              />
            </div>
            <div className="col-12 col-sm-6 col-md-6 col-lg-4 col-xl-3">
              {/*Storage Auction fee GR8*/}
              <ViewValue
                  label={t('admin:customer_zone_balance.storage_fee_gr8')}
                  value={customerZoneBalance.data.storageFeeGr8}
              />
            </div>
            <div className="col-12 col-sm-6 col-md-6 col-lg-4 col-xl-3">
              {/*Loading costs*/}
              <ViewValue
                  label={t('admin:customer_zone_balance.loading_costs')}
                  value={customerZoneBalance.data.loadingCosts}
              />
            </div>
            <div className="col-12 col-sm-6 col-md-6 col-lg-4 col-xl-3">
              {/*Late fee*/}
              <ViewValue
                  label={t('admin:customer_zone_balance.late_fee')}
                  value={customerZoneBalance.data.lateFee}
              />
            </div>
            <div className="col-12 col-sm-6 col-md-6 col-lg-4 col-xl-3">
              {/*Insurance*/}
              <ViewValue
                  label={t('admin:customer_zone_balance.insurance')}
                  value={customerZoneBalance.data.insurance}
              />
            </div>
            <div className="col-12 col-sm-6 col-md-6 col-lg-4 col-xl-3">
              {/*Current balance*/}
              <ViewValue
                  label={t('admin:customer_zone_balance.current_balance')}
                  value={customerZoneBalance.data.currentBalance}
              />
            </div>
            <div className="col-12 col-sm-6 col-md-6 col-lg-4 col-xl-3">
              {/*Payed amount*/}
              <ViewValue
                  label={t('admin:customer_zone_balance.payed_amount')}
                  value={customerZoneBalance.data.payedAmount}
              />
            </div>
            <div className="col-12 col-sm-6 col-md-6 col-lg-4 col-xl-3">
              {/*Balance*/}
              <ViewValue
                  label={t('admin:customer_zone_balance.balance')}
                  value={customerZoneBalance.data.balance}
              />
            </div>
          </div>

          <hr style={{marginTop: '29px'}} />
        </form>
      </div>}
    </>
  );
}

export default CustomerZoneBalanceForm;
