import React, {FC, useState} from 'react';
import './InputAutosuggest.scss';
import Autosuggest from 'react-autosuggest';

const InputAutosuggest: FC<{
  className?: string;
  style?: any;
  label?: string;
  Autosuggest: any & {
    suggestions: {
      id?: string,
      text: string
    }[];
    inputProps?: any;
  };
  hideLabel?: boolean;
  onChange?: any;
}> = (props) => {
  const [value, setValue] = useState('');
  const [selected, setSelected] = useState<any>();
  const [_suggestions, set_suggestions] = useState<any[]>([]);

  // Return filtered suggestions based on input value
  function getSuggestions(value): any[] {
    const suggestions = props.Autosuggest.suggestions;
    const inputValue = value.trim().toLowerCase();
    const inputLength = inputValue.length;

    // Show all suggestions if empty input
    if (!value)
      return suggestions;

    return inputLength === 0 ? [] : suggestions.filter(suggestion => {
      return suggestion.text.toLowerCase().slice(0, inputLength) === inputValue;
    });
  }

  // Called when need to recalculate suggestions
  const onSuggestionsFetchRequested = ({value}) => {
    set_suggestions(getSuggestions(value));
  };

  // Will be called every time you need to set suggestions to []
  const onSuggestionsClearRequested = () => {
    set_suggestions([]);
  };

  // Return value for value input when suggestion is clicked
  const getSuggestionValue = suggestion => suggestion ? suggestion.text : '';

  // Template for suggestion
  const renderSuggestion = suggestion => (
    <div className="suggestion-item">
      {suggestion.text}
    </div>
  );

  // Every time render suggestions on input focus
  function shouldRenderSuggestions() {
    return true;
  }

  const onSuggestionSelected = (event, {suggestion, suggestionValue, suggestionIndex, sectionIndex, method}) => {
    setSelected(suggestion);
  };

  const onChange = (e, {newValue}) => {
    setValue(newValue);
    // Continue event
    if (typeof props.Autosuggest?.inputProps?.onChange === 'function')
      props.Autosuggest?.inputProps.onChange(e, selected, {newValue});
  };

  return (
    <div
      className={`form-field autosuggest ${props.className ?? ''}`}
      style={props.style}
    >
      <div className="field">
        {!props.hideLabel && <div className="label">
          <label htmlFor={props.Autosuggest?.inputProps?.id}>
            {props.label}
          </label>
        </div>}
        <Autosuggest
          suggestions={_suggestions}
          onSuggestionsFetchRequested={onSuggestionsFetchRequested}
          onSuggestionsClearRequested={onSuggestionsClearRequested}
          getSuggestionValue={getSuggestionValue}
          renderSuggestion={renderSuggestion}
          shouldRenderSuggestions={shouldRenderSuggestions}
          onSuggestionSelected={onSuggestionSelected}
          inputProps={{
            style: {},
            value: value,
            ...props.Autosuggest?.inputProps,
            onChange: onChange,
          }}
        />
      </div>
    </div>
  );
};

export default InputAutosuggest;
