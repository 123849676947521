import React, {FC, useEffect, useState} from 'react';
import './SentPassword.scss';
import {useDispatch} from 'react-redux';
import {useTranslation} from 'react-i18next';
import {Link, useParams} from 'react-router-dom';
import setBgImg from '../../Core/Store/Actions/Login/setBgImg';

const SentPassword: FC = () => {
  const {t} = useTranslation();
  const recipientEmail = useParams().email;
  const dispatch = useDispatch();
  const [email, setEmail] = useState('');

  useEffect(() => {
    dispatch(setBgImg('/assets/images/sign-up/sign-up-bg-1.jpg'));
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    const email = t('login:sent_password.subtitle');
    setEmail(email.replace(':email', recipientEmail));
    // eslint-disable-next-line
  }, []);

  return (
    <div id="sent-password">
      <div className="content">
        <Link
          to="/"
          className="go-back"
        >
          <img
            src={'/assets/images/arrow-back.png'}
            alt="arrow-back"
            height={9}
          />
          <span>
            {t('login:sent_password.go_back')}
          </span>
        </Link>

        <div className="sent-image text-center">
          <img
            src={'/assets/images/sent-password/plane.svg'}
            alt="plane"
          />
        </div>

        <div className="header">
          <h5 className="title">
            {t('login:sent_password.title')}
          </h5>
          <p className="subtitle">
            {email}
          </p>
        </div>

        <div className="button_sent">
          <Link to="/">
            <button className="w-100 th-submit">
              {t('login:sent_password.go_home_btn')}
            </button>
          </Link>
        </div>
        <Link
          to="/"
          className="go-back-mobile"
        >
          <img
            src={'/assets/images/arrow-back.png'}
            alt="arrow-back"
            height={9}
          />
          <span>
            {t('login:forgot_password.go_back')}
          </span>
        </Link>
      </div>
    </div>
  );
};

export default SentPassword;
