import React, {FC, useEffect, useRef, useState} from 'react';
import {useTranslation} from 'react-i18next';
import {Link, useHistory, useParams} from 'react-router-dom';
import './TrackingForm.scss';
import Input from '../../Core/Form/Input/Input';
import axios from 'axios';
import Config from '../../Providers/Config';
import $ from 'jquery';
import {IHttpResponse} from '../../Interfaces/IHttpResponse';
import * as EgoUtil from '@egofoxlab/util';
import Checkbox from '../../Core/Form/Checkbox/Checkbox';
import InputAutosuggest from '../../Core/Form/InputAutosuggest/InputAutosuggest';
import {DragDropContext, Draggable, Droppable} from 'react-beautiful-dnd';
import Notify from '../../Core/Ui/Notify/Notify';
import Share from '../Share/Share';
import Head from '../Head/Head';
import BottomToolbar from '../BottomToolbar/BottomToolbar';
import {Accordion, AccordionDetails, AccordionSummary} from '@material-ui/core';
import {Moment} from 'moment';
import KeyboardDatePicker from '../../Core/Form/Pickers/KeyboardDatePicker/KeyboardDatePicker';
import TrackingProvider from '../../Providers/Tracking';
import CustomerZoneAutoProvider from '../../Providers/CustomerZoneAuto';
import ITracking from '../../Interfaces/ITracking';
import ICustomerZoneAuto from '../../Interfaces/ICustomerZoneAuto';
import ISelectOption from '../../Interfaces/ISelectOption';
import DestinationPortProvider from '../../Providers/DestinationPort';
import IDestinationPort from '../../Interfaces/IDestinationPort';

const stepsSuggestions = [
  {
    text: 'Покупка',
  },
  {
    text: 'Оплата получена',
  },
  {
    text: 'Доставлен на площадку в США',
  },
  {
    text: 'Date of dispatch',
  },
 /* {
    text: 'Погружен в контейнер',
  },*/
  {
    text: 'Дата отплытия судна',
  },
  {
    text: 'Расчетное время прибытия судна',
  },
  {
    text: 'Контейнер расформирован',
  },
  {
    text: 'Таможенная очистка',
  },
  {
    text: 'Выдан клиенту',
  },
];

const trackingProvider = new TrackingProvider();
const customerZoneAutoProvider = new CustomerZoneAutoProvider();
const destinationPortProvider = new DestinationPortProvider();

const TrackingForm: FC = () => {
  const {t} = useTranslation();
  const [destinationPortsOptions, setDestinationPortsOptions] = useState<ISelectOption[]>();
  const history = useHistory();
  const id = parseInt(useParams().id) || null;
  const [tracking, setTracking] = useState<ITracking>();
  const [customerZoneAuto, setCustomerZoneAuto] = useState<ICustomerZoneAuto>();
  const [steps, setSteps] = useState<{
    dndId: string;
    id?: string;
    name: string;
    date: Moment;
    status: boolean;
  }[]>([]);
  const [addStepId, setAddStepId] = useState('');
  const [addStepName, setAddStepName] = useState('');
  const [addStepDate, setAddStepDate] = useState<Moment>(null);
  const [addStepStatus, setAddStepStatus] = useState(false);
  const [saveProcess, setSaveProcess] = useState(false);
  const [saveNotify, setSaveNotify] = useState(false);
  const _eForm = useRef<HTMLFormElement>();

  useEffect(() => {
    (async () => {
      const tracking = id > 0 ? await trackingProvider.get(id) : ITracking.parse({});

      if (!tracking) {
        history.push('/admin/tracking');

        return;
      }

      // Destination ports
      setDestinationPortsOptions((((await destinationPortProvider.list()) ?? []) as IDestinationPort[]).map(item => ({
        value: item.id,
        label: `${item.displayCity}, ${item.displayCountry}`,
      })));

      const customerZoneAuto = await customerZoneAutoProvider.get(tracking.customerZoneAutoId);
      setCustomerZoneAuto(customerZoneAuto);

      // Steps
      setSteps(dndSteps(tracking.data.steps));

      setTracking(tracking);
    })();
    // eslint-disable-next-line
  }, []);

  function reset(): void {
    // Clear input field
    EgoUtil.clearFields(_eForm.current);
    $(_eForm.current).find('[name]').trigger('change');
    // Clear steps
    setSteps([]);
  }

  /**
   * Modify all steps for using dnd
   *
   * @param {any[]} steps
   * @returns {any[]}
   */
  function dndSteps(steps: any[]): any[] {
    for (const key in steps) {
      steps[key].dndId = `step-${key}`;
    }

    return steps;
  }

  function onSubmit(e): void {
    if (!_eForm.current.checkValidity()) {
      return;
    }

    e.preventDefault();

    // Set `Save Process` flag
    setSaveProcess(true);

    const formData = new FormData();
    // Steps
    steps.forEach((step, i) => {
      formData.set(`steps[${i}][id]`, step.id);
      formData.set(`steps[${i}][name]`, step.name);
      formData.set(`steps[${i}][date]`, step.date?.format(Config.DATE_FORMAT));
      formData.set(`steps[${i}][status]`, step.status ? '1' : '0');
    });

    // Save form
    let url;

    if (tracking.id > 0) {
      url = Config.api(`/tracking/update/${tracking.id}`);
    } else {
      url = Config.api('/tracking/create');
    }

    axios.post(url, formData, {
      headers: {'Content-Type': 'multipart/form-data'},
    }).then((_response) => {
      const response: IHttpResponse = _response.data;

      if (response.code !== 200) {
        console.error(response.message);
        // Unset `Save Process` flag
        setSaveProcess(false);

        return;
      }

      // Mark as saved
      setSaveNotify(true);

      // Add tracking to state after saving
      if (!(tracking.id > 0)) {
        history.replace(`/admin/tracking/form/${response.data.id}`);

        // Update tracking
        setTracking(response.data);

        // Steps
        if (tracking.data && Array.isArray(tracking.data.steps)) {
          setSteps(dndSteps(tracking.data.steps));
        }
      }

      // Unset `Save Process` flag
      setSaveProcess(false);
    });
  }

  function onDragEnd(result) {
    // Dropped outside the list
    if (!result.destination) {
      return;
    }

    const _steps = steps.slice();
    const [step] = _steps.splice(result.source.index, 1);
    _steps.splice(result.destination.index, 0, step);
    setSteps(_steps);
  }

  return (
      <>
        {tracking && <div id="tracking-component">
          <Head
              head={t('admin:tracking.form.head')}
              subhead={'VIN - Auto'}
              right={<div className="d-flex justify-content-lg-end justify-content-start train-buttons">
                <button
                    form="tracking-form-component"
                    className="btn btn-save"
                    disabled={saveProcess}
                >
                  {t('buttons.save')}
                </button>
                <button
                    type="button"
                    className="btn btn-reload"
                    onClick={() => reset()}
                >
                  {t('buttons.reset')}
                </button>
                <Link
                    to={'/admin/tracking'}
                    className="btn btn-close"
                >
                  {t('buttons.close')}
                </Link>
              </div>}
              hr={{style: {marginTop: 20}}}
          />

          <Accordion defaultExpanded={true}>
            <AccordionDetails className="pt-0">
              <div className="row d-flex flex-grow-1">
                <div className="col-12 col-sm-6 col-md-6 col-lg-4 col-xl-3">
                  {/*Auto*/}
                  <Input
                      label={t('admin:customer_zone_balance.auto')}
                      input={{
                        defaultValue:
                            `${customerZoneAuto.data.year} ${customerZoneAuto.data.maker} ${customerZoneAuto.data.model}`,
                        readOnly: true,
                      }}
                  />
                </div>
                <div className="col-12 col-sm-6 col-md-6 col-lg-4 col-xl-3">
                  {/*VIN*/}
                  <Input
                      label={t('admin:customer_zone_balance.vin')}
                      input={{
                        defaultValue: customerZoneAuto.data.vin,
                        readOnly: true,
                      }}
                  />
                </div>
                <div className="col-12 col-sm-6 col-md-6 col-lg-4 col-xl-3">
                  {/*Tracking ID*/}
                  <Input
                      label={t('admin:tracking.tracking_id')}
                      input={{
                        value: tracking.code ?? '',
                        readOnly: true,
                      }}
                  />
                </div>
                <div className="col-12 col-sm-6 col-md-6 col-lg-4 col-xl-3">
                  {/*Destination port*/}
                  <Input
                      label={t('admin:tracking.destination_port')}
                      input={{
                        defaultValue:
                        destinationPortsOptions.find(
                            item => item.value === customerZoneAuto.data.destinationPort)?.label,
                        readOnly: true,
                      }}
                  />
                </div>
                <div className="col-12 col-sm-6 col-md-6 col-lg-4 col-xl-3">
                  {/*Container number*/}
                  <Input
                      label={t('admin:customer_zone_balance.container_number')}
                      input={{
                        defaultValue: customerZoneAuto.data.containerNumber,
                        readOnly: true,
                      }}
                  />
                </div>
                <div className="col-12 col-sm-6 col-md-6 col-lg-4 col-xl-3">
                  {/*Booking Number*/}
                  <Input
                      label={t('admin:tracking.booking_number')}
                      input={{
                        defaultValue: customerZoneAuto.data.bookingNumber,
                        readOnly: true,
                      }}
                  />
                </div>
              </div>
            </AccordionDetails>
          </Accordion>

          <form
              ref={_eForm}
              id="tracking-form-component"
              onSubmit={onSubmit}
          />

          <Accordion defaultExpanded={true}>
            <AccordionSummary>
              {t('admin:tracking.section_tracking_status')}
            </AccordionSummary>
            <AccordionDetails>
              <div className="row d-flex flex-grow-1">
                <div className="col-12 col-md-12">
                  <div className="steps-wrap">
                    <div className="steps">
                      <DragDropContext onDragEnd={onDragEnd}>
                        <Droppable droppableId="droppable">
                          {(provided, snapshot) => (
                              <div
                                  ref={provided.innerRef}
                                  {...provided.droppableProps}
                              >
                                {steps.map((step, i) =>
                                    <Draggable
                                        key={step.dndId}
                                        draggableId={step.dndId}
                                        index={i}
                                    >
                                      {(provided, snapshot) => (
                                          <div>
                                            <form
                                                ref={provided.innerRef}
                                                {...provided.dragHandleProps}
                                                {...provided.draggableProps}
                                                className="row d-flex align-items-start step-row step"
                                            >
                                              {/*Action*/}
                                              <div
                                                  className="col-12 col-md-1 action d-flex align-items-center action"
                                                  style={{height: 37, marginTop: 31}}
                                              >
                                                <button
                                                    type={'button'}
                                                    onClick={() => {
                                                      const _steps = [...steps];
                                                      _steps.splice(i, 1);
                                                      setSteps(_steps);
                                                    }}
                                                >
                                                  <img
                                                      src={'/assets/images/icon-delete.svg'}
                                                      alt="icon-delete"
                                                  />
                                                </button>
                                              </div>
                                              {/*Name*/}
                                              <div className="col-12 col-md-3">
                                                <div className="step-label">
                                                  {t('admin:tracking.form.step')}
                                                </div>
                                                <InputAutosuggest
                                                    hideLabel={true}
                                                    Autosuggest={{
                                                      suggestions: stepsSuggestions,
                                                      inputProps: {
                                                        name: 'step-name',
                                                        value: step.name,
                                                        placeholder: t('admin:tracking.step_name'),
                                                        required: true,
                                                        onChange: (e, suggestion, {newValue}) => {
                                                          const _steps = [...steps];
                                                          _steps[i].name = newValue;
                                                          setSteps(_steps);
                                                        },
                                                      },
                                                    }}
                                                />
                                              </div>
                                              {/*Date*/}
                                              <div className="col-12 col-md-3">
                                                <div className="step-label">
                                                  {t('admin:tracking.form.date')}
                                                </div>
                                                <KeyboardDatePicker
                                                    hideLabel={true}
                                                    KeyboardDatePicker={{
                                                      name: 'step-date',
                                                      placeholder: t('admin:tracking.step_date'),
                                                      value: step.date ?? null,
                                                      onChange: (date) => {
                                                        if (!date)
                                                          return;

                                                        const _steps = [...steps];
                                                        _steps[i].date = date;
                                                        setSteps(_steps);
                                                      },
                                                    }}
                                                />
                                              </div>
                                              {/*Status*/}
                                              <div
                                                  className="col-12 col-md-3 d-flex align-items-center action"
                                                  style={{height: 37, marginTop: 31}}
                                              >
                                                <Checkbox
                                                    label={{
                                                      label: t('admin:tracking.step_status'),
                                                      labelPlacement: 'start',
                                                    }}
                                                    checkbox={{
                                                      name: 'step-status',
                                                      checked: step.status,
                                                      onChange: (e, checked) => {
                                                        const _steps = [...steps];
                                                        _steps[i].status = checked;
                                                        setSteps(_steps);
                                                      },
                                                    }}
                                                    bottomOffset={0}
                                                />
                                              </div>
                                            </form>
                                            {provided.placeholder}
                                          </div>
                                      )}
                                    </Draggable>,
                                )}
                                {provided.placeholder}
                              </div>
                          )}
                        </Droppable>
                      </DragDropContext>
                    </div>
                    {/*Add new*/}
                    <form
                        className="row d-flex align-items-start step-row step"
                        onSubmit={(e) => {
                          e.preventDefault();

                          let _steps = [...steps];
                          _steps.push({
                            dndId: null,
                            id: addStepId,
                            name: addStepName,
                            date: addStepDate,
                            status: addStepStatus === true,
                          });
                          _steps = dndSteps(_steps);
                          setSteps(_steps);

                          // Clear input field
                          EgoUtil.clearFields(e.target);
                          setAddStepId(null);
                          setAddStepName(null);
                          setAddStepDate(null);
                          setAddStepStatus(false);
                        }}
                    >
                      {/*Action*/}
                      <div
                          className="col-12 col-md-1 d-flex align-items-center action"
                          style={{height: 37, marginTop: 31}}
                      >
                        <button>
                          <img
                              src={'/assets/images/icon-plus.svg'}
                              alt="icon-plus"
                          />
                        </button>
                      </div>
                      {/*Name*/}
                      <div className="col-12 col-md-3">
                        <div className="step-label">
                          {t('admin:tracking.form.step')}
                        </div>
                        <InputAutosuggest
                            hideLabel={true}
                            Autosuggest={{
                              suggestions: stepsSuggestions,
                              inputProps: {
                                name: 'step-name',
                                value: addStepName ?? '',
                                placeholder: t('admin:tracking.step_name'),
                                required: true,
                                onChange: (e, suggestion, {newValue}) => {
                                  setAddStepId(suggestion ? suggestion.id ? suggestion.id : '' : '');
                                  setAddStepName(newValue);
                                },
                              },
                            }}
                        />
                      </div>
                      {/*Date*/}
                      <div className="col-12 col-md-3">
                        <div className="step-label">
                          {t('admin:tracking.form.date')}
                        </div>
                        <KeyboardDatePicker
                            hideLabel={true}
                            KeyboardDatePicker={{
                              value: addStepDate,
                              onChange: (date) => setAddStepDate(date),
                            }}
                        />
                      </div>
                      {/*Status*/}
                      <div
                          className="col-12 col-md-3 d-flex align-items-center action"
                          style={{height: 37, marginTop: 31}}
                      >
                        <Checkbox
                            label={{
                              label: t('admin:tracking.step_status'),
                              labelPlacement: 'start',
                            }}
                            checkbox={{
                              name: 'add-step-status',
                              checked: addStepStatus === true,
                              onChange: (e, checked) => setAddStepStatus(checked),
                            }}
                            bottomOffset={0}
                        />
                      </div>
                    </form>
                  </div>
                </div>
              </div>
            </AccordionDetails>
          </Accordion>

          {/*Share*/}
          {tracking && tracking.id > 0 && <Share
              className="mb-3"
              share={tracking.id}
              module={'tracking'}
          />}

          <BottomToolbar
              right={<>
                <div className="d-flex justify-content-lg-end justify-content-start train-buttons">
                  <button
                      form="tracking-form-component"
                      className="btn btn-save"
                      disabled={saveProcess}
                  >
                    {t('buttons.save')}
                  </button>
                  <button
                      type="button"
                      className="btn btn-reload"
                      onClick={() => reset()}
                  >
                    {t('buttons.reset')}
                  </button>
                  <Link
                      to={'/admin/tracking'}
                      className="btn btn-close"
                  >
                    {t('buttons.close')}
                  </Link>
                </div>
                <div className="d-flex justify-content-end">
                  <Notify
                      type="save-form"
                      hide={!saveNotify}
                  />
                </div>
              </>}
          />
        </div>}
      </>
  );
};

export default TrackingForm;
