import moment from 'moment';

export default class IAuctionCity {

  id: number;
  city: string;
  state: string;
  port: string;
  country: string;
  toPortLandPriceCopart: string;
  toPortLandPriceIaai: string;
  toPortLandPriceManheim: string;
  odessaShippingPriceSedan: string;
  odessaShippingPriceCrossover: string;
  odessaShippingPriceHeavyDuty: string;
  createdAt: string;
  updatedAt: string;

  public static parse(data: any): IAuctionCity {
    if (!data) {
      return null;
    }

    const customerZoneAuto = new IAuctionCity();

    customerZoneAuto.id = data.id ? parseInt(data.id, 10) : null;
    customerZoneAuto.city = data.city ? data.city : null;
    customerZoneAuto.state = data.state ? data.state : null;
    customerZoneAuto.port = data.port ? data.port : null;
    customerZoneAuto.country = data.country ? data.country : null;
    customerZoneAuto.toPortLandPriceCopart = data.toPortLandPriceCopart ? data.toPortLandPriceCopart : null;
    customerZoneAuto.toPortLandPriceIaai = data.toPortLandPriceIaai ? data.toPortLandPriceIaai : null;
    customerZoneAuto.toPortLandPriceManheim = data.toPortLandPriceManheim ? data.toPortLandPriceManheim : null;
    customerZoneAuto.odessaShippingPriceSedan = data.odessaShippingPriceSedan ? data.odessaShippingPriceSedan : null;
    customerZoneAuto.odessaShippingPriceCrossover = data.odessaShippingPriceCrossover ? data.odessaShippingPriceCrossover : null;
    customerZoneAuto.odessaShippingPriceHeavyDuty = data.odessaShippingPriceHeavyDuty ? data.odessaShippingPriceHeavyDuty : null;
    customerZoneAuto.createdAt = data.created_at ? moment(data.created_at, 'YYYY-MM-DD HH:mm:ss').format('DD.MM.YYYY') : null;
    customerZoneAuto.updatedAt = data.updated_at ? moment(data.created_at, 'YYYY-MM-DD HH:mm:ss').format('DD.MM.YYYY') : null;

    return customerZoneAuto;
  }

}
