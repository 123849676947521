import React from 'react';
import ReactDOM from 'react-dom';
import {Provider} from 'react-redux';
import App from './App';
import Store from './Core/Store/Store';
//  jQuery
import $ from 'jquery';
//  Bootstrap
import 'bootstrap/dist/css/bootstrap.min.css';
import 'bootstrap/dist/js/bootstrap.bundle';
import {MuiPickersUtilsProvider} from '@material-ui/pickers';
import MomentUtils from '@date-io/moment';
import {ThemeProvider} from '@material-ui/styles';
import MuiTheme from './MuiTheme';

window['jQuery'] = $;
window['$'] = $;

// wrap the app in I18next Provider with the configuration loaded from i18n.js
ReactDOM.render(
  <Provider store={Store}>
    <ThemeProvider theme={MuiTheme}>
      <MuiPickersUtilsProvider utils={MomentUtils}>
        <App />
      </MuiPickersUtilsProvider>
    </ThemeProvider>
  </Provider>,
  document.getElementById('root')
);
