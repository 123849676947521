import React, {FC} from 'react';
import './FbButton.scss';

const FbButton: FC<{
  className?: string;
  style?: any;
  onClick?: any;
}> = (props) => {
  return (
    <button
      type="button"
      className={`btn-fb ${props.className ?? ''}`}
      style={props.style}
      onClick={props.onClick}
    >
      <img
        src={'/assets/images/fb-logo.png'}
        alt="fb"
        height={21}
      />
      Facebook
    </button>
  );
};

export default FbButton;
