import React, {useContext, useEffect, useRef, useState} from 'react';
import './Header.scss';
import Input from '../../Core/Form/Input/Input';
import {Link} from 'react-router-dom';
import {useTranslation} from 'react-i18next';
import AdminContext from '../AdminContext';

export default function Header({setMobileMenu}) {
  const {t} = useTranslation();
  const context = useContext(AdminContext);
  const user = context.auth.user;
  const profileRef = useRef();
  const [profileOpen, setProfileOpen] = useState(false);

  // Close profile menu
  useEffect(() => {
    function onWindowClick(e) {
      if (e.target.closest('.profile') !== profileRef.current)
        setProfileOpen(false);
    }

    window.addEventListener('click', onWindowClick);

    return () => window.removeEventListener('click', onWindowClick);
  }, []);

  function exit() {
    context.auth.clear();

    window.location.href = '/';
  }

  return (
    <div
      id="header-component"
      className="d-flex justify-content-between align-items-center"
    >
      <div className="row h-100 flex-grow-1">
        <div className="col-6 h-100 align-items-center search d-none d-md-flex">
          <Input
            hideLabel={true}
            input={{
              placeholder: 'Выполните поиск'
            }}
            bottomOffset={0}
          />
        </div>
        <div className="col-6 h-100 justify-content-end align-items-center d-none d-md-flex">
          <div className="d-flex align-items-center notify">
            <img
              src={'/assets/images/icon-notify-bell-active.png'}
              alt="Notify Bell Active"
            />
          </div>
          <div className="divider" />
          <div
            ref={profileRef}
            className="profile"
          >
            <div
              className="info"
              onClick={() => setProfileOpen(!profileOpen)}
            >
              <div
                className="avatar"
                style={{backgroundImage: `url('${user.avatar}')`}}
              >
              </div>

              <img
                src={'/assets/images/arrow-bottom.png'}
                alt="arrow-bottom"
                height={6}
              />
            </div>

            {/*Menu*/}
            <div className={`menu ${profileOpen ? ' open ' : ''}`}>
              <Link
                to="/admin/personal-data"
                className="item item-personal-data"
                onClick={() => setProfileOpen(false)}
              >
                <span className="item-icon" />
                <span>
                  {t('admin:header.personal_data')}
                </span>
              </Link>
              <div
                onClick={() => {
                  setProfileOpen(false);
                  exit();
                }}
                className="item item-exit"
              >
                <span className="item-icon" />
                <span>
                  {t('admin:header.exit')}
                </span>
              </div>
            </div>
          </div>
        </div>
        <div
          className="col-4 col-sm-4 d-flex align-items-center d-md-none"
        >
          <span
            className={`navbar-toggler-icon mr-3`}
            onClick={() => setMobileMenu(true)}
          />

          <a href="/#">
            <img
              width="85"
              src={'/assets/images/logo.png'}
              alt=""
            />
          </a>
        </div>
        <div className="col-8 col-sm-8 d-flex align-items-center justify-content-end d-md-none ">
          <a
            href="tel:2222"
            className="callback-mobile"
          >
            <img
              src={'/assets/images/call-answer.svg'}
              alt=""
            />
          </a>
        </div>
      </div>
    </div>
  );
}
