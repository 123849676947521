import moment, {Moment} from 'moment';
import Config from '../Providers/Config';

export default class ICustomerZoneAuto {

  id: number;
  buyerId: number;
  data: {
    dealer: number;
    buyerId: number;
    autoPurchaseDate: Moment;
    auction: string;
    orderType: string;
    vin: string;
    maker: string;
    model: string;
    year: number;
    lot: string;
    purchaseCity: number;
    shippingCompany: string;
    departurePort: number;
    autoPrice: number;
    lotLink: string;
    dispatch: string;
    deliveryCompany: string;
    dispatchDate: Moment;
    deliveryPrice: number;
    selfDelivery: string;
    warehouse: string;
    warehouseArrivalDate: Moment;
    titleReceivedDate: Moment;
    deliveryNotes: string;
    odometer: number;
    condition: string;
    keys: string;
    titleStatus: string;
    auctionContact: string;
    titleNotes: string;
    title: any[];
    clientPaymentDate: Moment;
    auctionPaymentDate: Moment;
    destinationPort: number;
    notes: string;
    dateOfLoading: Moment;
    sailDate: Moment;
    containerNumber: string;
    bookingNumber: string;
    eta: Moment;
    finalDestinationArrivalDate: Moment;
    /*wagonNumber: string;
    wagonLoadingPlace: string;
    wagonDestination: string;
    plannedWagonLading: Moment;
    wagonLoadingDate: Moment;
    wagonEstimateArrivalDate: Moment;
    wagonArrivalDate: Moment;
    wagonNotes: string;
    wagonNotesForYourself: string;*/
    tracking: string;
    finalDestinationCountry: string;
    finalDestinationCity: string;
    consolidation: string;
    shippingLine: string;
    trackingUrl: string;
    photosAuction: any[];
    photosBeforeLoading: any[];
    photosAfterUploading: any[];
    photosFromDriver: any[];
    dockReceipt: any[];
    billOfLading: any[];
    billOfSale: any[];
    carfaxAutocheck: any[];
    conosament: any[];
  };
  code: string;
  invoices: number[];
  createdAt: Moment;
  updatedAt: Moment;

  public static parse(data: any): ICustomerZoneAuto {
    if (!data) {
      return null;
    }

    const customerZoneAuto = new ICustomerZoneAuto();

    customerZoneAuto.id = data.id ? parseInt(data.id, 10) : null;
    customerZoneAuto.buyerId = data.buyer_id > 0 ? data.buyer_id : null;
    customerZoneAuto.data = {
      dealer: data.data?.['dealer'] ?? null,
      buyerId: data.data?.['buyer_id'] ?? null,
      autoPurchaseDate: moment(data.data?.['auto_purchase_date'], Config.DATE_FORMAT).isValid() ?
        moment(data.data?.['auto_purchase_date'], Config.DATE_FORMAT) : null,
      auction: data.data?.['auction'] ?? null,
      orderType: data.data?.['order_type'] ?? null,
      vin: data.data?.['vin'] ?? null,
      maker: data.data?.['maker'] ?? null,
      model: data.data?.['model'] ?? null,
      year: data.data?.['year'] ?? null,
      lot: data.data?.['lot'] ?? null,
      purchaseCity: data.data?.['purchase_city'] ?? null,
      shippingCompany: data.data?.['shipping_company'] ?? null,
      departurePort: data.data?.['departure_port'] ?? null,
      autoPrice: data.data?.['auto_price'] ?? null,
      lotLink: data.data?.['lot_link'] ?? null,
      dispatch: data.data?.['dispatch'] ?? null,
      deliveryCompany: data.data?.['delivery_company'] ?? null,
      dispatchDate: moment(data.data?.['dispatch_date'], Config.DATE_FORMAT).isValid() ?
        moment(data.data?.['dispatch_date'], Config.DATE_FORMAT) : null,
      deliveryPrice: data.data?.['delivery_price'] ?? null,
      selfDelivery: data.data?.['self_delivery'] ?? null,
      warehouse: data.data?.['warehouse'] ?? null,
      warehouseArrivalDate: moment(data.data?.['warehouse_arrival_date'], Config.DATE_FORMAT).isValid() ?
        moment(data.data?.['warehouse_arrival_date'], Config.DATE_FORMAT) : null,
      titleReceivedDate: moment(data.data?.['title_received_date'], Config.DATE_FORMAT).isValid() ?
          moment(data.data?.['title_received_date'], Config.DATE_FORMAT) : null,
      deliveryNotes: data.data?.['delivery_notes'] ?? null,
      odometer: data.data?.['odometer'] ?? null,
      condition: data.data?.['condition'] ?? null,
      keys: data.data?.['keys'] ?? null,
      titleStatus: data.data?.['title_status'] ?? null,
      auctionContact: data.data?.['auction_contact'] ?? null,
      titleNotes: data.data?.['title_notes'] ?? null,
      title: Array.isArray(data.data?.['title']) ? data.data?.['title'] : [],
      clientPaymentDate: moment(data.data?.['client_payment_date'], Config.DATE_FORMAT).isValid() ?
        moment(data.data?.['client_payment_date'], Config.DATE_FORMAT) : null,
      auctionPaymentDate: moment(data.data?.['auction_payment_date'], Config.DATE_FORMAT).isValid() ?
        moment(data.data?.['auction_payment_date'], Config.DATE_FORMAT) : null,
      destinationPort: isNaN(parseInt(data.data?.destination_port)) ? null : parseInt(data.data?.destination_port),
      notes: data.data?.['notes'] ?? null,
      dateOfLoading: moment(data.data?.['date_of_loading'], Config.DATE_FORMAT).isValid() ?
        moment(data.data?.['date_of_loading'], Config.DATE_FORMAT) : null,
      sailDate: moment(data.data?.['sail_date'], Config.DATE_FORMAT).isValid() ?
        moment(data.data?.['sail_date'], Config.DATE_FORMAT) : null,
      containerNumber: data.data?.['container_number'] ?? null,
      bookingNumber: data.data?.['booking_number'] ?? null,
      eta: moment(data.data?.['eta'], Config.DATE_FORMAT).isValid() ?
        moment(data.data?.['eta'], Config.DATE_FORMAT) : null,
      finalDestinationArrivalDate: moment(data.data?.['final_destination_arrival_date'], Config.DATE_FORMAT).isValid() ?
        moment(data.data?.['final_destination_arrival_date'], Config.DATE_FORMAT) : null,
      /*wagonNumber: data.data?.['wagon_number'],
      wagonLoadingPlace: data.data?.['wagon_loading_place'],
      wagonDestination: data.data?.['wagon_destination'],
      plannedWagonLading: moment(data.data?.['planned_wagon_lading'], Config.DATE_FORMAT).isValid() ?
        moment(data.data?.['planned_wagon_lading'], Config.DATE_FORMAT) : null,
      wagonLoadingDate: moment(data.data?.['wagon_loading_date'], Config.DATE_FORMAT).isValid() ?
        moment(data.data?.['wagon_loading_date'], Config.DATE_FORMAT) : null,
      wagonEstimateArrivalDate: moment(data.data?.['wagon_estimate_arrival_date'], Config.DATE_FORMAT).isValid() ?
        moment(data.data?.['wagon_estimate_arrival_date'], Config.DATE_FORMAT) : null,
      wagonArrivalDate: moment(data.data?.['wagon_arrival_date'], Config.DATE_FORMAT).isValid() ?
        moment(data.data?.['wagon_arrival_date'], Config.DATE_FORMAT) : null,
      wagonNotes: data.data?.['wagon_notes'] ?? null,
      wagonNotesForYourself: data.data?.['wagon_notes_for_yourself'] ?? null,*/
      tracking: data.data?.['tracking'] ?? null,
      finalDestinationCountry: data.data?.['final_destination_country'] ?? null,
      finalDestinationCity: data.data?.['final_destination_city'] ?? null,
      consolidation: data.data?.['consolidation'] ?? null,
      shippingLine: data.data?.['shipping_line'] ?? null,
      trackingUrl: data.data?.['tracking_url'] ?? null,
      photosAuction: Array.isArray(data.data?.['photos_auction']) ? data.data?.['photos_auction'] : [],
      photosBeforeLoading: Array.isArray(data.data?.['photos_before_loading']) ? data.data?.['photos_before_loading'] : [],
      photosAfterUploading: Array.isArray(data.data?.['photos_after_uploading']) ? data.data?.['photos_after_uploading'] : [],
      photosFromDriver: Array.isArray(data.data?.['photos_from_driver']) ? data.data?.['photos_from_driver'] : [],
      dockReceipt: Array.isArray(data.data?.['dock_receipt']) ? data.data?.['dock_receipt'] : [],
      billOfLading: Array.isArray(data.data?.['bill_of_lading']) ? data.data?.['bill_of_lading'] : [],
      billOfSale: Array.isArray(data.data?.['bill_of_sale']) ? data.data?.['bill_of_sale'] : [],
      carfaxAutocheck: Array.isArray(data.data?.['carfax_autocheck']) ? data.data?.['carfax_autocheck'] : [],
      conosament: Array.isArray(data.data?.['conosament']) ? data.data?.['conosament'] : [],
    };
    customerZoneAuto.invoices = data?.invoices ?? null;
    customerZoneAuto.createdAt = moment(data.created_at).isValid() ? moment(data.created_at) : null;
    customerZoneAuto.updatedAt = moment(data.updated_at).isValid() ? moment(data.updated_at) : null;

    return customerZoneAuto;
  }

}
