import axios from 'axios';
import Config from './Config';
import IBuyerId from '../Interfaces/IBuyerId';

export default class BuyerId {

  // Load list
  async list(data: any = {}): Promise<{
    data: IBuyerId[],
    count: number
  }|false> {
    const response = (await axios.get(Config.api('/buyer-id/list'), {params: data})).data;

    if (!response.success) {
      console.error(response.message);

      return false;
    }

    //  Skip empty
    if (!response.data)
      return false;

    const responseData = response.data;

    return {
      data: responseData.data.map(item => IBuyerId.parse(item)),
      count: responseData.count
    };
  }

  // Remove by ID
  async delete(id: number|number[]): Promise<boolean> {
    const response = (await axios.post(Config.api('/buyer-id/delete'), {id})).data;

    if (!response.success) {
      console.error(response.message);

      return false;
    }

    return true;
  }

  // Load form
  async get(id): Promise<IBuyerId> {
    const response = (await axios.get(Config.api(`/buyer-id/get/${id}`))).data;

    if (!response.success) {
      console.error(response.message);

      return null;
    }

    //  Skip empty
    if (!response.data)
      return null;

    return IBuyerId.parse(response.data);
  }

  // Create
  async create(data: any): Promise<any> {
    const response = (await axios.post(Config.api(`/buyer-id/create`), data)).data;

    if (!response.success) {
      throw new Error(response);
    }

    return response.data;
  }

  // Update
  async update(id: number, data: any): Promise<any> {
    const response = (await axios.post(Config.api(`/buyer-id/update/${id}`), data)).data;

    if (!response.success) {
      throw new Error(response);
    }

    return response.data;
  }

}
