import React, {FC, useEffect, useRef, useState} from 'react';
import {useTranslation} from 'react-i18next';
import {useHistory, useParams} from 'react-router-dom';
import './RolesForm.scss';
import Input from '../../Core/Form/Input/Input';
import axios from 'axios';
import Config from '../../Providers/Config';
import $ from 'jquery';
import {IHttpResponse} from '../../Interfaces/IHttpResponse';
import * as EgoUtil from '@egofoxlab/util';
import Notify from '../../Core/Ui/Notify/Notify';
import IRole from '../../Interfaces/IRole';
import Checkbox from '../../Core/Form/Checkbox/Checkbox';
import {UserPermission} from '../../Providers/UserPermission';
import Head from '../Head/Head';
import BottomToolbar from '../BottomToolbar/BottomToolbar';

// Load form
async function load(id) {
  const response = (await axios.get(Config.api(`/role/get/${id}`))).data;

  if (!response.success) {
    console.error(response.message);

    return false;
  }

  return response.data;
}

const RolesForm: FC = () => {
  const {t} = useTranslation();
  const history = useHistory();
  const id = parseInt(useParams().id) || null;
  const [role, setRole] = useState<IRole>();
  const [permissions, setPermissions] = useState([]);
  const [saveProcess, setSaveProcess] = useState(false);
  const [saveNotify, setSaveNotify] = useState(false);
  const _eForm = useRef<HTMLFormElement>();

  useEffect(() => {
    (async () => {
      const role = IRole.parse(id > 0 ? await load(id) : {});

      if (!role) {
        history.push('/admin/roles');

        return;
      }

      setPermissions(role.permissions ?? []);
      setRole(role);
    })();
    // eslint-disable-next-line
  }, []);

  function reset(): void {
    //  Clear input field
    EgoUtil.clearFields(_eForm.current);
    setPermissions([]);
  }

  function onSubmit(e): void {
    if (!_eForm.current.checkValidity()) {
      return;
    }

    e.preventDefault();

    //  Set `Save Process` flag
    setSaveProcess(true);

    const eForm = $(_eForm.current);
    const formData = new FormData();
    //  Name
    formData.append('name', eForm.find('[name="name"]').val());
    //  Permissions
    eForm.find('[name="permissions[]"]:checked').each((i, node) => {
      formData.append('permissions[]', $(node).val());
    });

    //  Save form
    let url;

    if (role && role.id > 0) {
      url = Config.api(`/role/update/${role.id}`);
    } else {
      url = Config.api('/role/create');
    }

    axios.post(url, formData, {
      headers: {'Content-Type': 'multipart/form-data'}
    })
      .then((_response) => {
        const response: IHttpResponse = _response.data;

        if (response.code !== 200) {
          console.error(response.message);
          //  Unset `Save Process` flag
          setSaveProcess(false);

          return;
        }

        //  Mark as saved
        setSaveNotify(true);

        //  Add role to state after saving
        if (!role || !(role.id > 0)) {
          history.replace(`/admin/roles/form/${response.data.id}`);

          //  Update role state
          setRole(IRole.parse(response.data));
        }

        //  Unset `Save Process` flag
        setSaveProcess(false);
      });
  }

  const onPermissionChange = (e, checked) => {
    const value = e.target.value;
    const _permissions = [...permissions];

    if (checked) {
      if (_permissions.indexOf(value) === -1) {
        _permissions.push(value);
      }
    } else {
      if (_permissions.indexOf(value) !== -1) {
        _permissions.splice(permissions.indexOf(value), 1);
      }
    }

    setPermissions(_permissions);
  };

  return (
    <>
      {role !== undefined ?
        <div
          id="roles-component"
        >
          <Head
            head={t('admin:roles.form.head')}
            right={<div className="d-flex justify-content-lg-end justify-content-start mt-3 mt-lg-0 train-buttons">
              <button
                form="roles-form-component"
                className="btn btn-save"
                disabled={saveProcess}
              >
                {t('buttons.save')}
              </button>
              <button
                type="button"
                className="btn btn-reload"
                onClick={() => reset()}
              >
                {t('buttons.reset')}
              </button>
              <button
                type="button"
                className="btn btn-remove"
              >
                {t('buttons.remove')}
              </button>
            </div>}
            hr={{style: {marginTop: 20}}}
          />

          <form
            ref={_eForm}
            id="roles-form-component"
            onSubmit={onSubmit}
          >
            <div className="row">
              <div className="col-12 col-md-6">
                {/*Name*/}
                <Input
                  label={t('admin:roles.name')}
                  input={{
                    name: 'name',
                    defaultValue: role ? role.displayName : null,
                    required: true
                  }}
                />
              </div>

              <div className="col-12 col-md-6">
              </div>
            </div>

            <div className="row">
              <div className="col-12 col-md-6 col-lg-4">
                <div>
                  {/*Customer Zone Autos Full*/}
                  <Checkbox
                    label={{
                      label: t('admin:roles.customer_zone_autos_full')
                    }}
                    checkbox={{
                      name: 'permissions[]',
                      value: 'customer-zone-autos-full',
                      checked: permissions.includes('customer-zone-autos-full'),
                      onChange: onPermissionChange
                    }}
                  />
                </div>
                <div>
                  {/*Customer Zone Autos Readonly*/}
                  <Checkbox
                    label={{
                      label: t('admin:roles.customer_zone_autos_readonly')
                    }}
                    checkbox={{
                      name: 'permissions[]',
                      value: 'customer-zone-autos-readonly',
                      checked: permissions.includes('customer-zone-autos-readonly'),
                      onChange: onPermissionChange
                    }}
                  />
                </div>
                <div>
                  {/*Customer Zone Autos Shared*/}
                  <Checkbox
                    label={{
                      label: t('admin:roles.customer_zone_autos_shared')
                    }}
                    checkbox={{
                      name: 'permissions[]',
                      value: UserPermission.CUSTOMER_ZONE_AUTOS_SHARED,
                      checked: permissions.includes(UserPermission.CUSTOMER_ZONE_AUTOS_SHARED),
                      onChange: onPermissionChange
                    }}
                  />
                </div>
                <div>
                  {/*Invoices Full*/}
                  <Checkbox
                    label={{
                      label: t('admin:roles.invoices_full')
                    }}
                    checkbox={{
                      name: 'permissions[]',
                      value: 'invoices-full',
                      checked: permissions.includes('invoices-full'),
                      onChange: onPermissionChange
                    }}
                  />
                </div>
                <div>
                  {/*Invoices Readonly*/}
                  <Checkbox
                    label={{
                      label: t('admin:roles.invoices_readonly')
                    }}
                    checkbox={{
                      name: 'permissions[]',
                      value: 'invoices-readonly',
                      checked: permissions.includes('invoices-readonly'),
                      onChange: onPermissionChange
                    }}
                  />
                </div>
                <div>
                  {/*Invoices Shared*/}
                  <Checkbox
                    label={{
                      label: t('admin:roles.invoices_shared')
                    }}
                    checkbox={{
                      name: 'permissions[]',
                      value: UserPermission.INVOICES_SHARED,
                      checked: permissions.includes(UserPermission.INVOICES_SHARED),
                      onChange: onPermissionChange
                    }}
                  />
                </div>
                <div>
                  {/*Customer Zone Balances Full*/}
                  <Checkbox
                    label={{
                      label: t('admin:roles.customer_zone_balances_full')
                    }}
                    checkbox={{
                      name: 'permissions[]',
                      value: 'customer-zone-balances-full',
                      checked: permissions.includes('customer-zone-balances-full'),
                      onChange: onPermissionChange
                    }}
                  />
                </div>
                <div>
                  {/*Customer Zone Balances Readonly*/}
                  <Checkbox
                    label={{
                      label: t('admin:roles.customer_zone_balances_readonly')
                    }}
                    checkbox={{
                      name: 'permissions[]',
                      value: 'customer-zone-balances-readonly',
                      checked: permissions.includes('customer-zone-balances-readonly'),
                      onChange: onPermissionChange
                    }}
                  />
                </div>
                <div>
                  {/*Customer Zone Balances Shared*/}
                  <Checkbox
                    label={{
                      label: t('admin:roles.customer_zone_balances_shared')
                    }}
                    checkbox={{
                      name: 'permissions[]',
                      value: UserPermission.CUSTOMER_ZONE_BALANCES_SHARED,
                      checked: permissions.includes(UserPermission.CUSTOMER_ZONE_BALANCES_SHARED),
                      onChange: onPermissionChange
                    }}
                  />
                </div>
              </div>

              <div className="col-12 col-md-6 col-lg-4">
                <div>
                  {/*Trackings Full*/}
                  <Checkbox
                    label={{
                      label: t('admin:roles.trackings_full')
                    }}
                    checkbox={{
                      name: 'permissions[]',
                      value: 'trackings-full',
                      checked: permissions.includes('trackings-full'),
                      onChange: onPermissionChange
                    }}
                  />
                </div>
                <div>
                  {/*Trackings Readonly*/}
                  <Checkbox
                    label={{
                      label: t('admin:roles.trackings_readonly')
                    }}
                    checkbox={{
                      name: 'permissions[]',
                      value: 'trackings-readonly',
                      checked: permissions.includes('trackings-readonly'),
                      onChange: onPermissionChange
                    }}
                  />
                </div>
                <div>
                  {/*Trackings Shared*/}
                  <Checkbox
                    label={{
                      label: t('admin:roles.trackings_shared')
                    }}
                    checkbox={{
                      name: 'permissions[]',
                      value: UserPermission.TRACKINGS_SHARED,
                      checked: permissions.includes(UserPermission.TRACKINGS_SHARED),
                      onChange: onPermissionChange
                    }}
                  />
                </div>
                <div>
                  {/*Buyer ID Full*/}
                  <Checkbox
                    label={{
                      label: t('admin:roles.buyer_id_full')
                    }}
                    checkbox={{
                      name: 'permissions[]',
                      value: UserPermission.BUYER_ID_FULL,
                      checked: permissions.includes(UserPermission.BUYER_ID_FULL),
                      onChange: onPermissionChange
                    }}
                  />
                </div>
                <div>
                  {/*Buyer ID Readonly*/}
                  <Checkbox
                    label={{
                      label: t('admin:roles.buyer_id_readonly')
                    }}
                    checkbox={{
                      name: 'permissions[]',
                      value: UserPermission.BUYER_ID_READONLY,
                      checked: permissions.includes(UserPermission.BUYER_ID_READONLY),
                      onChange: onPermissionChange
                    }}
                  />
                </div>
                <div>
                  {/*Users Full*/}
                  <Checkbox
                    label={{
                      label: t('admin:roles.users_full')
                    }}
                    checkbox={{
                      name: 'permissions[]',
                      value: UserPermission.USERS_FULL,
                      checked: permissions.includes(UserPermission.USERS_FULL),
                      onChange: onPermissionChange
                    }}
                  />
                </div>
                <div>
                  {/*Users Readonly*/}
                  <Checkbox
                    label={{
                      label: t('admin:roles.users_readonly')
                    }}
                    checkbox={{
                      name: 'permissions[]',
                      value: UserPermission.USERS_READONLY,
                      checked: permissions.includes(UserPermission.USERS_READONLY),
                      onChange: onPermissionChange
                    }}
                  />
                </div>
                <div>
                  {/*Promotions*/}
                  <div>
                    <Checkbox
                      label={{
                        label: t('admin:roles.promotions')
                      }}
                      checkbox={{
                        name: 'permissions[]',
                        value: 'promotions',
                        checked: permissions.includes('promotions'),
                        onChange: onPermissionChange
                      }}
                    />
                  </div>
                </div>
              </div>

              <div className="col-12 col-md-6 col-lg-4">
                <div>
                  {/*Payment Data*/}
                  <div>
                    <Checkbox
                      label={{
                        label: t('admin:roles.payment_data')
                      }}
                      checkbox={{
                        name: 'permissions[]',
                        value: 'payment-data',
                        checked: permissions.includes('payment-data'),
                        onChange: onPermissionChange
                      }}
                    />
                  </div>
                </div>
                <div>
                  {/*Calculator*/}
                  <Checkbox
                    label={{
                      label: t('admin:roles.calculator')
                    }}
                    checkbox={{
                      name: 'permissions[]',
                      value: UserPermission.CALCULATOR,
                      checked: permissions.includes(UserPermission.CALCULATOR),
                      onChange: onPermissionChange
                    }}
                  />
                </div>
                <div>
                  {/*Referral Program Full*/}
                  <Checkbox
                    label={{
                      label: t('admin:roles.referral_program_full')
                    }}
                    checkbox={{
                      name: 'permissions[]',
                      value: 'referral-program-full',
                      checked: permissions.includes('referral-program-full'),
                      onChange: onPermissionChange
                    }}
                  />
                </div>
                <div>
                  {/*Referral Program Readonly*/}
                  <Checkbox
                    label={{
                      label: t('admin:roles.referral_program_readonly')
                    }}
                    checkbox={{
                      name: 'permissions[]',
                      value: 'referral-program-readonly',
                      checked: permissions.indexOf('referral-program-readonly') !== -1,
                      onChange: onPermissionChange
                    }}
                  />
                </div>
              </div>
            </div>

            <hr style={{marginTop: '29px'}} />

            <div className="row">
              <div className="col-12">
                <p style={{whiteSpace: 'pre-wrap', fontSize: 12}}>{t('admin:roles.description')}</p>
              </div>
            </div>
          </form>

          <BottomToolbar
            right={<>
              <div className="d-flex justify-content-lg-end justify-content-start train-buttons">
                <button
                  form="roles-form-component"
                  className="btn btn-save"
                  disabled={saveProcess}
                >
                  {t('buttons.save')}
                </button>
                <button
                  type="button"
                  className="btn btn-reload"
                  onClick={() => reset()}
                >
                  {t('buttons.reset')}
                </button>
                <button
                  type="button"
                  className="btn btn-remove"
                >
                  {t('buttons.remove')}
                </button>
              </div>
              <div className="d-flex justify-content-end">
                <Notify
                  type="save-form"
                  hide={!saveNotify}
                />
              </div>
            </>}
          />
        </div>
        :
        ''
      }
    </>
  );
};

export default RolesForm;
