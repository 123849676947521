import axios from 'axios';
import Config from './Config';
import IRole from '../Interfaces/IRole';

export default class Role {

  // Load list
  async list(data: any = {}): Promise<{
    data: IRole[],
    count: number
  }|false> {
    const response = (await axios.get(Config.api('/role/list'), {params: data})).data;

    if (!response.success) {
      console.error(response.message);

      return false;
    }

    //  Skip empty
    if (!response.data)
      return false;

    const responseData = response.data;

    return {
      data: responseData.data.map(item => IRole.parse(item)),
      count: responseData.count
    };
  }

  // Remove by ID
  async delete(id: number|number[]): Promise<boolean> {
    const response = (await axios.post(Config.api('/role/delete'), {id})).data;

    if (!response.success) {
      console.error(response.message);

      return false;
    }

    return true;
  }

}
