import React, {FC, useState} from 'react';
import {useTranslation} from 'react-i18next';
import Select, {SelectStylesHideIndicatorSeparator} from '../../Core/Form/Select/Select';
import Input from '../../Core/Form/Input/Input';
import DownArrowIndicator from '../../Core/Form/Select/Indicators/DownArrowIndicator';
import axios from 'axios';
import Config from '../../Providers/Config';

const UsersFilter: FC<{
  onFilter?: (filter: any) => void
}> = (props) => {
  const {t} = useTranslation();
  const [filter, setFilter] = useState<{
    company?: string;
    email?: string;
    role?: number;
  }>({});

  // Search roles based on input
  const roleLoadOptions = async input => {
    input = input ? input : '';

    // Min input length
    if (input && input.length < 2)
      return false;

    const response = (await axios.get(Config.api(`/role/search?term=${input}`))).data;

    if (!response.success)
      return [];

    return response.data.map((item) => ({
      value: item.id,
      label: item.display_name
    }));
  };

  return (
    <div className="row table-filter">
      <div className="col-12 col-md-6 col-lg-4 col-xl-3">
        <Input
          hideLabel={true}
          input={{
            placeholder: t('admin:filter.company'),
            onChange: e => setFilter({...filter, company: e.target.value})
          }}
        />
      </div>
      <div className="col-12 col-md-6 col-lg-4 col-xl-3">
        <Input
          hideLabel={true}
          input={{
            placeholder: t('admin:filter.email'),
            onChange: e => setFilter({...filter, email: e.target.value})
          }}
        />
      </div>
      <div className="col-12 col-md-6 col-lg-4 col-xl-3 d-flex align-items-center table-filter-with-label">
        <Select
          hideLabel={true}
          async={true}
          select={{
            cacheOptions: true,
            loadOptions: roleLoadOptions,
            defaultOptions: true,
            placeholder: t('admin:filter.role'),
            components: {
              DropdownIndicator: DownArrowIndicator,
            },
            styles: SelectStylesHideIndicatorSeparator,
            onChange: option => setFilter({...filter, role: option.value})
          }}
          required={true}
          />
      </div>
      <div className="col-12 col-md-12 col-lg-3 col-xl-2 d-flex justify-content-center justify-content-lg-start">
        <button
          className="btn btn-submit mb-control"
          onClick={() => {
            if (typeof props.onFilter === 'function')
              props.onFilter(filter);
          }}
        >
          {t('buttons.search')}
        </button>
      </div>
    </div>
  );
}

export default UsersFilter;
