import UserStatus from '../Providers/UserStatus';
import IShare from './IShare';
import IRole from './IRole';
import moment, {Moment} from 'moment';
import IBuyerId from './IBuyerId';

export default class IUser {

  id: number;
  firstName: string;
  lastName: string;
  email: string;
  token: string;
  avatar: string;
  status: number;
  roleGroup: string;
  role: IRole;
  buyerId: number;
  company: string;
  country: string;
  city: string;
  phone: string;
  subscription: boolean;
  cardName: string;
  cardNumber: string;
  referralCode: string;
  createdAt: Moment;
  updatedAt: Moment;
  share: IShare[];
  buyerIds: IBuyerId[];

  public static parse(data: any): IUser {
    if (!data) {
      return null;
    }

    const user = new IUser();

    user.id = data.id ? parseInt(data.id, 10) : null;
    user.firstName = data.first_name ? data.first_name : '';
    user.lastName = data.last_name ? data.last_name : '';
    user.email = data.email ? data.email : '';
    user.token = data.api_token ? data.api_token : '';
    user.avatar = data.avatar ? data.avatar : '/assets/images/icon-user-photo.svg';
    user.status = data.status ? data.status : UserStatus.NOT_ACTIVE;
    user.roleGroup = data.role_group ?? null;
    user.role = data.role ? IRole.parse(data.role) : IRole.parse({});
    user.company = data.company ? data.company : null;
    user.country = data.country ? data.country : null;
    user.city = data.city ? data.city : '';
    user.phone = data.phone ? data.phone : '';
    user.subscription = data.subscription ? data.subscription : false;
    user.cardName = data.card_name ? data.card_name : '';
    user.cardNumber = data.card_number ? data.card_number : '';
    user.referralCode = data.referral_code ? data.referral_code : '';
    user.createdAt = moment(data.created_at).isValid() ? moment(data.created_at) : null;
    user.updatedAt = moment(data.updated_at).isValid() ? moment(data.updated_at) : null;
    //  Share
    user.share = Array.isArray(data.share) ? data.share.map((share) => IShare.parse(share)) : [];
    // Buyer IDs
    user.buyerIds = data.buyer_ids?.map(item => IBuyerId.parse(item)) ?? [];

    return user;
  }

  /**
   * Check is user have shared stuff
   *
   * @param module
   * @param share
   */
  public isShare(module: string, share: string): boolean {
    if (!Array.isArray(this.share)) {
      return false;
    }

    for (const _share of this.share) {
      if (_share.module === module && _share.share === share) {
        return true;
      }
    }

    return false;
  }

}
