import React, {FC} from 'react';
import './style.scss';
import {useTranslation} from 'react-i18next';

const Notify: FC<{
  className?: string;
  type: string;
  message?: string;
  hide?: boolean;
}> = (props) => {
  const {t} = useTranslation();

  /**
   * Return message/s
   */
  function getMessages(messages?): string[] {
    messages = messages ? messages : props.message;
    let result = [];

    if (messages && typeof messages === 'string') {
      return [messages];
    } else if (messages && Array.isArray(messages)) {
      return messages;
    } else if (messages && typeof messages === 'object') {
      for (const key in messages) {
        if (!messages[key]) {
          continue;
        }

        result = result.concat(getMessages(messages[key]));
      }

      return result;
    }

    const message = {
      'save-form': t('notify.save_form')
    }[props.type];

    if (message)
      return [message];

    return [];
  }

  return (
    <>
      {!props.hide && <div
          className={`d-flex notify-component ${props.className ? props.className : ''}`}
      >
        {props.type === 'save-form' && <div className="notify save-form">
          {getMessages().map((message, i) =>
            <div
              key={i}
              className={'message'}
            >
              <img
                src={'/assets/images/icon-checked-symbol.svg'}
                alt="checked-symbol"
                width={15}
              />
              <span>
                {message}
              </span>
            </div>
          )}
        </div>}
        {props.type === 'error' && <div className="notify error">
          {getMessages().map((message, i) =>
            <div
              key={i}
              className={'message'}
            >
              <span>
                {message}
              </span>
            </div>
          )}
        </div>}
      </div>}
    </>
  );
};

export default Notify;
