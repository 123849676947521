import React from 'react';
import './Radio.scss';
import {Radio, FormControlLabel} from '@material-ui/core';

const RadioIcon = () => {
  return (
    <span className={'radio-icon'} />
  );
};

const RadioIconChecked = () => {
  return (
    <span className={'radio-icon checked'} />
  );
};

export default function RadioComponent(props) {
  return (
    <div
      className="form-field radio"
      style={{
        ...props.style,
        marginBottom: props.bottomOffset
      }}
    >
      <div className="field">
        {props.label ?
            <FormControlLabel
              {...props.label}
              control={<Radio
                disableRipple={true}
                icon={<RadioIcon />}
                checkedIcon={<RadioIconChecked />}
                {...props.radio}
              />}
            />
        :
          <Radio
            disableRipple={true}
            icon={<RadioIcon />}
            checkedIcon={<RadioIconChecked />}
            {...props.radio}
          />
        }
      </div>
    </div>
  );
}
