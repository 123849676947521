import moment, {Moment} from 'moment';

export default class IBuyerId {

  id: number;
  login: string;
  auction: string;
  createdAt: Moment;
  updatedAt: Moment;

  public static parse(data: any): IBuyerId {
    if (!data) {
      return null;
    }

    const buyerId = new IBuyerId();

    buyerId.id = data.id ? parseInt(data.id, 10) : null;
    buyerId.login = data.login ?? null;
    buyerId.auction = data.auction ?? null;
    buyerId.createdAt = moment(data.created_at).isValid() ? moment(data.created_at) : null;
    buyerId.updatedAt = moment(data.updated_at).isValid() ? moment(data.updated_at) : null;

    return buyerId;
  }

}
