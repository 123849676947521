import React, {FC} from 'react';
import './Errors.scss';

const Errors: FC<{
  className?: string;
  errors: string[];
  nested?: boolean;
  style?: any;
  bottomOffset?: string | number;
}> = (props) => {
  let errors = Array.isArray(props.errors) ? props.errors : [];

  if (props.nested === true && props.errors) {
    errors = [];

    for (const key in props.errors) {
      errors = [...errors, props.errors[key]];
    }
  }

  return (
    <div
      className={`d-flex errors-component ${props.className ? props.className : ''}`}
      style={props.style}
    >
      {
        errors.map((item, i) =>
          <div
            key={i}
            className={'error'}
          >
            {item}
          </div>
        )
      }
    </div>
  );
}

export default Errors;
