import React, {FC, useEffect, useState} from 'react';
import {useTranslation} from 'react-i18next';
import {useHistory, useParams} from 'react-router-dom';
import './BuyerIdsView.scss';
import BuyerIdProvider from '../../Providers/BuyerId';
import ViewValue from '../../Core/Form/ViewValue/ViewValue';
import Head from '../Head/Head';
import Auction from '../../Providers/Auction';
import IBuyerId from '../../Interfaces/IBuyerId';

const buyerIdProvider = new BuyerIdProvider();

const BuyerIdsView: FC = () => {
  const {t} = useTranslation();
  const auctionOptions = [
    {
      value: Auction.COPART,
      label: t(`admin:auction.${Auction.COPART}`)
    },
    {
      value: Auction.IAAI,
      label: t(`admin:auction.${Auction.IAAI}`)
    },
    {
      value: Auction.MANHEIM,
      label: t(`admin:auction.${Auction.MANHEIM}`)
    },
    {
      value: Auction.GR8AUTO,
      label: t(`admin:auction.${Auction.GR8AUTO}`)
    },
    {
      value: Auction.IMPACTAUTO,
      label: t(`admin:auction.${Auction.IMPACTAUTO}`)
    },
  ];
  const history = useHistory();
  const id = parseInt(useParams().id);
  const [buyerId, setBuyerId] = useState<IBuyerId>(null);

  useEffect(() => {
    (async () => {
      if (!(id > 0)) {
        history.push('/admin/buyer-ids');

        return;
      }

      const buyerId = await buyerIdProvider.get(id);

      if (!buyerId) {
        history.push('/admin/buyer-ids');

        return;
      }

      setBuyerId(buyerId);
    })();
    // eslint-disable-next-line
  }, []);

  return (
    <>
      {buyerId && <div id="buyer-ids-view-component">
        <Head
            head={t('admin:buyer_ids.form.head')}
            subhead={'VIN - Auto'}
        />

        <form id="buyer-ids-form">
          <div className="row">
            {/*Login*/}
            <ViewValue
                className="col-12 col-md-6"
                label={t('admin:buyer_ids.login')}
                value={buyerId.login ?? ''}
            />

            {/*Role group*/}
            <ViewValue
                className="col-12 col-md-6"
                label={t('admin:buyer_ids.auction')}
                value={auctionOptions.find(item => item.value === buyerId.auction)?.label ?? null}
            />
          </div>

          <hr style={{marginTop: '29px'}} />
        </form>
      </div>}
    </>
  );
};

export default BuyerIdsView;
