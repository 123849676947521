import React, {FC, useEffect, useRef, useState} from 'react';
import {useTranslation} from 'react-i18next';
import {Link, useHistory, useParams} from 'react-router-dom';
import './CustomerZoneBalanceForm.scss';
import Input from '../../Core/Form/Input/Input';
import Textarea from '../../Core/Form/Textarea/Textarea';
import axios from 'axios';
import Config from '../../Providers/Config';
import $ from 'jquery';
import {IHttpResponse} from '../../Interfaces/IHttpResponse';
import Notify from '../../Core/Ui/Notify/Notify';
import Share from '../Share/Share';
import Head from '../Head/Head';
import BottomToolbar from '../BottomToolbar/BottomToolbar';
import {Accordion, AccordionDetails, AccordionSummary} from '@material-ui/core';
import CustomerZoneAutoProvider from '../../Providers/CustomerZoneAuto';
import CustomerZoneBalanceProvider from '../../Providers/CustomerZoneBalance';
import ICustomerZoneBalance from '../../Interfaces/ICustomerZoneBalance';
import ICustomerZoneAuto from '../../Interfaces/ICustomerZoneAuto';
import Cars from '../../Providers/Cars';
import Auction from '../../Providers/Auction';
import DeparturePortProvider from '../../Providers/DeparturePort';

const customerZoneBalanceProvider = new CustomerZoneBalanceProvider();
const customerZoneAutoProvider = new CustomerZoneAutoProvider();
const auctionCityProvider = new Auction.City();
const departurePortProvider = new DeparturePortProvider();

const CustomerZoneBalanceForm: FC = () => {
  const {t} = useTranslation();
  const auctionOptions = Cars.auctions().map(item => ({
    value: item,
    label: t(`admin:auction.${item}`)
  }));
  const orderTypeOptions = [
    {
      value: 'auto',
      label: t('admin:order_type.auto')
    },
  ];
  const history = useHistory();
  const id = parseInt(useParams().id) || null;
  const [customerZoneBalance, setCustomerZoneBalance] = useState<ICustomerZoneBalance>(null);
  const [customerZoneAuto, setCustomerZoneAuto] = useState<ICustomerZoneAuto>();
  const [buyerId, setBuyerId] = useState('');
  const [purchaseCity, setPurchaseCity] = useState('');
  const [departurePort, setDeparturePort] = useState('');
  const [saveProcess, setSaveProcess] = useState(false);
  const [saveNotify, setSaveNotify] = useState(false);
  const _eForm = useRef<HTMLFormElement>(null);

  // Search Buyer ID based on input
  const buyerIdLoad = async id => {
    if (!(id > 0))
      return null;

    const valueResponse = (await axios.get(Config.api(`/buyer-id/search?id=${id}`))).data;
    const item = valueResponse.success ? valueResponse.data[0] : null;

    return item ? `${item.login} - ${t(`admin:auction.${item.auction}`)}` : null;
  };

  // Search auction cities based on input
  const purchaseCityLoad = async id => {
    if (!(id > 0))
      return null;

    const valueResponse = await auctionCityProvider.search({id});
    const item = valueResponse?.[0];

    return item ? `${item.city} – ${item.port}` : null;
  };

  // Get departure port
  const departurePortLoad = async id => {
    if (!(id > 0))
      return null;

    const item = await departurePortProvider.get(id);

    return item ? `${item.displayCity}${item.displayState ? `, ${item.displayState}` : ''}` : null;
  };

  useEffect(() => {
    (async () => {
      const customerZoneBalance = id > 0 ? await customerZoneBalanceProvider.get(id) : ICustomerZoneBalance.parse({});

      if (!customerZoneBalance) {
        history.push('/admin/customer-zone/balance');

        return;
      }

      const customerZoneAuto = await customerZoneAutoProvider.get(customerZoneBalance.customerZoneAutoId);
      setCustomerZoneAuto(customerZoneAuto);
      setBuyerId(await buyerIdLoad(customerZoneAuto.buyerId));
      setPurchaseCity(await purchaseCityLoad(customerZoneAuto.data.purchaseCity));
      setDeparturePort(await departurePortLoad(customerZoneAuto.data.departurePort));
      setCustomerZoneBalance(customerZoneBalance);
    })();
    // eslint-disable-next-line
  }, []);

  function reset(): void {
    //  Clear input field
    $(_eForm.current).find('[name]:not([readonly])').val('');
  }

  function onSubmit(e): void {
    if (!_eForm.current.checkValidity()) {
      return;
    }

    e.preventDefault();

    //  Set `Save Process` flag
    setSaveProcess(true);

    const eForm = $(_eForm.current);
    const formData = new FormData();
    // Delivery by land
    formData.append('delivery_by_land', eForm.find('[name="delivery-by-land"]').val());
    // Freight
    formData.append('freight', eForm.find('[name="freight"]').val());
    // Agent reward
    formData.append('agent_reward', eForm.find('[name="agent-reward"]').val());
    // Auction Storage fee
    formData.append('auction_storage_fee', eForm.find('[name="auction-storage-fee"]').val());
    // Storage fee GR8
    formData.append('storage_fee_gr8', eForm.find('[name="storage-fee-gr8"]').val());
    // Loading costs
    formData.append('loading_costs', eForm.find('[name="loading-costs"]').val());
    // Late fee
    formData.append('late_fee', eForm.find('[name="late-fee"]').val());
    // Insurance
    formData.append('insurance', eForm.find('[name="insurance"]').val());
    // Current balance
    formData.append('current_balance', eForm.find('[name="current-balance"]').val());
    // Payed amount
    formData.append('payed_amount', eForm.find('[name="payed-amount"]').val());
    // Balance
    formData.append('balance', eForm.find('[name="balance"]').val());

    //  Save form
    let url;

    if (customerZoneBalance.id > 0) {
      url = Config.api(`/customer-zone/balance/update/${customerZoneBalance.id}`);
    } else {
      url = Config.api('/customer-zone/balance/create');
    }

    axios.post(url, formData, {
      headers: {'Content-Type': 'multipart/form-data'}
    })
      .then((_response) => {
        const response: IHttpResponse = _response.data;

        if (response.code !== 200) {
          console.error(response.message);
          //  Unset `Save Process` flag
          setSaveProcess(false);

          return;
        }

        //  Mark as saved
        setSaveNotify(true);

        //  Add customer zone to state after saving
        if (!(customerZoneBalance.id > 0)) {
          history.replace(`/admin/customer-zone/balance/form/${response.data.id}`);

          //  Update customer zone state
          setCustomerZoneBalance(response.data);
        }

        //  Unset `Save Process` flag
        setSaveProcess(false);
      });
  }

  return (
    <>
      {customerZoneBalance && <div
          id="customer-zone-balance-component"
      >
        <Head
            head={t('admin:customer_zone_balance.form.head')}
            subhead={'VIN - Auto'}
            right={<div className="d-flex justify-content-lg-end justify-content-start train-buttons">
              <button
                form="customer-zone-form-component"
                className="btn btn-save"
                disabled={saveProcess}
              >
                {t('buttons.save')}
              </button>
              <button
                type="button"
                className="btn btn-reload"
                onClick={() => reset()}
              >
                {t('buttons.reset')}
              </button>
              <Link
                to={'/admin/customer-zone/balance'}
                className="btn btn-close"
              >
                {t('buttons.close')}
              </Link>
            </div>}
            hr={{style: {marginTop: 20}}}
        />

        <Accordion defaultExpanded={true}>
          <AccordionDetails className="pt-0">
            <div className="row d-flex flex-grow-1">
              <div className="col-12 col-sm-6 col-md-6 col-lg-4 col-xl-3">
                {/*Auto*/}
                <Input
                    label={t('admin:customer_zone_balance.auto')}
                    input={{
                      defaultValue:
                        `${customerZoneAuto.data.year} ${customerZoneAuto.data.maker} ${customerZoneAuto.data.model}`,
                      readOnly: true
                    }}
                />
              </div>
              <div className="col-12 col-sm-6 col-md-6 col-lg-4 col-xl-3">
                {/*Buyer ID*/}
                <Input
                    label={t('admin:customer_zone_balance.buyer_id')}
                    input={{
                      defaultValue: buyerId,
                      readOnly: true
                    }}
                />
              </div>
              <div className="col-12 col-sm-6 col-md-6 col-lg-4 col-xl-3">
                {/*Lot*/}
                <Input
                    label={t('admin:customer_zone_balance.lot')}
                    input={{
                      defaultValue: customerZoneAuto.data.lot,
                      readOnly: true
                    }}
                />
              </div>
              <div className="col-12 col-sm-6 col-md-6 col-lg-4 col-xl-3">
                {/*VIN*/}
                <Input
                    label={t('admin:customer_zone_balance.vin')}
                    input={{
                      defaultValue: customerZoneAuto.data.vin,
                      readOnly: true
                    }}
                />
              </div>
              <div className="col-12 col-sm-6 col-md-6 col-lg-4 col-xl-3">
                {/*Auto purchase date*/}
                <Input
                    label={t('admin:customer_zone_balance.auto_purchase_date')}
                    input={{
                      defaultValue: customerZoneAuto.data.autoPurchaseDate?.format(Config.DATE_FORMAT),
                      readOnly: true
                    }}
                />
              </div>
              <div className="col-12 col-sm-6 col-md-6 col-lg-4 col-xl-3">
                {/*Date of Loading*/}
                <Input
                    label={t('admin:customer_zone_balance.date_of_loading')}
                    input={{
                      defaultValue: customerZoneAuto.data.dateOfLoading?.format(Config.DATE_FORMAT),
                      readOnly: true
                    }}
                />
              </div>
              <div className="col-12 col-sm-6 col-md-6 col-lg-4 col-xl-3">
                {/*Auction*/}
                <Input
                    label={t('admin:customer_zone_balance.auction')}
                    input={{
                      defaultValue:
                        auctionOptions.find(item => item.value === customerZoneAuto.data.auction)?.label,
                      readOnly: true
                    }}
                />
              </div>
              <div className="col-12 col-sm-6 col-md-6 col-lg-4 col-xl-3">
                {/*Order type*/}
                <Input
                    label={t('admin:customer_zone_balance.order_type')}
                    input={{
                      defaultValue:
                        orderTypeOptions.find(item => item.value === customerZoneAuto.data.orderType)?.label,
                      readOnly: true
                    }}
                />
              </div>
              <div className="col-12 col-sm-6 col-md-6 col-lg-4 col-xl-3">
                {/*Purchase city*/}
                <Input
                    label={t('admin:customer_zone_balance.purchase_city')}
                    input={{
                      defaultValue: purchaseCity,
                      readOnly: true
                    }}
                />
              </div>
              <div className="col-12 col-sm-6 col-md-6 col-lg-4 col-xl-3">
                {/*Point of loading*/}
                <Input
                    label={t('admin:customer_zone_balance.departure_port')}
                    input={{
                      defaultValue: departurePort,
                      readOnly: true
                    }}
                />
              </div>
              <div className="col-12 col-sm-6 col-md-6 col-lg-4 col-xl-3">
                {/*Container number*/}
                <Input
                    label={t('admin:customer_zone_balance.container_number')}
                    input={{
                      defaultValue: customerZoneAuto.data.containerNumber,
                      readOnly: true
                    }}
                />
              </div>
              <div className="col-12 col-sm-6 col-md-6 col-lg-4 col-xl-3">
                {/*Notes*/}
                <Textarea
                    label={t('admin:customer_zone_balance.notes')}
                    textarea={{
                      defaultValue: customerZoneAuto.data.notes,
                      readOnly: true,
                      rowsMin: 3,
                      rowsMax: 3
                    }}
                />
              </div>
            </div>
          </AccordionDetails>
        </Accordion>

        <form
            ref={_eForm}
            id="customer-zone-form-component"
            onSubmit={onSubmit}
        >
          <Accordion defaultExpanded={true}>
            <AccordionSummary>
              {t('admin:customer_zone_balance.section_calculation')}
            </AccordionSummary>
            <AccordionDetails>
              <div className="row d-flex flex-grow-1">
                <div className="col-12 col-sm-6 col-md-6 col-lg-4 col-xl-3">
                  {/*Auto price*/}
                  <Input
                      label={t('admin:customer_zone_balance.auto_price')}
                      input={{
                        defaultValue: customerZoneAuto.data.autoPrice,
                        readOnly: true
                      }}
                  />
                </div>
                <div className="col-12 col-sm-6 col-md-6 col-lg-4 col-xl-3">
                  {/*Delivery by land*/}
                  <Input
                      label={t('admin:customer_zone_balance.delivery_by_land')}
                      input={{
                        type: 'number',
                        name: 'delivery-by-land',
                        defaultValue: customerZoneBalance.data.deliveryByLand,
                      }}
                  />
                </div>
                <div className="col-12 col-sm-6 col-md-6 col-lg-4 col-xl-3">
                  {/*Freight*/}
                  <Input
                      label={t('admin:customer_zone_balance.freight')}
                      input={{
                        type: 'number',
                        name: 'freight',
                        defaultValue: customerZoneBalance.data.freight,
                      }}
                  />
                </div>
                <div className="col-12 col-sm-6 col-md-6 col-lg-4 col-xl-3">
                  {/*Agent reward*/}
                  <Input
                      label={t('admin:customer_zone_balance.agent_reward')}
                      input={{
                        type: 'number',
                        name: 'agent-reward',
                        defaultValue: customerZoneBalance.data.agentReward,
                      }}
                  />
                </div>
                <div className="col-12 col-sm-6 col-md-6 col-lg-4 col-xl-3">
                  {/*Auction Storage fee*/}
                  <Input
                      label={t('admin:customer_zone_balance.auction_storage_fee')}
                      input={{
                        type: 'number',
                        name: 'auction-storage-fee',
                        defaultValue: customerZoneBalance.data.auctionStorageFee,
                      }}
                  />
                </div>
                <div className="col-12 col-sm-6 col-md-6 col-lg-4 col-xl-3">
                  {/*Storage Auction fee GR8*/}
                  <Input
                      label={t('admin:customer_zone_balance.storage_fee_gr8')}
                      input={{
                        type: 'number',
                        name: 'storage-fee-gr8',
                        defaultValue: customerZoneBalance.data.storageFeeGr8,
                      }}
                  />
                </div>
                <div className="col-12 col-sm-6 col-md-6 col-lg-4 col-xl-3">
                  {/*Loading costs*/}
                  <Input
                      label={t('admin:customer_zone_balance.loading_costs')}
                      input={{
                        type: 'number',
                        name: 'loading-costs',
                        defaultValue: customerZoneBalance.data.loadingCosts,
                      }}
                  />
                </div>
                <div className="col-12 col-sm-6 col-md-6 col-lg-4 col-xl-3">
                  {/*Late fee*/}
                  <Input
                      label={t('admin:customer_zone_balance.late_fee')}
                      input={{
                        type: 'number',
                        name: 'late-fee',
                        defaultValue: customerZoneBalance.data.lateFee,
                      }}
                  />
                </div>
                <div className="col-12 col-sm-6 col-md-6 col-lg-4 col-xl-3">
                  {/*Insurance*/}
                  <Input
                      label={t('admin:customer_zone_balance.insurance')}
                      input={{
                        type: 'number',
                        name: 'insurance',
                        defaultValue: customerZoneBalance.data.insurance,
                      }}
                  />
                </div>
                <div className="col-12 col-sm-6 col-md-6 col-lg-4 col-xl-3">
                  {/*Current balance*/}
                  <Input
                      label={t('admin:customer_zone_balance.current_balance')}
                      input={{
                        type: 'number',
                        name: 'current-balance',
                        defaultValue: customerZoneBalance.data.currentBalance,
                      }}
                  />
                </div>
                <div className="col-12 col-sm-6 col-md-6 col-lg-4 col-xl-3">
                  {/*Payed amount*/}
                  <Input
                      label={t('admin:customer_zone_balance.payed_amount')}
                      input={{
                        type: 'number',
                        name: 'payed-amount',
                        defaultValue: customerZoneBalance.data.payedAmount,
                      }}
                  />
                </div>
                <div className="col-12 col-sm-6 col-md-6 col-lg-4 col-xl-3">
                  {/*Balance*/}
                  <Input
                      label={t('admin:customer_zone_balance.balance')}
                      input={{
                        type: 'number',
                        name: 'balance',
                        defaultValue: customerZoneBalance.data.balance,
                      }}
                  />
                </div>
              </div>
            </AccordionDetails>
          </Accordion>
        </form>

        {/*Share*/}
        {customerZoneBalance && customerZoneBalance.id > 0 && <Share
            className="mb-3"
            share={customerZoneBalance.id}
            module={'customer-zone-balance'}
        />}

        <BottomToolbar
            right={<>
              <div className="d-flex justify-content-lg-end justify-content-start train-buttons">
                <button
                  form="customer-zone-form-component"
                  className="btn btn-save"
                  disabled={saveProcess}
                >
                  {t('buttons.save')}
                </button>
                <button
                  type="button"
                  className="btn btn-reload"
                  onClick={() => reset()}
                >
                  {t('buttons.reset')}
                </button>
                <Link
                  to={'/admin/customer-zone/balance'}
                  className="btn btn-close"
                >
                  {t('buttons.close')}
                </Link>
              </div>
              <div className="d-flex justify-content-end">
                <Notify
                  type="save-form"
                  hide={!saveNotify}
                />
              </div>
            </>}
        />
      </div>}
    </>
  );
};

export default CustomerZoneBalanceForm;
