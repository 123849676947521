import React, {useEffect, useRef, useState} from 'react';
import './Select.scss';
import Tooltip from '../Tooltip/Tooltip';
import SelectComponent from 'react-select';
import AsyncSelectComponent from 'react-select/async';
import UpDownArrowsIndicator from './Indicators/UpDownArrowsIndicator';
import {useTranslation} from 'react-i18next';

export const SelectStylesDisplayIndicatorSeparator = {
  indicatorSeparator: (provided, state) => ({
    ...provided,
    display: 'block !important'
  })
};
export const SelectStylesHideIndicatorSeparator = {
  indicatorSeparator: (provided, state) => ({
    ...provided,
    display: 'none !important'
  })
};

const scrollKey = Symbol();

// Return scrollable parents
function getScrollParents(node) {
  const nodes = [];
  let i = 0;

  while (true) {
    i++;

    if (i > 20)
      break;

    const prevScrollParent = nodes.length > 0 ? nodes[nodes.length - 1] : node;
    const scrollParent = getScrollParent(prevScrollParent);

    if (!scrollParent || scrollParent instanceof HTMLHtmlElement || scrollParent === prevScrollParent)
      break;

    nodes.push(scrollParent);
  }

  return nodes;
}

// Return scrollable parent
function getScrollParent(node) {
  if (node == null) {
    return null;
  }

  if (node.scrollHeight > node.clientHeight) {
    return node;
  } else {
    return getScrollParent(node.parentNode);
  }
}

export default function Select(props) {
  const {t} = useTranslation();
  const rootRef = useRef();
  const [menuOpen, setMenuOpen] = useState(false);
  const classNamePrefix = props.classNamePrefix ? props.classNamePrefix : 'react-select';
  const [scrollParents, setScrollParents] = useState([]);
  const [option, setOption] = useState(props.select ? props.select.defaultValue || null : null);

  // Close menu on resize
  useEffect(() => {
    function onResize() {
      setMenuOpen(false);
    }

    window.addEventListener('resize', onResize);

    return () => window.removeEventListener('resize', onResize);
  }, []);

  // Fix menu on same position
  useEffect(() => {
    if (!menuOpen)
      return;

    const eMenu = document.querySelector(`.${classNamePrefix}__menu`).parentElement;

    window.requestAnimationFrame(() => {
      const eMenuTop = eMenu.getBoundingClientRect().top;
      eMenu.style.top = `${window.scrollY + eMenuTop}px`;
      eMenu.style.position = 'absolute';
      eMenu.style.zIndex = '99999';
    });
    // eslint-disable-next-line
  }, [menuOpen]);

  // Preferred horizontal menu position, `left`, `center`, `right`(calculated by default)
  // Useful on mobile layout when `select` thinner than open menu
  // and you want to position on right side
  // *NOTE: Working only with `menuPosition="fixed"`
  useEffect(() => {
    if (!menuOpen)
      return;

    const eFilterMenu = document.querySelector(`.${classNamePrefix}__menu`);
    const eMenu = eFilterMenu.parentElement;
    const selectWidth = parseFloat(getComputedStyle(rootRef.current).width);
    const filterMenuWidth = parseFloat(getComputedStyle(eFilterMenu).width);
    const menuLeft = parseFloat(getComputedStyle(eMenu).left);
    const position = {
      left: menuLeft - filterMenuWidth + selectWidth,
      center: menuLeft - (filterMenuWidth - selectWidth) / 2,
    };
    const positionMatrix = [
      {
        position: 'center',
        available: position.center >= 0 && position.center + filterMenuWidth <= window.innerWidth
      },
      {
        position: 'left',
        available: position.left >= 0 && position.left + filterMenuWidth <= window.innerWidth
      },
      {
        position: 'right',
        available: menuLeft + filterMenuWidth <= window.innerWidth
      }
    ];

    // Use preferred position
    let availablePosition: any = positionMatrix.find(
      position => position.position === props.horizontalMenuPosition && position.available
    );
    availablePosition = availablePosition ? availablePosition.position : null;

    if (availablePosition) {
      eMenu.style.left = `${position[availablePosition]}px`;

      return;
    }

    // Search for available position
    availablePosition = positionMatrix.find(position => position.available);
    availablePosition = availablePosition ? availablePosition.position : 'center';

    eMenu.style.left = `${position[availablePosition]}px`;
    // eslint-disable-next-line
  }, [menuOpen]);

  // On Menu open
  function onMenuOpen() {
    setMenuOpen(true);
    // Set scroll listener for scrollable parent
    setScrollParents(getScrollParents(rootRef.current).map(scrollParent => {
      scrollParent[scrollKey] = () => {
        setMenuOpen(false);
        // Remove scroll listener for scrollable parent if menu was terminated
        scrollParent.removeEventListener('scroll', scrollParent[scrollKey]);
      };
      scrollParent.addEventListener('scroll', scrollParent[scrollKey]);

      return scrollParent;
    }));
  }

  // On Menu close
  function onMenuClose() {
    setMenuOpen(false);
    // Remove scroll listener for scrollable parent
    scrollParents.forEach(scrollParent => {
      scrollParent.removeEventListener('scroll', scrollParent[scrollKey]);
    });
    setScrollParents([]);
  }

  //
  function getInputValue() {
    if (props.select && props.select.value !== undefined)
      return props.select.value || '';

    if (option)
      return option.value || '';

    return '';
  }

  const selectProps = {
    loadingMessage: () => t('select_loading'),
    noOptionsMessage: () => t('select_empty'),
    placeholder: t('select_placeholder'),
    menuPosition: 'fixed',
    menuPlacement: 'auto',
    menuPortalTarget: document.body,
    onMenuOpen: onMenuOpen,
    onMenuClose: onMenuClose,
    classNamePrefix: classNamePrefix,
    menuIsOpen: menuOpen,
    ...props.select,
    components: {
      DropdownIndicator: UpDownArrowsIndicator,
      ...(props.select ? props.select.components : {})
    },
    className: 'react-select-container',
    onChange: (...args) => {
      const [option] = args;
      setOption(option);

      if (props.select && typeof props.select.onChange === 'function')
        props.select.onChange(...args);
    }
  };

  return (
    <div
      ref={rootRef}
      className={`form-field select ${props.className || ''}`}
      style={{
        marginBottom: props.bottomOffset,
        ...props.style,
      }}
    >
      <div className="field">
        <div className={'label ' + (props.hideLabel ? 'hide' : '')}>
          {
            props.beforeLabel ?
              <div className="before">
                {props.beforeLabel}
              </div>
              :
              ''
          }
          <label htmlFor={props.id}>
            {props.label}
          </label>
          {
            props.afterLabel ?
              <div className="after">
                {props.afterLabel}
              </div>
              :
              ''
          }

          {props.tooltip ?
            <Tooltip title={props.tooltip.title} />
            :
            ''
          }
        </div>
        <div className="input">
          {props.async ? <AsyncSelectComponent {...selectProps} /> : <SelectComponent {...selectProps} />}
          {!props.disabled && (
            <input
              tabIndex={-1}
              autoComplete="off"
              style={{position: 'absolute', left: 0, bottom: 0, height: 0, opacity: 0, zIndex: -1}}
              value={getInputValue()}
              required={props.required}
              onChange={() => {
              }}
            />
          )}
        </div>
      </div>
    </div>
  );
};
