import React, {useContext, useState, FC} from 'react';
import {useTranslation} from 'react-i18next';
import {Link, NavLink} from 'react-router-dom';
import './LeftMenu.scss';
import AdminContext from './../AdminContext';
import {UserPermission} from '../../Providers/UserPermission';
import IRole from '../../Interfaces/IRole';
import Config from '../../Providers/Config';

const LeftMenu: FC<{
  appStore: any,
  setMobileMenu: any,
  className?: string,
}> = (props) => {
  const {t} = useTranslation();
  const context = useContext(AdminContext);
  const [menuCollapse, setMenuCollapse] = useState(false);
  const {mobileMenu} = props.appStore;
  const {setMobileMenu} = props;

  //  Hide menu
  function hideMenu() {
    setMobileMenu(false)
  }

  function exit() {
    context.auth.clear();

    window.location.href = '/';
  }

  return (
    <div
      id="left-menu-component"
      className={`${props.className ? props.className : ''} ${menuCollapse ? 'menu-collapse' : ''} ${mobileMenu ? 'active' : ''}`}
    >
      <div className="header">
        <div className="row d-flex h-100 align-items-center flex-row-reverse flex-md-row justify-content-between justify-md-content-start">
          <div className="col-6 logo-wrap text-right text-md-left">
            <Link
              to="/admin"
              className="d-inline-block logo"
            >
              <img
                src={`/assets/images/${Config.Common.logo}`}
                alt="Logo"
              />
            </Link>
          </div>
          <div className="col-6 d-none d-md-flex justify-content-end left-menu-collapse-switcher-wrap">
            <span
              className="left-menu-collapse-switcher"
              onClick={() => setMenuCollapse(!menuCollapse)}
            />
          </div>
          <span
            className="navbar-toggler-icon active d-block d-md-none"
            onClick={() => setMobileMenu(false)}
          />
        </div>
      </div>

      <div className="menu">
        {/*Customer Zone Auto*/}
        {
          IRole.checkPermission(context.auth.user.role, [
            UserPermission.CUSTOMER_ZONE_AUTOS_FULL,
            UserPermission.CUSTOMER_ZONE_AUTOS_READONLY
          ])
            ?
            <NavLink
              to="/admin/customer-zone/auto"
              className="item item-home"
              onClick={() => {
                hideMenu()
              }}
            >
              <span className="item-icon" />
              <span className="item-text">
                {t('admin:left_menu.home')}
              </span>
            </NavLink>
            :
            ''
        }

        {/*Customer Zone Balance*/}
        {
          IRole.checkPermission(context.auth.user.role, [
            UserPermission.CUSTOMER_ZONE_BALANCES_FULL,
            UserPermission.CUSTOMER_ZONE_BALANCES_READONLY
          ])
            ?
            <NavLink
              to="/admin/customer-zone/balance"
              className="item item-finance"
              onClick={() => {
                hideMenu()
              }}
            >
              <span className="item-icon" />
              <span className="item-text">
                {t('admin:left_menu.customer_zone_balance')}
              </span>
            </NavLink>
            :
            ''
        }

        {/*Invoices*/}
        {
          IRole.checkPermission(context.auth.user.role, [
            UserPermission.INVOICES_FULL,
            UserPermission.INVOICES_READONLY
          ])
            ?
            <NavLink
              to="/admin/invoices"
              className="item item-invoices"
              onClick={() => {
                hideMenu()
              }}
            >
              <span className="item-icon" />
              <span className="item-text">
                {t('admin:left_menu.invoices')}
              </span>
            </NavLink>
            :
            ''
        }

        {/*Tracking*/}
        {
          IRole.checkPermission(context.auth.user.role, [
            UserPermission.TRACKINGS_FULL,
            UserPermission.TRACKINGS_READONLY
          ])
            ?
            <NavLink
              to="/admin/tracking"
              className="item item-tracking"
              onClick={() => {
                hideMenu()
              }}
            >
              <span className="item-icon" />
              <span className="item-text">
                {t('admin:left_menu.tracking')}
              </span>
            </NavLink>
            :
            ''
        }

        {/*Calculator*/}
        {
          IRole.checkPermission(context.auth.user.role, [
            UserPermission.CALCULATOR
          ])
            ?
            <NavLink
              to="/admin/calculator"
              className="item item-calculator"
              onClick={() => {
                hideMenu()
              }}
            >
              <span className="item-icon" />
              <span className="item-text">
                {t('admin:left_menu.calculator')}
              </span>
            </NavLink>
            :
            ''
        }

        {/*Buyer IDs*/}
        {IRole.checkPermission(context.auth.user.role, [
          UserPermission.BUYER_ID_FULL,
          UserPermission.BUYER_ID_READONLY,
        ]) && <NavLink
            to="/admin/buyer-ids"
            className="item item-buyer-ids"
            onClick={() => {
              hideMenu()
            }}
        >
          <span className="item-icon" />
          <span className="item-text">
            {t('admin:left_menu.buyer_ids')}
          </span>
        </NavLink>}

        {/*Roles*/}
        {IRole.checkPermission(context.auth.user.role, []) ?
          <NavLink
            to="/admin/roles"
            className="item item-roles"
            onClick={() => {
              hideMenu()
            }}
          >
            <span className="item-icon" />
            <span className="item-text">
              {t('admin:left_menu.roles')}
            </span>
          </NavLink>
          :
          ''
        }

        {/*Users*/}
        {
          IRole.checkPermission(context.auth.user.role, [
            UserPermission.USERS_FULL,
            UserPermission.USERS_READONLY
          ])
            ?
            <NavLink
              to="/admin/users"
              className="item item-users"
              onClick={() => {
                hideMenu()
              }}
            >
              <span className="item-icon" />
              <span className="item-text">
                {t('admin:left_menu.users')}
              </span>
            </NavLink>
            :
            ''
        }

        {/*Promotions*/}
        {IRole.checkPermission(context.auth.user.role, [
          UserPermission.PROMOTIONS
        ], false) ?
          <NavLink
            to="/admin/promotions"
            className="item item-promotions"
            onClick={() => {
              hideMenu()
            }}
          >
            <span className="item-icon" />
            <span className="item-text">
              {t('admin:left_menu.promotions')}
            </span>
          </NavLink>
          :
          ''
        }

        {/*Payment Data*/}
        {IRole.checkPermission(context.auth.user.role, [
          UserPermission.PAYMENT_DATA
        ], false) ?
          <NavLink
            to="/admin/payment-data"
            className="item item-payment-data"
            onClick={() => {
              hideMenu()
            }}
          >
            <span className="item-icon" />
            <span className="item-text">
              {t('admin:left_menu.payment_data')}
            </span>
          </NavLink>
          :
          ''
        }

        {/*Referral program*/}
        {
          IRole.checkPermission(context.auth.user.role, [
            UserPermission.REFERRAL_PROGRAM_FULL,
            UserPermission.REFERRAL_PROGRAM_READONLY
          ])
            ?
            <NavLink
              to="/admin/referral-program"
              className="item item-referral-program"
              onClick={() => {
                hideMenu()
              }}
            >
              <span className="item-icon" />
              <span className="item-text">
                {t('admin:left_menu.referral_program')}
              </span>
            </NavLink>
            :
            ''
        }

        {/*Personal Data*/}
        <NavLink
          to="/admin/personal-data"
          className="item item-personal-data"
          onClick={() => {
            hideMenu()
          }}
        >
          <span className="item-icon" />
          <span className="item-text">
            {t('admin:left_menu.personal_data')}
          </span>
        </NavLink>

        {/*Exit*/}
        <div
          onClick={() => {
            hideMenu();
            exit();
          }}
          className="item item-exit mobile"
        >
          <span className="item-icon" />
          <span className="item-text">
            {t('admin:header.exit')}
          </span>
        </div>
      </div>
    </div>
  );
};

export default LeftMenu;
