import React, {cloneElement, FC, useEffect, useMemo, useRef, useState} from 'react';
import {Link} from 'react-router-dom';
import {Menu, MenuItem} from '@material-ui/core';
import {useTranslation} from 'react-i18next';

interface IMenuItemProps {
  // Required for internal usage. RowActionMenu must pass actual row index
  rowIndex?: number;
  link?: (row: any) => any;
  href?: (row: any) => any;
  menuItem?: any;
}

// Edit row menu item action
export const RowActionMenuItemEdit: FC<IMenuItemProps> = (props) => {
  const {t} = useTranslation();
  const linkProps = props.link?.(props.rowIndex);

  const menuItem = <MenuItem
    {...props.menuItem}
    className={`row-action-menu-item-edit ${props.menuItem?.className ?? ''}`}
  >

    <img
      src={'/assets/images/icon-pencil.png'}
      alt="Edit row"
    />
    <span>
      {t('table.action.edit')}
    </span>
  </MenuItem>;

  return (<>
    {props.link ? <Link
      {...linkProps}
      className={`row-action-menu-item-link ${linkProps?.className ?? ''}`}
    >
      {menuItem}
    </Link> : menuItem}
  </>);
};

// Edit row menu item action
export const RowActionMenuItemView: FC<IMenuItemProps> = (props) => {
  const {t} = useTranslation();
  const linkProps = props.link?.(props.rowIndex);
  const hrefProps = props.href?.(props.rowIndex);

  const menuItem = <MenuItem
    {...props.menuItem}
    className={`row-action-menu-item-view ${props.menuItem?.className ?? ''}`}
  >
    <img
      src={'/assets/images/icon-eye.png'}
      alt="View row"
    />
    <span>
      {t('table.action.view')}
    </span>
  </MenuItem>;

  // Link
  if (props.link)
    return <Link
      {...linkProps}
      className={`row-action-menu-item-link ${linkProps?.className ?? ''}`}
    >
      {menuItem}
    </Link>;

  // A tag
  if (props.href)
    return <a
      {...hrefProps}
      className={`row-action-menu-item-link ${linkProps?.className ?? ''}`}
    >
      {menuItem}
    </a>;

  return (<>
    {menuItem}
  </>);
};

// Remove row menu item action
export const RowActionMenuItemRemove: FC<IMenuItemProps> = (props) => {
  const {t} = useTranslation();
  const linkProps = props.link?.(props.rowIndex);

  const menuItem = <MenuItem
    {...props.menuItem}
    className={`row-action-menu-item-remove ${props.menuItem?.className ?? ''}`}
  >
    <img
      src={'/assets/images/icon-trash-red.png'}
      alt="Remove row"
    />
    <span>
      {t('table.action.remove')}
    </span>
  </MenuItem>;

  return (<>
    {props.link ? <Link
      {...linkProps}
      className={`row-action-menu-item-link ${linkProps?.className ?? ''}`}
    >
      {menuItem}
    </Link> : menuItem}
  </>);
};

// Action context menu component
const RowActionMenu: FC<{
  // Rpw index
  rowIndex: number,
  // Pass original to force rerender
  row: any,
  menuItems?: JSX.Element[]
}> = (props) => {
  const [_eAnchor, set_eAnchor] = useState<null | HTMLElement>();
  const [menuItems, setMenuItems] = useState<JSX.Element[]>([]);
  const _eAnchorRef = useRef<HTMLImageElement>();

  useEffect(() => {
    const menuItems = props.menuItems ?? [];

    setMenuItems(menuItems.map((menuItem, i) => cloneElement(menuItem, {
        key: i,
        rowIndex: props.rowIndex,
        menuItem: {
          onClick: e => {
            handleClose();

            if (typeof menuItem.props?.menuItem?.onClick === 'function')
              menuItem.props?.menuItem?.onClick(e, props.rowIndex);
          }
        }
      })
    ))
    // eslint-disable-next-line
  }, [props.row, props.menuItems]);

  const handleClose = () => {
    set_eAnchor(null);
  }

  return (
    <div className="table-row-action-menu-component">
      <div
        className="open-action-menu"
        onClick={() => set_eAnchor(_eAnchorRef.current)}
      >
        <img
          ref={_eAnchorRef}
          className="open-action-menu-button"
          src={'/assets/images/icon-gear.png'}
          alt="Action menu"
        />
      </div>
      {menuItems.length > 0 && <Menu
          className="table-component-menu row-action-menu"
          anchorEl={_eAnchor}
          getContentAnchorEl={null}
          anchorOrigin={{
            horizontal: 'right',
            vertical: 'bottom'
          }}
          transformOrigin={{
            horizontal: 'left',
            vertical: 'top'
          }}
          keepMounted
          open={Boolean(_eAnchor)}
          onClose={handleClose}
      >
        {menuItems.map((menuItem, i) => <div key={i}>
          {menuItem}
        </div>)}
      </Menu>}
    </div>
  );
};

// Add row action context menu
const useRowActions = (props?: {
  menuItems?: any[]
}) => {
  return hooks => {
    hooks.allColumns.push(columns => [
      {
        id: 'row-action',
        className: 'col-action',
        Header: () => null,
        Cell: ({row}) => {
          return useMemo(() => <RowActionMenu
              rowIndex={row.index}
              row={row.original}
              {...props}
            />,
            // eslint-disable-next-line
            [row.original]);
        },
      },
      ...columns,
    ])
  };
};

export default useRowActions;
