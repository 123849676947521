import ICustomerZoneAuto from '../Interfaces/ICustomerZoneAuto';
import axios from 'axios';
import Config from './Config';

export default class CustomerZoneAuto {

  // Load list
  async list(data: any = {}): Promise<{
    data: ICustomerZoneAuto[],
    count: number,
    addons: any
  }|false> {
    const response = (await axios.get(Config.api(`/customer-zone/auto/list`), {params: data})).data;

    if (!response.success) {
      console.error(response.message);

      return false;
    }

    //  Skip empty
    if (!response.data) {
      return;
    }

    const responseData = response.data;

    return {
      data: responseData.data.map(item => ICustomerZoneAuto.parse(item)),
      count: responseData.count,
      addons: responseData.addons
    };
  }

  // Remove by ID
  async delete(id: number|number[]): Promise<boolean> {
    const response = (await axios.post(Config.api('/customer-zone/auto/delete'), {id})).data;

    if (!response.success) {
      console.error(response.message);

      return false;
    }

    return true;
  }

  // Get by ID
  async get(id): Promise<ICustomerZoneAuto> {
    const response = (await axios.get(Config.api(`/customer-zone/auto/get/${id}`))).data;

    if (!response.success) {
      console.error(response.message);

      return null;
    }

    //  Skip empty
    if (!response.data)
      return null;

    return ICustomerZoneAuto.parse(response.data);
  }

}
