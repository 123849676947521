import React, {FC, useEffect, useRef, useState} from 'react';
import {useTranslation} from 'react-i18next';
import {Link} from 'react-router-dom';
import './PaymentData.scss';
import Input from '../../Core/Form/Input/Input';
import Notify from '../../Core/Ui/Notify/Notify';
import axios from 'axios';
import Config from '../../Providers/Config';
import {IHttpResponse} from '../../Interfaces/IHttpResponse';
import Errors from '../../Core/Form/Errors/Errors';
import * as EgoUtil from '@egofoxlab/util';
//import Cleave from 'cleave.js/dist/cleave.min';
import Cleave from 'cleave.js/dist/cleave-react';
import Head from '../Head/Head';
import BottomToolbar from '../BottomToolbar/BottomToolbar';

const PaymentData: FC = () => {
  const {t} = useTranslation();
  const [cardName, setCardName] = useState('');
  const [cardNumber, setCardNumber] = useState('');
  const [saveNotify, setSaveNotify] = useState(false);
  const [errors, setErrors] = useState(null);
  const _eRoot = useRef<HTMLFormElement>();

  useEffect(() => {
    axios.get(Config.api('/payment-data/get'))
      .then((_response) => {
        const response = _response.data;

        if (response.code !== 200) {
          console.error(response.message);

          return;
        }

        setCardName(response.data.card_name);
        setCardNumber(response.data.card_number);
      });
  }, []);

  function onSubmit(e) {
    if (!e.target.checkValidity())
      return;

    e.preventDefault();

    axios.post(Config.api('/payment-data/save'), {
      cardName: cardName,
      cardNumber: cardNumber
    })
      .then((_response) => {
        const response: IHttpResponse = _response.data;

        if (response.code !== 200) {
          console.error(response.message);
          setErrors(EgoUtil.getArrItem(response.data, 'error'));

          return;
        }

        setSaveNotify(true);
      });
  }

  return (
    <form
      ref={_eRoot}
      id="payment-data-component"
      onSubmit={onSubmit}
    >
      <Head head={t('admin:payment_data.head')} />

      <div>
        <div className="form-title-light mobile-text">
          {t('admin:payment_data.card_title')}
        </div>

        <div className="row">
          {/*Card Name*/}
          <div className="col-12 col-md-6">
            <Input
              label={t('admin:payment_data.card_name')}
              input={{
                name: 'card-name',
                id: 'card-name',
                value: cardName,
                required: true,
                inputProps: {
                  pattern: '[A-Za-z\\D]*?\\s[A-Za-z\\D]*',
                },
                onChange: (e) => setCardName(e.target.value)
              }}
            />
          </div>
          <div className="col-md-6" />
          {/*Card Number*/}
          <div className="col-12 col-md-6">
            <Input
              label={t('admin:payment_data.card_number')}
              components={{
                input: <Cleave
                  options={{creditCard: true}}
                  value={cardNumber}
                  onChange={e => setCardNumber(e.target.value)}
                />
              }}
            />
          </div>
        </div>
      </div>

      <div
        className="mobile-h"
        style={{height: 330}}
      />

      {/*Errors*/}
      <div>
        <Errors
          errors={errors}
          style={{marginTop: 15, marginBottom: 0}}
        />
      </div>

      <BottomToolbar
        left={<>
          <Link
            to="/admin"
            className="btn th-cancel mb-3 mb-lg-0"
          >
            {t('admin:payment_data.go_home')}
          </Link>
        </>}
        right={<>
          <div className="d-flex justify-content-lg-end justify-content-start">
            <button className="btn btn-save">
              {t('buttons.save')}
            </button>
          </div>
          <div className="d-flex justify-content-end">
            <Notify
              type="save-form"
              hide={!saveNotify}
            />
          </div>
        </>}
      />
    </form>
  );
}

export default PaymentData;
