import React, {FC} from 'react';
import './Head.scss';

const Head: FC<{
  head: string;
  beforeHead?: any;
  afterHead?: any;
  subhead?: string;
  beforeSubhead?: any;
  afterSubhead?: any;
  left?: any
  right?: any
  hr?: any;
}> = (props) => {
  return (
    <div
      id="admin-head-component"
      className="row admin-head"
    >
      {props.left ? <div className="col-6">{props.left}</div> : ''}
      <div className={`${props.left || props.right ? 'col-12 col-md-12 col-lg-6' : 'col-12'}`}>
        {/*Head*/}
        <h1 className="admin-head-head">
          {props.beforeHead ? props.beforeHead : ''}
          {props.head}
          {props.afterHead ? props.afterHead : ''}
        </h1>
        {props.subhead ? <h3 className="admin-head-subhead mb-3">
          {props.beforeSubhead ? props.beforeSubhead : ''}
          {props.subhead}
          {props.afterSubhead ? props.afterSubhead : ''}
        </h3> : ''}
      </div>
      {props.right ? <div className="col-12 col-md-12 col-lg-6">{props.right}</div> : ''}

      {props.children ? <div className="col-12 admin-head-content">{props.children}</div> : ''}

      <div className="col-12">
        <hr {...props.hr} />
      </div>
    </div>
  );
};

export default Head;
