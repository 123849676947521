import React, {FC, useEffect, useRef, useState} from 'react';
import {useTranslation} from 'react-i18next';
import './Promotions.scss';
import axios from 'axios';
import Config from '../../../Providers/Config';
import {IHttpResponse} from '../../../Interfaces/IHttpResponse';
import {FacebookShareButton} from 'react-share';

const Promotions: FC = () => {
  const {t} = useTranslation();
  const [code, setCode] = useState('');
  const [copyText, setCopyText] = useState('');
  const [copyLink, setCopyLink] = useState('');
  const [selected, setSelected] = useState(false);
  const _eCopy = useRef<HTMLInputElement>();
  const _eCode = useRef<HTMLSpanElement>();
  const _eCodeMobile = useRef<HTMLSpanElement>();

  useEffect(() => {
    axios.get(Config.api('/promotions-data/promotions/get'))
      .then((_response) => {
        const response: IHttpResponse = _response.data;

        if (response.code !== 200) {
          console.error(response.message);

          return;
        }

        const code = response.data.referral_code;
        let copyText = t('admin:promotions_data.promotions.copy');
        copyText = copyText.replace(/:code/g, code);
        let copyLink = t('admin:promotions_data.promotions.copy_link');
        copyLink = copyLink.replace(/:code/g, code);

        setCode(code);
        setCopyText(copyText);
        setCopyLink(copyLink);
      });
    // eslint-disable-next-line
  }, []);

  function copy(): void {
    _eCopy.current.select();
    document.execCommand('copy');

    setSelected(true);

    window.requestAnimationFrame(() => {
      _eCode.current.style.backgroundColor = '#9ec6ff';
      _eCode.current.style.transition = 'all ease-in 0ms';
      _eCodeMobile.current.style.backgroundColor = '#9ec6ff';
      _eCodeMobile.current.style.transition = 'all ease-in 0ms';

      window.requestAnimationFrame(() => {
        _eCode.current.style.backgroundColor = '#fff';
        _eCode.current.style.transition = 'all ease-in 500ms';
        _eCodeMobile.current.style.backgroundColor = '#f5f6f8';
        _eCodeMobile.current.style.transition = 'all ease-in 500ms';
      });
    });
  }

  return (
    <>
      {
        code ?
          <form id="promotions-component">
            <div
              className="mobile img"
              style={{
                background: 'center  / contain no-repeat url(/assets/images/referral-1.svg)',
                height: '140px'
              }}
            />
            <div className="header title ">
              {t('admin:promotions_data.promotions.header')}
            </div>
            <div className="mobile input">
              <div className="field">
                <div className="input">
                  <div
                    className={'code'}
                    title={t('admin:promotions_data.promotions.tip')}
                    onClick={() => copy()}
                  >
                    <span
                      ref={_eCodeMobile}
                      className={(selected ? 'selected' : '')}
                    >
                      {code}
                    </span>
                  </div>
                </div>
              </div>
            </div>

            <div className="description">
              {t('admin:promotions_data.promotions.description_1')}
              <br />
              <div className="mobile-br">
                {t('admin:promotions_data.promotions.description_2')}
              </div>
            </div>
            <div className="text-center socials mobile ">
              {/*FB*/}
              <FacebookShareButton
                className="item"
                url={copyLink}
              >
                <img
                  src={'/assets/images/icon-facebook.svg'}
                  alt="icon-facebook"
                  height={45}
                />
              </FacebookShareButton>
              {/*Telegram*/}
              <a
                className="tgme_action_button item"
                href={`tg://msg_url?url=${copyText}`}
              >
                <img
                  src={'/assets/images/icon-telegram.svg'}
                  alt="icon-telegram"
                  height={45}
                />
              </a>
              {/*Whatsapp*/}
              <a
                className="item"
                href={`whatsapp://send?text=${copyText}`}
                data-action="share/whatsapp/share"
              >
                <img
                  src={'/assets/images/icon-whatsapp.svg'}
                  alt="icon-whatsapp"
                  height={45}
                />
              </a>
              {/*Viber*/}
              <a
                className="item"
                href={`viber://forward?text=${copyText}`}
                data-action="share/whatsapp/share"
              >
                <img
                  src={'/assets/images/icon-viber.svg'}
                  alt="icon-viber"
                  height={45}
                />
              </a>
            </div>

            <br />
            <hr className="mobile none" />
            <br />

            <div className="content">
              <div className="row">
                <div className="col-12 col-md-7 ">
                  {/*Field with code*/}
                  <div className="field mobile none">
                    <div className="input">
                      <div className="text-center title">
                        {t('admin:promotions_data.promotions.your_code')}
                      </div>

                      <div
                        className={'code'}
                        title={t('admin:promotions_data.promotions.tip')}
                      >
                        <span
                          ref={_eCode}
                          className={(selected ? 'selected' : '')}
                        >
                          {code}
                        </span>
                      </div>

                      {/*Socials*/}
                      <div className="text-center socials">
                        {/*FB*/}
                        <FacebookShareButton url={copyLink}>
                          <img
                            src={'/assets/images/icon-facebook.svg'}
                            alt="icon-facebook"
                            height={25}
                          />
                        </FacebookShareButton>
                        {/*Telegram*/}
                        <a
                          className="tgme_action_button"
                          href={`tg://msg_url?url=${copyText}`}
                        >
                          <img
                            src={'/assets/images/icon-telegram.svg'}
                            alt="icon-telegram"
                            height={25}
                          />
                        </a>
                        {/*Whatsapp*/}
                        <a
                          href={`whatsapp://send?text=${copyText}`}
                          data-action="share/whatsapp/share"
                        >
                          <img
                            src={'/assets/images/icon-whatsapp.svg'}
                            alt="icon-whatsapp"
                            height={25}
                          />
                        </a>
                        {/*Viber*/}
                        <a
                          href={`viber://forward?text=${copyText}`}
                          data-action="share/whatsapp/share"
                        >
                          <img
                            src={'/assets/images/icon-viber.svg'}
                            alt="icon-viber"
                            height={25}
                          />
                        </a>
                      </div>
                    </div>

                    <button
                      className="mobile none"
                      type="button"
                      title={t('admin:promotions_data.promotions.tip')}
                      onClick={() => copy()}
                    >
                      <img
                        src={'/assets/images/icon-copy-light.png'}
                        alt="icon-copy-light"
                        height={29}
                      />
                    </button>

                    {/*Funnel*/}
                    <div className="funnel mobile none">
                      <img
                        src={'/assets/images/arrow-share.png'}
                        alt="arrow-share"
                        height={42}
                      />
                      <span>
                        {t('admin:promotions_data.promotions.text_funnel')}
                      </span>
                    </div>
                  </div>
                </div>
                <div className="col-12 col-md-5 text-right  none">
                  <div
                    style={{
                      background: 'center right / contain no-repeat url(/assets/images/referral-1.svg)',
                      height: '330px'
                    }}
                  />
                </div>
              </div>
            </div>

            <input
              ref={_eCopy}
              type="text"
              defaultValue={copyText}
              style={{position: 'absolute', top: 0, left: -99999999}}
            />
          </form>
          :
          ''
      }
    </>
  );
};

export default Promotions;
