import React, {FC} from 'react';
import {useTranslation} from 'react-i18next';
import './PromotionsData.scss';
import Promotions from './Promotions/Promotions';
import ReferralsHistory from './ReferralsHistory/ReferralsHistory';
import Head from '../Head/Head';

const PromotionsData: FC = () => {
  const {t} = useTranslation();

  return (
    <div id="promotions-data-component">
      <Head head={t('admin:promotions_data.head')} />

      <ul
        className="nav nav-tabs"
        role="tablist"
      >
        <li className="nav-item">
          <a
            className="nav-link active"
            id="tab-promotions"
            data-toggle="tab"
            href="#tab-content-promotions"
            role="tab"
            aria-controls="tab-promotions"
            aria-selected="true"
          >
            <span>
              {t('admin:promotions_data.tab_promotions')}
            </span>
          </a>
        </li>
        <li className="nav-item">
          <a
            className="nav-link"
            id="tab-profile"
            data-toggle="tab"
            href="#tab-content-referrals-history"
            role="tab"
            aria-controls="profile"
            aria-selected="false"
          >
            <span>
              {t('admin:promotions_data.tab_referrals_history')}
            </span>
          </a>
        </li>
      </ul>

      <div className="tab-content">
        <div
          className="tab-pane fade show active"
          id="tab-content-promotions"
          role="tabpanel"
          aria-labelledby="tab-promotions"
        >
          <Promotions />
        </div>
        <div
          className="tab-pane fade"
          id="tab-content-referrals-history"
          role="tabpanel"
          aria-labelledby="tab-referrals-history"
        >
          <ReferralsHistory />
        </div>

      </div>
    </div>
  );
};

export default PromotionsData;
