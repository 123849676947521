import React, {FC} from 'react';
import './DevAlert.scss';

const DevAlert: FC = () => {
  return (<div id="dev-alert-component">
    <span role="img" aria-label="warning">⚠️</span>
    ️Warning! You use the development app version.
    <span role="img" aria-label="warning">⚠️</span>
  </div>);
};

export default DevAlert;