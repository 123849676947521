import React, {FC, useEffect, useState} from 'react';
import './App.scss';
import {BrowserRouter as Router} from 'react-router-dom';
import Navigator from './Navigator';
import axios from 'axios';
import Config from './Providers/Config';
import i18next from 'i18next';

const App: FC = () => {
  const [config, setConfig] = useState(false);

  useEffect(() => {
    /**
     * Load modules
     *
     * @returns {Promise<void>}
     */
    async function loadModules( ) {
      return Promise.all([
        //  i18next
        new Promise(async (resolve) => {
          await import('./i18n');

          i18next.on('initialized', async () => {
            const lang = i18next.language.substr(0, 2);
            await import(`moment/locale/${lang}`);

            resolve();
          });
        })
      ]);
    }

    axios.get(`/assets/config/config.json?v=${(new Date()).getTime()}`).then(({data: response}) => {
      // Set config data
      Config.config(response);
      //  Load modules
      loadModules().then(() => {
        setConfig(true)
      });
    });
  }, []);

  const ready = ![config].includes(false);

  return (
    <Router>
      {ready && <Navigator />}
    </Router>
  );
}

export default App;

