import React, {FC} from 'react';
import './HeadNotify.scss';

const HeadNotify: FC<{
  className?: string;
  style?: any;
  messages?: {
    title?: string,
    text: string
  }[];
  hide?: boolean;
}> = (props) => {
  return (
    <>
      {props.messages && props.messages.length > 0 && !props.hide ? <div
        className={`head-notify-component ${props.className ?? ''}`}
        style={props.style}
      >
        {props.messages && props.messages.length > 0 ?
          props.messages.map((item, i) => <div
              key={i}
              className="notify"
            >
              <img
                src={'/assets/images/icon-information.svg'}
                alt="icon-information"
                width={16}
              />
              <span className={'title'}>
                {item.title}
              </span>
              <span className={'text'}>
                {item.text}
              </span>
            </div>
          ) : ''}
      </div> : ''}
    </>
  );
}

export default HeadNotify;
