import moment, {Moment} from 'moment';
import ICustomerZoneAuto from './ICustomerZoneAuto';
import Config from '../Providers/Config';

export default class IInvoice {

  id: number;
  customerZoneAutoId: number;
  status: string;
  employee: number;
  data: {
    amount: string,
    city: string,
    country: string,
    flat: string,
    house: string,
    invoiceDate: Moment,
    invoiceNumber: string,
    productNumber: string,
    clientNumber: string,
    firstName: string,
    serviceType: string,
    serviceName: string,
    street: string,
    stuffType: string,
    lastName: string,
    serialNumber: string,
    product: string,
    shortName: string
  };
  promoCode: string;
  rewardAmount: number;
  promoCodeActive: boolean;
  promoCodeActiveDate: string;
  invoiceSent: boolean;
  invoiceSentDate: string;
  invoicePaid: boolean;
  invoicePaidDate: string;
  fundsCredited: boolean;
  fundsCreditedDate: string;
  customerZoneAuto?: ICustomerZoneAuto;
  createdAt: Moment;
  updatedAt: Moment;

  public static parse(data: any): IInvoice {
    if (!data) {
      return null;
    }

    const invoice = new IInvoice();

    invoice.id = data.id ? parseInt(data.id, 10) : null;
    invoice.customerZoneAutoId = data.customer_zone_auto_id ? data.customer_zone_auto_id : null;
    invoice.status = data.status ? data.status : null;
    invoice.employee = data.employee ? data.employee : null;
    invoice.data = {
      amount: data.data ? data.data.amount : null,
      city: data.data ? data.data.city : null,
      country: data.data ? data.data.country : null,
      flat: data.data ? data.data.flat : null,
      house: data.data ? data.data.house : null,
      invoiceDate: moment(data.data?.['invoice_date'], Config.DATE_FORMAT).isValid() ?
        moment(data.data?.['invoice_date'], Config.DATE_FORMAT) : null,
      invoiceNumber: data.data ? data.data['invoice_number'] : null,
      productNumber: data.data ? data.data['product_number'] : null,
      clientNumber: data.data ? data.data['client_number'] : null,
      firstName: data.data ? data.data['first_name'] : null,
      serviceType: data.data ? data.data['service_type'] : null,
      serviceName: null,
      street: data.data ? data.data.street : null,
      stuffType: data.data ? data.data['stuff_type'] : null,
      lastName: data.data ? data.data['last_name'] : null,
      serialNumber: data.data ? data.data['serial_number'] : null,
      product: data.data ? data.data['product'] : null,
      shortName: data.data ? data.data['last_name'] : null
    };

    //  Short name
    if (invoice.data.firstName) {
      invoice.data.shortName += ' ' + invoice.data.firstName.substr(0, 1).toUpperCase() + '.';
    }

    //  Service name
    invoice.data.serviceName = {
      'g&g': 'G&G',
      'iaai': 'IAAI',
      'copart': 'Copart',
      'gr8-auto': 'GR8 Auto',
    }[invoice.data.serviceType];

    invoice.promoCode = data.promo_code ? data.promo_code : null;
    invoice.rewardAmount = data.reward_amount ? parseFloat(data.reward_amount) : null;
    invoice.promoCodeActive = data.promo_code_active === true;
    invoice.promoCodeActiveDate = data.promo_code_active_date ? data.promo_code_active_date : null;
    invoice.invoiceSent = data.invoice_sent === true;
    invoice.invoiceSentDate = data.invoice_sent_date ? data.invoice_sent_date : null;
    invoice.invoicePaid = data.invoice_paid === true;
    invoice.invoicePaidDate = data.invoice_paid_date ? data.invoice_paid_date : null;
    invoice.fundsCredited = data.funds_credited === true;
    invoice.fundsCreditedDate = data.funds_credited_date ? data.funds_credited_date : null;
    invoice.customerZoneAuto = ICustomerZoneAuto.parse(data['customer_zone_auto']);
    invoice.createdAt = moment(data.created_at).isValid() ? moment(data.created_at) : null;
    invoice.updatedAt = moment(data.updated_at).isValid() ? moment(data.updated_at) : null;

    return invoice;
  }

}
