import React from 'react';
import Checkbox from '../Checkbox/Checkbox';

// Add selection row checkbox
const useRowSelection = () => {
  return hooks => {
    hooks.allColumns.push(columns => [
      {
        id: 'selection',
        className: 'col-selection',
        Header: ({getToggleAllRowsSelectedProps}) => (
          <div>
            <Checkbox
              checkbox={{
                ...getToggleAllRowsSelectedProps()
              }}
            />
          </div>
        ),
        Cell: ({row}) => {
          return (
            <div>
              <Checkbox
                checkbox={{
                  ...row.getToggleRowSelectedProps()
                }}
              />
            </div>
          )
        },
      },
      ...columns,
    ])
  };
};

export default useRowSelection;
