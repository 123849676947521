import React from 'react';
import Auth from '../Providers/Auth';

const AdminContext = React.createContext<{
  auth: Auth
}>({
  auth: new Auth()
});

export default AdminContext;
