import React, {FC, useEffect, useRef, useState} from 'react';
import {useTranslation} from 'react-i18next';
import {useHistory, useParams} from 'react-router-dom';
import './BuyerIdsForm.scss';
import BuyerIdProvider from '../../Providers/BuyerId';
import Input from '../../Core/Form/Input/Input';
import $ from 'jquery';
import * as EgoUtil from '@egofoxlab/util';
import Notify from '../../Core/Ui/Notify/Notify';
import Select, {SelectStylesHideIndicatorSeparator} from '../../Core/Form/Select/Select';
import Head from '../Head/Head';
import BottomToolbar from '../BottomToolbar/BottomToolbar';
import {Accordion, AccordionDetails} from '@material-ui/core';
import DownArrowIndicator from '../../Core/Form/Select/Indicators/DownArrowIndicator';
import Auction from '../../Providers/Auction';
import IBuyerId from '../../Interfaces/IBuyerId';

const buyerIdProvider = new BuyerIdProvider();

const BuyerIdsForm: FC = () => {
  const {t} = useTranslation();
  const auctionOptions = [
    {
      value: Auction.COPART,
      label: t(`admin:auction.${Auction.COPART}`)
    },
    {
      value: Auction.IAAI,
      label: t(`admin:auction.${Auction.IAAI}`)
    },
    {
      value: Auction.MANHEIM,
      label: t(`admin:auction.${Auction.MANHEIM}`)
    },
    {
      value: Auction.GR8AUTO,
      label: t(`admin:auction.${Auction.GR8AUTO}`)
    },
    {
      value: Auction.IMPACTAUTO,
      label: t(`admin:auction.${Auction.IMPACTAUTO}`)
    },
  ];
  const history = useHistory();
  const id = parseInt(useParams().id) || null;
  const [buyerId, setBuyerId] = useState<IBuyerId>();
  const [auction, setAuction] = useState<any>();
  const [saveProcess, setSaveProcess] = useState(false);
  const [saveNotify, setSaveNotify] = useState(false);
  const _eForm = useRef<HTMLFormElement>();

  useEffect(() => {
    (async () => {
      let buyerId = id > 0 ? await buyerIdProvider.get(id) : null;
      buyerId = buyerId ?? IBuyerId.parse({})

      if (!buyerId) {
        history.push('/admin/buyer-ids');

        return;
      }

      setBuyerId(buyerId);
      setAuction(buyerId.auction);
    })();
    // eslint-disable-next-line
  }, []);

  function reset(): void {
    // Clear input field
    EgoUtil.clearFields(_eForm.current);
    $(_eForm.current).find('[name]').trigger('change');
  }

  async function onSubmit(e) {
    if (!_eForm.current.checkValidity()) {
      return;
    }

    e.preventDefault();

    // Set `Save Process` flag
    setSaveProcess(true);

    const eForm = $(_eForm.current);
    const formData = new FormData();
    // Login
    formData.append('login', eForm.find('[name="login"]').val());
    // Password
    formData.append('password', eForm.find('[name="password"]').val());
    // Auction
    formData.append('auction', auction ?? '');

    // Save form
    let response;

    try {
      if (buyerId && buyerId.id > 0)
        response = await buyerIdProvider.update(buyerId.id, formData);
      else
        response = await buyerIdProvider.create(formData);
    } catch (ex) {
      // Unset `Save Process` flag
      setSaveProcess(false);

      return;
    }

    // Mark as saved
    setSaveNotify(true);

    // Update state after saving
    if (!buyerId || !(buyerId.id > 0)) {
      history.replace(`/admin/buyer-ids/form/${response.id}`);
      setBuyerId(IBuyerId.parse(response));
    }

    // Unset `Save Process` flag
    setSaveProcess(false);
  }

  return (
    <>
      {buyerId !== undefined && <div
          id="buyer-ids-form-component"
      >
        <Head
            head={t('admin:buyer_ids.form.head')}
            subhead={'VIN - Auto'}
            right={<div className="d-flex justify-content-lg-end justify-content-start train-buttons">
              <button
                form="buyer-ids-form"
                className="btn btn-save"
                disabled={saveProcess}
              >
                {t('buttons.save')}
              </button>
              <button
                type="button"
                className="btn btn-reload"
                onClick={() => reset()}
              >
                {t('buttons.reset')}
              </button>
              <button
                type="button"
                className="btn btn-remove"
              >
                {t('buttons.remove')}
              </button>
            </div>}
            hr={{style: {marginTop: 20}}}
        />

        <form
            ref={_eForm}
            id="buyer-ids-form"
            onSubmit={onSubmit}
        >
          <Accordion defaultExpanded={true}>
            <AccordionDetails className="pt-0">
              <div className="row d-flex flex-grow-1">
                <div className="col-12 col-sm-6 col-md-6 col-lg-4 col-xl-3">
                  {/*Login*/}
                  <Input
                      label={t('admin:buyer_ids.login')}
                      input={{
                        name: 'login',
                        defaultValue: buyerId.login ?? '',
                        required: true
                      }}
                  />
                </div>
                <div className="col-12 col-sm-6 col-md-6 col-lg-4 col-xl-3">
                  {/*Auction*/}
                  <Select
                      label={t('admin:buyer_ids.auction')}
                      select={{
                        isSearchable: false,
                        options: auctionOptions,
                        defaultValue: auctionOptions.find(item => item.value === buyerId.auction),
                        required: true,
                        components: {
                          DropdownIndicator: DownArrowIndicator,
                        },
                        styles: SelectStylesHideIndicatorSeparator,
                        onChange: option => setAuction(option.value)
                      }}
                      required={true}
                  />
                </div>
              </div>
            </AccordionDetails>
          </Accordion>
        </form>

        <BottomToolbar
            right={<>
              <div className="d-flex justify-content-lg-end justify-content-start train-buttons">
                <button
                  form="buyer-ids-form"
                  className="btn btn-save"
                  disabled={saveProcess}
                >
                  {t('buttons.save')}
                </button>
                <button
                  type="button"
                  className="btn btn-reload"
                  onClick={() => reset()}
                >
                  {t('buttons.reset')}
                </button>
                <button
                  type="button"
                  className="btn btn-remove"
                >
                  {t('buttons.remove')}
                </button>
              </div>
              <div className="d-flex justify-content-end">
                <Notify
                  type="save-form"
                  hide={!saveNotify}
                />
              </div>
            </>}
        />
      </div>}
    </>
  );
};

export default BuyerIdsForm;
