import moment, {Moment} from 'moment';
import Config from '../Providers/Config';
import ICustomerZoneAuto from './ICustomerZoneAuto';

export default class ITracking {

  id: number;
  customerZoneAutoId: number;
  data: {
    steps: {
      id?: string;
      name?: string;
      date?: Moment;
      status: boolean;
    }[];
  };
  code: string;
  customerZoneAuto?: ICustomerZoneAuto;
  createdAt: Moment;
  updatedAt: Moment;

  public static parse(data: any): ITracking {
    if (!data) {
      return null;
    }

    const tracking = new ITracking();

    tracking.id = data.id ? parseInt(data.id, 10) : null;
    tracking.customerZoneAutoId = data.customer_zone_auto_id ? data.customer_zone_auto_id : null;
    tracking.data = {
      steps: (data.data['steps'] ?? []).map(item => {
        return ({
          id: item.id ?? null,
          name: item.name ?? null,
          date: moment(item.date, Config.DATE_FORMAT).isValid() ? moment(item.date, Config.DATE_FORMAT) : null,
          status: item.status === true,
        });
      }),
    };
    tracking.code = data.code;
    tracking.createdAt = moment(data.created_at).isValid() ? moment(data.created_at) : null;
    tracking.updatedAt = moment(data.updated_at).isValid() ? moment(data.updated_at) : null;
    tracking.customerZoneAuto = ICustomerZoneAuto.parse(data['customer_zone_auto']);

    return tracking;
  }

}
