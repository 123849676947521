import React, {FC, useContext, useEffect, useRef, useState} from 'react';
import {useTranslation} from 'react-i18next';
import './PersonalData.scss';
import UserProvider from '../../Providers/User';
import Input from '../../Core/Form/Input/Input';
import Notify from '../../Core/Ui/Notify/Notify';
import Config from '../../Providers/Config';
import axios from 'axios';
import {IHttpResponse} from '../../Interfaces/IHttpResponse';
import IUser from '../../Interfaces/IUser';
import * as EgoUtil from '@egofoxlab/util';
import Errors from '../../Core/Form/Errors/Errors';
import Head from '../Head/Head';
import {Accordion, AccordionDetails, AccordionSummary} from '@material-ui/core';
import BottomToolbar from '../BottomToolbar/BottomToolbar';
import AdminContext from '../AdminContext';
import Auction from '../../Providers/Auction';

const userProvider = new UserProvider();

const PersonalData: FC = () => {
  const {t} = useTranslation();
  const context = useContext(AdminContext);
  const auctionOptions = [
    {
      value: Auction.COPART,
      label: t(`admin:auction.${Auction.COPART}`)
    },
    {
      value: Auction.IAAI,
      label: t(`admin:auction.${Auction.IAAI}`)
    },
    {
      value: Auction.MANHEIM,
      label: t(`admin:auction.${Auction.MANHEIM}`)
    },
    {
      value: Auction.GR8AUTO,
      label: t(`admin:auction.${Auction.GR8AUTO}`)
    },
    {
      value: Auction.IMPACTAUTO,
      label: t(`admin:auction.${Auction.IMPACTAUTO}`)
    },
  ];
  const [origUser, setOrigUser] = useState<IUser>();
  const [user, setUser] = useState({
    firstName: '',
    lastName: '',
    email: '',
    avatar: '',
    city: '',
    phone: ''
  });
  const [currPassword, setCurrPassword] = useState();
  const [password, setPassword] = useState();
  const [confirmPassword, setConfirmPassword] = useState();
  const [passwordNotEqual, setPasswordNotEqual] = useState();
  const [requiredPasswords, setRequiredPasswords] = useState(false);
  const [saveNotify, setSaveNotify] = useState(false);
  const [newEmail, setNewEmail] = useState(false);
  const [errors, setErrors] = useState();
  const _eAvatarInput = useRef<HTMLInputElement>();

  useEffect(() => {
    (async () => {
      const user = await userProvider.get(context.auth.user.id);
      setOrigUser(user);
      setUser({
        firstName: user.firstName,
        lastName: user.lastName,
        email: user.email,
        avatar: user.avatar,
        city: user.city,
        phone: user.phone
      });
    })();
    // eslint-disable-next-line
  }, []);

  function onAvatar(e): void {
    const input = e.target;

    if (input.files && input.files[0]) {
      const reader = new FileReader();

      reader.onload = (e: any) => {
        setUser({...user, avatar: e.target.result});
      };

      reader.readAsDataURL(input.files[0]);
    }
  }

  // Check passwords
  function checkPasswords(): void {
    let valid = null;

    if (password !== confirmPassword) {
      valid = false;
    }

    setPasswordNotEqual(valid);
  }

  function onSubmit(e): void {
    if (!e.target.checkValidity()) {
      return;
    }

    e.preventDefault();

    const formData = new FormData();
    formData.set('avatar', _eAvatarInput?.current?.files?.[0] ?? null);
    formData.set('firstName', user.firstName ? user.firstName : '');
    formData.set('lastName', user.lastName ? user.lastName : '');
    formData.set('email', user.email ? user.email : '');
    formData.set('city', user.city ? user.city : '');
    formData.set('phone', user.phone ? user.phone : '');
    formData.set('currPassword', currPassword ? currPassword : '');
    formData.set('password', password ? password : '');
    formData.set('confirmPassword', confirmPassword ? confirmPassword : '');

    axios.post(Config.api('/personal-data/save'), formData, {
      headers: {'Content-Type': 'multipart/form-data'}
    })
      .then((_response) => {
        const response: IHttpResponse = _response.data;

        if (response.code !== 200) {
          console.error(response.message);
          setErrors(EgoUtil.getArrItem(response.data, 'error'));

          return;
        }

        setErrors(null);
        setSaveNotify(true);
        setNewEmail(user.email !== origUser.email);
      });
  }

  return (
    <>
      {origUser && <form
          id="personal-data"
          onSubmit={onSubmit}
          autoComplete="off"
      >
        <Head head={t('admin:personal_data.header')} />

        <Accordion defaultExpanded={true}>
          <AccordionDetails className="pt-0">
            <div className="d-md-flex flex-grow-1">
              <div className="d-flex flex-column align-items-center align-items-md-start flex-shrink-0 mb-2 mb-md-0 avatar-wrap">
                <div
                    className="avatar"
                    style={{backgroundImage: `url('${user.avatar}')`}}
                />
                <div
                    className="upload-avatar"
                    onClick={() => _eAvatarInput?.current?.click()}
                >
                  {t('admin:personal_data.upload_avatar')}

                  <input
                      ref={_eAvatarInput}
                      type={'file'}
                      name={'avatar'}
                      className={'hide'}
                      onChange={onAvatar}
                  />
                </div>
              </div>
              <div className="row d-flex flex-grow-1">
                <div className="col-12 user-id">
                  {t('admin:personal_data.user_id')}: {origUser.id}
                </div>
                <div className="col-12 col-lg-4 col-xl-3">
                  {/*Name*/}
                  <Input
                      label={t('admin:personal_data.name')}
                      input={{
                        required: true,
                        value: user.firstName,
                        onChange: (e) => setUser({...user, firstName: e.target.value})
                      }}
                  />
                </div>
                <div className="col-12 col-lg-4 col-xl-3">
                  {/*Surname*/}
                  <Input
                      label={t('admin:personal_data.surname')}
                      input={{
                        value: user.lastName,
                        onChange: (e) => setUser({...user, lastName: e.target.value})
                      }}
                  />
                </div>
              </div>
            </div>
          </AccordionDetails>
        </Accordion>

        {/*Buyer ID*/}
        {origUser.buyerIds.length > 0 && <Accordion defaultExpanded={true}>
          <AccordionSummary>
            {t('admin:personal_data.buyer_id')}
          </AccordionSummary>
          <AccordionDetails>
            <div className="d-flex flex-grow-1 flex-column">
              {origUser.buyerIds.map(buyerId => <div
                key={buyerId.id}
                className="row d-flex flex-grow-1"
              >
                <div className="col-12 col-md-6 col-lg-4 col-xl-3">
                  <Input
                    label={t('admin:personal_data.buyer_id_login')}
                    input={{
                      defaultValue: buyerId.login,
                      readOnly: true
                    }}
                  />
                </div>
                <div className="col-12 col-md-6 col-lg-4 col-xl-3">
                  <Input
                    label={t('admin:personal_data.buyer_id_auction')}
                    input={{
                      defaultValue: auctionOptions.find(item => item.value === buyerId.auction)?.label ?? null,
                      readOnly: true
                    }}
                  />
                </div>
              </div>)}
            </div>
          </AccordionDetails>
        </Accordion>}

        <Accordion defaultExpanded={true}>
          <AccordionSummary>
            {t('admin:personal_data.contacts')}
          </AccordionSummary>
          <AccordionDetails>
            <div className="row d-flex flex-grow-1">
              <div className="col-12 col-md-6 col-lg-4 col-xl-3">
                <Input
                    label="Email"
                    input={{
                      value: user.email,
                      required: true,
                      onChange: (e) => setUser({...user, email: e.target.value})
                    }}
                    bottomOffset={0}
                />
                {
                  newEmail ?
                    <div className={'send-email-verify'}>
                      Подтверждение отправлено на почту
                    </div>
                    :
                    <div style={{height: 25}} />
                }
              </div>
              <div className="col-12 col-md-6 col-lg-4 col-xl-3">
                <Input
                    label={t('admin:personal_data.city')}
                    input={{
                      value: user.city,
                      onChange: (e) => setUser({...user, city: e.target.value})
                    }}
                />
              </div>
              <div className="col-12 col-md-6 col-lg-4 col-xl-3">
                <Input
                    label={t('admin:personal_data.phone')}
                    input={{
                      value: user.phone,
                      onChange: (e) => setUser({...user, phone: e.target.value})
                    }}
                />
              </div>
            </div>
          </AccordionDetails>
        </Accordion>

        {/*Password*/}
        <Accordion defaultExpanded={true}>
          <AccordionSummary id="section-password">
            {t('admin:personal_data.password')}
          </AccordionSummary>
          <AccordionDetails>
            <div className="row d-flex flex-grow-1">
              <div className="col-12 col-md-6 col-lg-4 col-xl-3">
                <Input
                    label={t('admin:personal_data.current_password')}
                    input={{
                      type: 'password',
                      placeholder: '******',
                      autoComplete: 'one-time-code',
                      required: requiredPasswords,
                      onChange: (e) => {
                        setCurrPassword(e.target.value)
                        setRequiredPasswords(Boolean(!!e.target.value || password || confirmPassword));
                      }
                    }}
                />
              </div>
              <div className="col-12 col-md-6 col-lg-4 col-xl-3">
                <Input
                    label={t('admin:personal_data.new_password')}
                    input={{
                      type: 'password',
                      required: requiredPasswords,
                      autoComplete: 'one-time-code',
                      onChange: (e) => {
                        setPassword(e.target.value);
                        setRequiredPasswords(Boolean(currPassword || !!e.target.value || confirmPassword));
                      },
                      onBlur: () => checkPasswords()
                    }}
                />
              </div>
              <div className="col-12 col-md-6 col-lg-4 col-xl-3">
                <Input
                    label={t('admin:personal_data.confirm_new_password')}
                    error={passwordNotEqual === false}
                    helperText={passwordNotEqual === false ? t('admin:personal_data.passwords_not_equal') : ''}
                    input={{
                      type: 'password',
                      required: requiredPasswords,
                      autoComplete: 'one-time-code',
                      onChange: (e) => {
                        setConfirmPassword(e.target.value);
                        setRequiredPasswords(Boolean(currPassword || password || e.target.value));
                      },
                      onBlur: () => checkPasswords()
                    }}
                    bottomOffset={0}
                />
              </div>
            </div>
          </AccordionDetails>
        </Accordion>

        <BottomToolbar
            right={<>
              <div className="d-flex justify-content-end">
                <button className="btn btn-save">{t('buttons.save')}</button>
              </div>
              <div className="d-flex flex-column justify-content-end">
                <Notify
                  className='justify-content-end'
                  type="save-form"
                  hide={!saveNotify}
                />
                {/*Errors*/}
                <Errors
                  className='justify-content-end'
                  errors={errors}
                  style={{marginTop: 15, marginBottom: 0}}
                />
              </div>
            </>}
        />
      </form>}
    </>
  );
};

export default PersonalData;
