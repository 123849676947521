export default class IDestinationPort {

  id: number;
  country: string;
  displayCountry: string;
  city: string;
  displayCity: string;

  public static parse(data: any): IDestinationPort {
    if (!data) {
      return null;
    }

    const destinationPort = new IDestinationPort();

    destinationPort.id = data.id ? parseInt(data.id, 10) : null;
    destinationPort.country = data.country ?? null;
    destinationPort.displayCountry = data.display_country ?? null;
    destinationPort.city = data.city ?? null;
    destinationPort.displayCity = data.display_city ?? null;

    return destinationPort;
  }

}
