import React from 'react';
import './Checkbox.scss';
import {Checkbox, FormControlLabel} from '@material-ui/core';

const CheckboxIcon = () => {
  return (
    <span className={'checkbox-icon'} />
  );
};

const CheckboxIconChecked = () => {
  return (
    <span className={'checkbox-icon checked'} />
  );
};

const CheckboxIconIndeterminate = () => {
  return (
    <span className={'checkbox-icon indeterminate'} />
  );
};

export default function CheckboxComponent(props) {
  return (
    <div
      className="form-field checkbox"
      style={{
        ...props.style,
        marginBottom: props.bottomOffset
      }}
    >
      <div className="field">
        {props.label ?
            <FormControlLabel
              {...props.label}
              control={<Checkbox
                disableRipple={true}
                icon={<CheckboxIcon />}
                checkedIcon={<CheckboxIconChecked />}
                indeterminateIcon={<CheckboxIconIndeterminate />}
                {...props.checkbox}
              />}
            />
        :
          <Checkbox
            disableRipple={true}
            icon={<CheckboxIcon />}
            checkedIcon={<CheckboxIconChecked />}
            indeterminateIcon={<CheckboxIconIndeterminate />}
            {...props.checkbox}
          />
        }
      </div>
    </div>
  );
}
