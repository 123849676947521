export class UserPermission {

  public static readonly CUSTOMER_ZONE_AUTOS_FULL = 'customer-zone-autos-full';
  public static readonly CUSTOMER_ZONE_AUTOS_READONLY = 'customer-zone-autos-readonly';
  public static readonly CUSTOMER_ZONE_AUTOS_SHARED = 'customer-zone-autos-shared';
  public static readonly INVOICES_FULL = 'invoices-full';
  public static readonly INVOICES_READONLY = 'invoices-readonly';
  public static readonly INVOICES_SHARED = 'invoices-shared';
  public static readonly CUSTOMER_ZONE_BALANCES_FULL = 'customer-zone-balances-full';
  public static readonly CUSTOMER_ZONE_BALANCES_READONLY = 'customer-zone-balances-readonly';
  public static readonly CUSTOMER_ZONE_BALANCES_SHARED = 'customer-zone-balances-shared';
  public static readonly TRACKINGS_FULL = 'trackings-full';
  public static readonly TRACKINGS_READONLY = 'trackings-readonly';
  public static readonly TRACKINGS_SHARED = 'trackings-shared';
  public static readonly CALCULATOR = 'calculator';
  public static readonly BUYER_ID_FULL = 'buyer-id-full';
  public static readonly BUYER_ID_READONLY = 'buyer-id-readonly';
  public static readonly USERS_FULL = 'users-full';
  public static readonly USERS_READONLY = 'users-readonly';
  public static readonly PROMOTIONS = 'promotions';
  public static readonly PAYMENT_DATA = 'payment-data';
  public static readonly REFERRAL_PROGRAM_FULL = 'referral-program-full';
  public static readonly REFERRAL_PROGRAM_READONLY = 'referral-program-readonly';

}
