import React, {FC} from 'react';
import './BottomToolbar.scss';

const BottomToolbar: FC<{
  className?: string;
  top?: any;
  left?: any;
  right?: any;
  bottom?: any;
}> = (props) => {
  return (
    <div
      id="bottom-toolbar-component"
      className={`row ${props.className ? props.className : ''}`}
    >
      <div className="col-12">
        <hr />
      </div>

      {props.top ? <div className="col-12">
        {props.top}
      </div> : ''}
      <div className="col-12 col-lg-6">
        {props.left}
      </div>
      <div className="col-12 col-lg-6">
        {props.right}
      </div>
      {props.bottom ? <div className="col-12">
        {props.bottom}
      </div> : ''}
    </div>
  );
};

export default BottomToolbar;

