import React, {FC, useEffect, useState} from 'react';
import './SignIn.scss';
import {useDispatch} from 'react-redux';
import {useTranslation} from 'react-i18next';
import Input from '../../Core/Form/Input/Input';
import {Link} from 'react-router-dom';
import Checkbox from '../../Core/Form/Checkbox/Checkbox';
import FbButton from '../../Core/Form/FbButton/FbButton';
import axios from 'axios';
import Config from '../../Providers/Config';
import {IHttpResponse} from '../../Interfaces/IHttpResponse';
import Auth from '../../Providers/Auth';
import FacebookLogin from 'react-facebook-login/dist/facebook-login-render-props'
import Errors from '../../Core/Form/Errors/Errors';
import {InputLabel} from '@material-ui/core';
import setBgImg from '../../Core/Store/Actions/Login/setBgImg';

const SignIn: FC = () => {
  const {t} = useTranslation();
  const dispatch = useDispatch();
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [rememberMe, setRememberMe] = useState(false);
  const [errors, setErrors] = useState(null);

  useEffect(() => {
    dispatch(setBgImg('/assets/images/sign-in/sign-in-bg-1.jpg'));
    // eslint-disable-next-line
  }, []);

  /**
   * Register with Facebook
   */
  function onFbCallback(response) {
    axios.post(Config.api('/auth/sign-in'), {
      fbToken: response.accessToken
    })
      .then((_response) => {
        const response: IHttpResponse = _response.data;

        if (response.code !== 200) {
          console.error(response.message);
          setErrors(response?.data?.error ?? null);

          return;
        }

        (new Auth()).save({
          token: response.data.api_token,
        }, true);

        window.location.href = '/admin';
      });
  }

  /**
   * Classic login
   */
  function onSignIn(e) {
    e.preventDefault();

    axios.post(Config.api('/auth/sign-in'), {
      email,
      password
    })
      .then((_response) => {
        const response: IHttpResponse = _response.data;

        if (response.code !== 200) {
          console.error(response.message);
          setErrors(response?.data?.error ?? null);

          return;
        }

        (new Auth()).save({
          token: response.data.api_token,
        }, rememberMe);

        window.location.href = '/admin';
      });
  }

  return (
    <div id="sign-in">
      <div className="content">
        <div className="header">
          <h5 className="title">
            {t('login:sign_in.title')}
          </h5>
          <p className="subtitle">
            {t('login:sign_in.subtitle')}
          </p>
        </div>

        <form
          className="form"
          autoComplete="off"
          onSubmit={onSignIn}
        >
          {/*Email*/}
          <Input
            label="Email"
            input={{
              type: 'email',
              placeholder: 'john@gmail.com',
              autoComplete: 'off',
              required: true,
              onChange: (e) => setEmail(e.target.value)
            }}
          />

          {/*Password*/}
          <Input
            input={{
              type: 'password',
              placeholder: t('login:sign_in.password_placeholder'),
              autoComplete: 'off',
              required: true,
              onChange: (e) => setPassword(e.target.value)
            }}
            components={{
              label: <div className="d-flex flex-grow-1">
                <InputLabel
                  shrink
                  htmlFor="bootstrap-input"
                >
                  {t('login:sign_in.password_label')}
                </InputLabel>
                <Link
                  to="/forgot-password"
                  className="forgot-password"
                >
                  {t('login:sign_in.forgot_password')}
                </Link>
              </div>
            }}
            bottomOffset={18}
          />

          <div style={{marginBottom: '18px'}}>
            <Errors errors={errors} />

            <button className="w-100 th-submit">
              {t('login:sign_in.sign_in_btn')}
            </button>
          </div>

          <div>
            <Checkbox
              label={{
                label: t('login:sign_in.remember_me')
              }}
              checkbox={{
                name: 'remember-me',
                onChange: (e, checked) => setRememberMe(checked)
              }}
              bottomOffset={42}
            />
          </div>

          <div
            style={{
              textAlign: 'center',
              color: '#7f8fa4',
              fontFamily: 'Lato-Light',
              fontSize: '13.48px',
              marginBottom: '31px'
            }}
          >
            {t('login:sign_in.or_sign_with')}
          </div>

          {/*Facebook*/}
          <div className="text-center">
            <FacebookLogin
              appId={Config.FB_ID}
              autoLoad={false}
              callback={onFbCallback}
              render={renderProps => (
                <FbButton
                  onClick={renderProps.onClick}
                />
              )}
            />
          </div>

          <div className="create-account">
            <span>
              {t('login:sign_in.not_have_account')}
            </span>
            <Link to="/sign-up">
              {t('login:sign_in.register')}
            </Link>
          </div>
        </form>
      </div>
    </div>
  );
};

export default SignIn;
