import React from 'react';
import {components} from 'react-select';

export default function DownArrowIndicator(props) {
  return (
    <components.DropdownIndicator {...props}>
      <img
        className={'dropdown-indicator'}
        src={'/assets/images/icon-select-indicator-down-arrow.png'}
        alt={'select-dropdown-indicator'}
        style={{height: 6}}
      />
    </components.DropdownIndicator>
  );
}
