import React, {FC, useEffect, useRef} from 'react';
import './style.scss';
import $ from 'jquery';

const Confirm: FC<{
  id: string;
  className?: string;
  title?: string;
  message?: any;
  cancel?: string;
  apply?: string;
  open?: boolean;
  onApply?: any;
  onShow?: () => void;
  onHide?: () => void;
}> = (props) => {
  const _eRoot = useRef<HTMLDivElement>();

  useEffect(() => {
    $(_eRoot.current).on('show.bs.modal', () => {
      if (typeof props.onShow === 'function')
        props.onShow();
    });

    $(_eRoot.current).on('hide.bs.modal', () => {
      if (typeof props.onHide === 'function')
        props.onHide();
    });
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    if (props.open)
      $(_eRoot.current).modal('show');
    else
      $(_eRoot.current).modal('hide');
  }, [props.open]);

  return (
    <div
      ref={_eRoot}
      className={`modal fade ${props.className}`}
      id={props.id}
      role="dialog"
      aria-labelledby="exampleModalLongTitle"
      aria-hidden="true"
    >
      <div

        className="modal-dialog"
        role="document"
      >
        <div className="modal-content">
          <div className="modal-header">
            <h5
              className="modal-title"
            >
              {props.title}
            </h5>
          </div>
          <div className="modal-body">
            {props.message}
          </div>
          <div className="modal-footer">
            <button
              type="button"
              className="btn th-apply cancel"
              data-dismiss="modal"
            >
              {props.cancel}
            </button>
            <button
              type="button"
              className="btn th-submit apply"
              data-dismiss="modal"
              onClick={() => {
                if (typeof props.onApply === 'function')
                  props.onApply();
              }}
            >
              {props.apply}
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Confirm;
