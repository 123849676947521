import moment from 'moment';

export default class IRole {

  id: number;
  name: string;
  displayName: string;
  visible: boolean;
  removable: boolean;
  permissions: string[];
  isAdmin: boolean;
  createdAt: string;
  updatedAt: string;

  public static parse(data: any): IRole {
    if (!data) {
      return null;
    }

    const role = new IRole();

    role.id = data.id ? parseInt(data.id, 10) : null;
    role.name = data.name ? data.name : '';
    role.displayName = data.display_name ?? null;
    role.visible = data.visible === true;
    role.removable = data.removable === true;
    role.permissions = Array.isArray(data.permissions) ? data.permissions : [];
    role.isAdmin = data.is_admin === true;
    role.createdAt = data.created_at ? moment(data.created_at, 'YYYY-MM-DD HH:mm:ss').format('DD.MM.YYYY') : null;
    role.updatedAt = data.updated_at ? moment(data.created_at, 'YYYY-MM-DD HH:mm:ss').format('DD.MM.YYYY') : null;

    return role;
  }

  public static checkPermission(role: IRole, permissions: string | string[], checkAdmin: boolean = true): boolean {
    if (typeof permissions === 'string') {
      permissions = [permissions];
    }

    if (checkAdmin && role.isAdmin)
      return true;

    return permissions.some((item) => role.permissions.includes(item));
  }

}
