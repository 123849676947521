import React, {FC} from 'react';
import './Picker.scss';
import FormControl from '@material-ui/core/FormControl';
import {FormHelperText, InputLabel} from '@material-ui/core';

const Picker: FC<any> = (props) => {
  const components = props.components || {};

  return (
    <div
      className={`form-field picker ${props.className ? props.className : ''}`}
      style={{
        ...props.style,
        marginBottom: props.bottomOffset
      }}
    >
      <div className="field">
        {props.hideLabel ?
          <FormControl error={props.error}>
            {props.picker}
            <FormHelperText>
              {props.helperText}
            </FormHelperText>
          </FormControl>
          :
          <FormControl error={props.error}>
            <div className="label">
              {components.label ? components.label :
                <InputLabel shrink htmlFor="bootstrap-input">
                  {props.label}
                </InputLabel>
              }
            </div>
            {props.picker}
            <FormHelperText>
              {props.helperText}
            </FormHelperText>
          </FormControl>
        }
      </div>
    </div>
  );
};

export default Picker;
