export default class IDeparturePort {

  id: number;
  country: string;
  displayCountry: string;
  state: string;
  displayState: string;
  city: string;
  displayCity: string;

  public static parse(data: any): IDeparturePort {
    if (!data) {
      return null;
    }

    const departurePort = new IDeparturePort();

    departurePort.id = data.id ? parseInt(data.id, 10) : null;
    departurePort.country = data.country ?? null;
    departurePort.displayCountry = data.display_country ?? null;
    departurePort.state = data.state ?? null;
    departurePort.displayState = data.display_state ?? null;
    departurePort.city = data.city ?? null;
    departurePort.displayCity = data.display_city ?? null;

    return departurePort;
  }

}
