import React, {FC, useEffect, useState} from 'react';
import {useTranslation} from 'react-i18next';
import './ReferralsHistory.scss';
import axios from 'axios';
import Config from '../../../Providers/Config';
import IInvoice from '../../../Interfaces/IInvoice';
import moment from 'moment';

async function load() {
  const response = (await axios.get(Config.api(`/invoices/get-by-referral-code`))).data;

  if (!response.success) {
    console.error(response.message);

    return false;
  }

  //  Skip empty
  if (!response.data)
    return false;

  return response.data.map(invoice => IInvoice.parse(invoice));
}

const ReferralsHistory: FC = () => {
  const {t} = useTranslation();
  const [invoices, setInvoices] = useState([]);

  useEffect(() => {
    (async () => {
      const invoices = (await load()) || [];
      setInvoices(invoices);
    })();
  }, []);

  return (
    <div
      id="referrals-history-component"
      className="refresh-container"
    >
      {/*Refresh*/}
      <div
        className="tool refresh text-right"
        onClick={() => {
          (async () => {
            const invoices = (await load()) || [];
            setInvoices(invoices);
          })();
        }}
      >
        <img
          src={'/assets/images/refresh.svg'}
          alt="refresh"
          height={16}
        />
      </div>

      <div className="table-wrap-scroll">
        <div className="table-wrap">
          {/*Header*/}
          <div className="header">
            {/*Date*/}
            <div className="date-activation">
              <img
                src={'/assets/images/icon-calendar-empty.svg'}
                alt="icon-calendar-empty"
                height={17}
              />
              <span>
                {t('admin:promotions_data.referrals_history.date_activation')}
              </span>
            </div>
            {/*Name*/}
            <div className="name">
              <img
                src={'/assets/images/icon-user-silhouette.svg'}
                alt="icon-user-silhouette"
                height={17}
              />
              <span>
                {t('admin:promotions_data.referrals_history.name')}
              </span>
            </div>
            {/*Amount*/}
            <div className="amount">
              <img
                src={'/assets/images/icon-dollar-symbol.svg'}
                alt="icon-dollar-symbol"
                height={17}
              />
              <span>
                {t('admin:promotions_data.referrals_history.amount')}
              </span>
            </div>
          </div>

          {/*Referrals*/}
          {/*<div className="referral">
                    Info
                    <div className="info">
                        Date
                        <div className="date-activation">
                            05.07.2019
                        </div>
                        Name
                        <div className="name">
                            Пономарев С.В.
                        </div>
                        Amount
                        <div className="amount">
                            $100
                        </div>
                    </div>
                    Status line
                    <div className="row status-line">
                        Promo code activate
                        <div className="col-3 status promo-code-activated active">
                            <div className="title">
                                {t('admin:promotions_data.referrals_history.status_promo_code_activated')}
                            </div>
                            <div className="image">
                                <img
                                    src={'/assets/images/icon-active-point.svg'}
                                    alt="icon-active-point"
                                />
                            </div>
                            <div className="date">
                                05.07.2019, 16:59
                            </div>
                        </div>
                        Invoice sent
                        <div className="col-3 status promo-code-activated active last-active">
                            <div className="title">
                                {t('admin:promotions_data.referrals_history.status_invoice_sent')}
                            </div>
                            <div className="image">
                                <img
                                    src={'/assets/images/icon-active-point.svg'}
                                    alt="icon-active-point"
                                />
                            </div>
                            <div className="date">
                                05.07.2019, 16:59
                            </div>
                        </div>
                        Invoice paid
                        <div className="col-3 status promo-code-activated">
                            <div
                                className="title"
                                style={{width: '80px'}}
                            >
                                {t('admin:promotions_data.referrals_history.status_invoice_paid')}
                            </div>
                            <div className="image">
                                <img
                                    src={'/assets/images/icon-not-active-point.svg'}
                                    alt="icon-not-active-point"
                                />
                            </div>
                        </div>
                        Fund credited
                        <div className="col-3 status promo-code-activated">
                            <div
                                className="title"
                                style={{width: '80px'}}
                            >
                                {t('admin:promotions_data.referrals_history.status_funds_credited')}
                            </div>
                            <div className="image">
                                <img
                                    src={'/assets/images/icon-not-active-point.svg'}
                                    alt="icon-not-active-point"
                                />
                            </div>
                        </div>
                    </div>
                </div>*/}
          {
            invoices.map((invoice) => {
              let statusActive = {
                promoCodeActive: invoice.promoCodeActive ? ' active ' : '',
                invoiceSent: invoice.invoiceSent ? ' active ' : '',
                invoicePaid: invoice.invoicePaid ? ' active ' : '',
                fundsCredited: invoice.fundsCredited ? ' active ' : ''
              };
              const keys = ['promoCodeActive', 'invoiceSent', 'invoicePaid', 'fundsCredited'];

              for (let i = 0; i < keys.length - 1; i++) {
                if (!statusActive[keys[i + 1]]) {
                  statusActive[keys[i]] += ' last-active';

                  break;
                }
              }

              return <div
                key={invoice.id}
                className="referral"
              >
                {/*Info*/}
                <div className="info">
                  {/*Date*/}
                  <div className="date-activation">
                    {
                      moment(
                        invoice.promoCodeActiveDate,
                        'YYYY-MM-DD HH:mm:ss'
                      ).format('DD.MM.YYYY, HH:mm')
                    }
                  </div>
                  {/*Name*/}
                  <div className="name">
                    {invoice.data.shortName}
                  </div>
                  {/*Amount*/}
                  <div className="amount">
                    ${invoice.rewardAmount}
                  </div>
                </div>
                {/*Status line*/}
                <div className="row status-line">
                  {/*Promo code activate*/}
                  <div
                    className={
                      'col-3 status promo-code-activated ' +
                      statusActive.promoCodeActive
                    }
                  >
                    <div className="title">
                      {t('admin:promotions_data.referrals_history.status_promo_code_activated')}
                    </div>
                    <div className="image">
                      {
                        invoice.promoCodeActive ?
                          <img
                            src={'/assets/images/icon-active-point.svg'}
                            alt="icon-active-point"
                          />
                          :
                          <img
                            src={'/assets/images/icon-not-active-point.svg'}
                            alt="icon-active-point"
                          />
                      }
                    </div>
                    <div className="date">
                      {
                        invoice.promoCodeActiveDate ?
                          moment(
                            invoice.promoCodeActiveDate,
                            'YYYY-MM-DD HH:mm:ss'
                          ).format('DD.MM.YYYY, HH:mm')
                          :
                          ''
                      }
                    </div>
                  </div>
                  {/*Invoice sent*/}
                  <div
                    className={
                      'col-3 status invoice-sent ' +
                      statusActive.invoiceSent
                    }
                  >
                    <div className="title">
                      {t('admin:promotions_data.referrals_history.status_invoice_sent')}
                    </div>
                    <div className="image">
                      {
                        invoice.invoiceSent ?
                          <img
                            src={'/assets/images/icon-active-point.svg'}
                            alt="icon-active-point"
                          />
                          :
                          <img
                            src={'/assets/images/icon-not-active-point.svg'}
                            alt="icon-active-point"
                          />
                      }
                    </div>
                    <div className="date">
                      {
                        invoice.invoiceSentDate ?
                          moment(
                            invoice.invoiceSentDate,
                            'YYYY-MM-DD HH:mm:ss'
                          ).format('DD.MM.YYYY, HH:mm')
                          :
                          ''
                      }
                    </div>
                  </div>
                  {/*Invoice paid*/}
                  <div
                    className={
                      'col-3 status invoice-paid ' +
                      statusActive.invoicePaid
                    }
                  >
                    <div
                      className="title"
                      style={{width: '100px'}}
                    >
                      {t('admin:promotions_data.referrals_history.status_invoice_paid')}
                    </div>
                    <div className="image">
                      {
                        invoice.invoicePaid ?
                          <img
                            src={'/assets/images/icon-active-point.svg'}
                            alt="icon-active-point"
                          />
                          :
                          <img
                            src={'/assets/images/icon-not-active-point.svg'}
                            alt="icon-active-point"
                          />
                      }
                    </div>
                    <div className="date">
                      {
                        invoice.invoicePaidDate ?
                          moment(
                            invoice.invoicePaidDate,
                            'YYYY-MM-DD HH:mm:ss'
                          ).format('DD.MM.YYYY, HH:mm')
                          :
                          ''
                      }
                    </div>
                  </div>
                  {/*Funds credited*/}
                  <div
                    className={
                      'col-3 status funds-credited ' +
                      statusActive.fundsCredited
                    }
                  >
                    <div
                      className="title"
                      style={{width: '100px'}}
                    >
                      {t('admin:promotions_data.referrals_history.status_funds_credited')}
                    </div>
                    <div className="image">
                      {
                        invoice.fundsCredited ?
                          <img
                            src={'/assets/images/icon-active-point.svg'}
                            alt="icon-active-point"
                          />
                          :
                          <img
                            src={'/assets/images/icon-not-active-point.svg'}
                            alt="icon-active-point"
                          />
                      }
                    </div>
                    <div className="date">
                      {
                        invoice.fundsCreditedDate ?
                          moment(
                            invoice.fundsCreditedDate,
                            'YYYY-MM-DD HH:mm:ss'
                          ).format('DD.MM.YYYY, HH:mm')
                          :
                          ''
                      }
                    </div>
                  </div>
                </div>
              </div>
            })
          }
        </div>
      </div>
    </div>
  );
};

export default ReferralsHistory;
