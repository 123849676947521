import React, {FC, useEffect, useRef, useState} from 'react';
import {useTranslation} from 'react-i18next';
import {Link, useHistory, useParams} from 'react-router-dom';
import './CustomerZoneAutoForm.scss';
import Input from '../../Core/Form/Input/Input';
import axios from 'axios';
import Config from '../../Providers/Config';
import $ from 'jquery';
import {IHttpResponse} from '../../Interfaces/IHttpResponse';
import * as EgoUtil from '@egofoxlab/util';
import Select, {SelectStylesHideIndicatorSeparator} from '../../Core/Form/Select/Select';
import Textarea from '../../Core/Form/Textarea/Textarea';
import Upload, {IUploadedFile} from '../../Core/Form/Upload/Upload';
import Notify from '../../Core/Ui/Notify/Notify';
import Share from '../Share/Share';
import Head from '../Head/Head';
import BottomToolbar from '../BottomToolbar/BottomToolbar';
import {Accordion, AccordionDetails, AccordionSummary} from '@material-ui/core';
import DownArrowIndicator from '../../Core/Form/Select/Indicators/DownArrowIndicator';
import KeyboardDatePicker from '../../Core/Form/Pickers/KeyboardDatePicker/KeyboardDatePicker';
import {Moment} from 'moment';
import Radio from '../../Core/Form/Radio/Radio';
import RadioGroup from '../../Core/Form/RadioGroup/RadioGroup';
import Cars from '../../Providers/Cars';
import User from '../../Providers/User';
import CustomerZoneAutoProvider from '../../Providers/CustomerZoneAuto';
import ICustomerZoneAuto from '../../Interfaces/ICustomerZoneAuto';
import Auction from '../../Providers/Auction';
import InvoiceProvider from '../../Providers/Invoice';
import IInvoice from '../../Interfaces/IInvoice';
import DestinationPortProvicer from '../../Providers/DestinationPort';
import ISelectOption from '../../Interfaces/ISelectOption';
import IDestinationPort from '../../Interfaces/IDestinationPort';
import IAuctionCity from '../../Interfaces/IAuctionCity';
import DeparturePortProvider from '../../Providers/DeparturePort';
import IDeparturePort from '../../Interfaces/IDeparturePort';
import FormViewValue from '../../Core/Form/FormViewValue/FormViewValue';

const customerZoneAutoProvider = new CustomerZoneAutoProvider();
const userProvider = new User();
const auctionCityProvider = new Auction.City();
const invoiceProvider = new InvoiceProvider();
const destinationPortProvider = new DestinationPortProvicer();
const departurePortProvider = new DeparturePortProvider();

const CustomerZoneAutoForm: FC = () => {
  const {t} = useTranslation();
  const auctionOptions = Cars.auctions().map(item => ({
    value: item,
    label: t(`admin:auction.${item}`),
  }));
  const orderTypeOptions = [
    {
      value: 'auto',
      label: t('admin:order_type.auto'),
    },
  ];
  const [cities, setCities] = useState<ISelectOption[]>();
  const [departurePorts, setDeparturePorts] = useState<ISelectOption[]>();
  const noYesOptions = [
    {
      value: '0',
      label: t('admin:customer_zone_auto.no'),
    },
    {
      value: '1',
      label: t('admin:customer_zone_auto.yes'),
    },
  ];
  const titleStatusOptions = Cars.titleStatuses.map(item => ({
    value: item,
    label: t(`admin:title_status.${item}`),
  }));
  const [destinationPortsOptions, setDestinationPortsOptions] = useState<ISelectOption[]>();
  const trackingOptions = [
    {
      value: 'require-trucking',
      label: 'Require trucking',
    },
    {
      value: 'arrange-trucking-myself',
      label: 'Will arrange trucking by myself',
    },
  ];
  const consolidationOptions = [
    {
      value: 'place_in_container',
      label: t('admin:customer_zone_auto.place_in_container'),
    },
    {
      value: 'own_container',
      label: t('admin:customer_zone_auto.own_container'),
    },
  ];
  const history = useHistory();
  const id = parseInt(useParams().id) || null;
  const [customerZone, setCustomerZone] = useState<ICustomerZoneAuto>(null);
  const [dealer, setDealer] = useState<any>();
  const [buyerId, setBuyerId] = useState<any>();
  const [autoPurchaseDate, setAutoPurchaseDate] = useState<Moment>(null);
  const [auction, setAuction] = useState<any>();
  const [orderType, setOrderType] = useState<any>();
  const [maker, setMaker] = useState<string>();
  const [model, setModel] = useState<any>();
  const [year, setYear] = useState<any>();
  const [purchaseCity, setPurchaseCity] = useState<any>();
  const [departurePort, setDeparturePort] = useState<any>();
  const [dispatch, setDispatch] = useState('');
  const [dispatchDate, setDispatchDate] = useState<Moment>(null);
  const [selfDelivery, setSelfDelivery] = useState('');
  const [warehouseArrivalDate, setWarehouseArrivalDate] = useState<Moment>(null);
  const [titleReceivedDate, setTitleReceivedDate] = useState<Moment>(null);
  const [keys, setKeys] = useState<any>();
  const [titleStatus, setTitleStatus] = useState<any>();
  const [title, setTitle] = useState<IUploadedFile[]>([]);
  const [invoices, setInvoices] = useState<IInvoice[]>();
  const [clientPaymentDate, setClientPaymentDate] = useState<Moment>(null);
  const [auctionPaymentDate, setAuctionPaymentDate] = useState<Moment>(null);
  const [destinationPort, setDestinationPort] = useState<any>();
  const [dateOfLoading, setDateOfLoading] = useState<Moment>(null);
  const [sailDate, setSailDate] = useState<Moment>(null);
  const [eta, setEta] = useState<Moment>(null);
  const [finalDestinationArrivalDate, setFinalDestinationArrivalDate] = useState<Moment>(null);
  //const [plannedWagonLading, setPlannedWagonLading] = useState<Moment>(null);
  //const [wagonLoadingDate, setWagonLoadingDate] = useState<Moment>(null);
  //const [wagonEstimateArrivalDate, setWagonEstimateArrivalDate] = useState<Moment>(null);
  //const [wagonArrivalDate, setWagonArrivalDate] = useState<Moment>(null);
  const [tracking, setTracking] = useState<any>(null);
  const [consolidation, setConsolidation] = useState<any>(null);
  const [photosAuction, setPhotosAuction] = useState<IUploadedFile[]>([]);
  const [photosBeforeLoading, setPhotosBeforeLoading] = useState<IUploadedFile[]>([]);
  const [photosAfterUploading, setPhotosAfterUploading] = useState<IUploadedFile[]>([]);
  const [photosFromDriver, setPhotosFromDriver] = useState<IUploadedFile[]>([]);
  const [dockReceipt, setDockReceipt] = useState<IUploadedFile[]>([]);
  const [billOfLading, setBillOfLading] = useState<IUploadedFile[]>([]);
  const [billOfSale, setBillOfSale] = useState<IUploadedFile[]>([]);
  const [carfaxAutocheck, setCarfaxAutocheck] = useState<IUploadedFile[]>([]);
  const [conosament, setConosament] = useState<IUploadedFile[]>([]);
  const [saveProcess, setSaveProcess] = useState(false);
  const [inApiUpdate, setInApiUpdate] = useState(false);
  const [showMessage, setShowMessage] = useState(false);
  const [message, setMessage] = useState('');
  const [saveNotify, setSaveNotify] = useState(false);
  const _eForm = useRef<HTMLFormElement>(null);

  useEffect(() => {
    getData();
    // eslint-disable-next-line
  }, []);

  // Get fresh invoice
  useEffect(() => {
    (async () => {
      if (!Array.isArray(customerZone?.invoices) || customerZone?.invoices.length === 0) {
        setInvoices(null);
        return;
      }

      setInvoices(((await invoiceProvider.list({ids: customerZone.invoices})) as any)?.data ?? null);
    })();
  }, [customerZone]);

  function getData() {
    (async () => {
      const customerZone = id > 0 ? (await customerZoneAutoProvider.get(id)) : null ?? ICustomerZoneAuto.parse({});

      if (!customerZone) {
        history.push('/admin/customer-zone/auto');

        return;
      }

      // Cities
      const cities = (((await auctionCityProvider.list()) ?? []) as IAuctionCity[]).map(item => ({
        value: item.id,
        label: `${item.city} - ${item.port}`,
      }));
      setCities(cities);
      // Departure ports
      const departurePorts = (((await departurePortProvider.list()) ?? []) as IDeparturePort[]).map(item => ({
        value: item.id,
        label: `${item.displayCity}${item.displayState ? `, ${item.displayState}` : ''}`,
      }));
      setDeparturePorts(departurePorts);
      // Destination ports
      const destinationPortsOptions = (((await destinationPortProvider.list()) ?? []) as IDestinationPort[]).map(
          item => ({
            value: item.id,
            label: `${item.displayCity}, ${item.displayCountry}`,
          }));
      setDestinationPortsOptions(destinationPortsOptions);

      // Inputs
      setMaker(customerZone.data.maker);
      setModel(customerZone.data.model);

      // Radio
      setDispatch(customerZone.data.dispatch);
      setSelfDelivery(customerZone.data.selfDelivery);

      // Dates
      setAutoPurchaseDate(customerZone.data.autoPurchaseDate);
      setDispatchDate(customerZone.data.dispatchDate);
      setWarehouseArrivalDate(customerZone.data.warehouseArrivalDate);
      setTitleReceivedDate(customerZone.data.titleReceivedDate);
      setClientPaymentDate(customerZone.data.clientPaymentDate);
      setAuctionPaymentDate(customerZone.data.auctionPaymentDate);
      setDateOfLoading(customerZone.data.dateOfLoading);
      setSailDate(customerZone.data.sailDate);
      setEta(customerZone.data.eta);
      setFinalDestinationArrivalDate(customerZone.data.finalDestinationArrivalDate);
      //setPlannedWagonLading(customerZone.data.plannedWagonLading);
      //setWagonLoadingDate(customerZone.data.wagonLoadingDate);
      //setWagonEstimateArrivalDate(customerZone.data.wagonEstimateArrivalDate);
      //setWagonArrivalDate(customerZone.data.wagonArrivalDate);

      // Selects
      setAuction(auctionOptions.find(item => item.value === customerZone.data.auction));
      setOrderType(orderTypeOptions.find(item => item.value === customerZone.data.orderType));
      setYear({
        value: customerZone.data.year ?? Cars.years()[0],
        label: customerZone.data.year ?? Cars.years()[0],
      });
      setPurchaseCity(cities.find(item => item.value === customerZone.data.purchaseCity));
      setDeparturePort(departurePorts.find(item => item.value === customerZone.data.departurePort));
      setKeys(noYesOptions.find(item => item.value === customerZone.data.keys));
      setTitleStatus(titleStatusOptions.find(item => item.value === customerZone.data.titleStatus));
      setDestinationPort(destinationPortsOptions.find(item => item.value === customerZone.data.destinationPort));
      setTracking(trackingOptions.find(item => item.value === customerZone.data.tracking));
      setConsolidation(consolidationOptions.find(item => item.value === customerZone.data.consolidation));

      // Files
      setTitle(customerZone.data?.title.map(item => ({
        format: 'url',
        url: item.url,
        originalName: item.original_name,
      })));
      setPhotosAuction(customerZone.data?.photosAuction.map(item => ({
        format: 'url',
        url: item.url,
        originalName: item.original_name,
      })));
      setPhotosBeforeLoading(customerZone.data?.photosBeforeLoading.map(item => ({
        format: 'url',
        url: item.url,
        originalName: item.original_name,
      })));
      setPhotosAfterUploading(customerZone.data?.photosAfterUploading.map(item => ({
        format: 'url',
        url: item.url,
        originalName: item.original_name,
      })));
      setPhotosFromDriver(customerZone.data?.photosFromDriver.map(item => ({
        format: 'url',
        url: item.url,
        originalName: item.original_name,
      })));
      setDockReceipt(customerZone.data?.dockReceipt.map(item => ({
        format: 'url',
        url: item.url,
        originalName: item.original_name,
      })));
      setBillOfLading(customerZone.data?.billOfLading.map(item => ({
        format: 'url',
        url: item.url,
        originalName: item.original_name,
      })));
      setBillOfSale(customerZone.data?.billOfSale.map(item => ({
        format: 'url',
        url: item.url,
        originalName: item.original_name,
      })));
      setCarfaxAutocheck(customerZone.data?.carfaxAutocheck.map(item => ({
        format: 'url',
        url: item.url,
        originalName: item.original_name,
      })));
      setConosament(customerZone.data?.conosament.map(item => ({
        format: 'url',
        url: item.url,
        originalName: item.original_name,
      })));

      setCustomerZone(customerZone);
      EgoUtil.setFieldValue($(_eForm.current).find('[name="shipping-line"]'),customerZone.data.shippingLine);
      EgoUtil.setFieldValue($(_eForm.current).find('[name="booking-number"]'),customerZone.data.bookingNumber);
      EgoUtil.setFieldValue($(_eForm.current).find('[name="container-number"]'),customerZone.data.containerNumber);

   //   $(_eForm.current).find('[name]').trigger('change');



    })();
  }

  function apiUpdate() {
    let url;
    url = Config.api(`/customer-zone/auto/api-update/${customerZone.id}`);

    setInApiUpdate(true);
    axios.post(url
      ).then((_response) => {
      setInApiUpdate(false);
      const response: IHttpResponse = _response.data;
      getData();
      setMessage(response.success['message']);
      setShowMessage(true);
      setTimeout(() =>{
        setShowMessage(false);
        setMessage('')
      }, 2000);

    })


  }


  function reset(): void {
    // Clear input field
    EgoUtil.clearFields(_eForm.current);
    $(_eForm.current).find('[name]').trigger('change');
    // Clear uploads
    setTitle([]);
    setPhotosAuction([]);
    setPhotosBeforeLoading([]);
    setPhotosAfterUploading([]);
    setPhotosFromDriver([]);
    setDockReceipt([]);
    setBillOfLading([]);
    setBillOfSale([]);
    setCarfaxAutocheck([]);
    setConosament([]);
    // Radio
    setDispatch(null);
    setSelfDelivery(null);
    // Dates
    setAutoPurchaseDate(null);
    setDispatchDate(null);
    setWarehouseArrivalDate(null);
    setTitleReceivedDate(null);
    setClientPaymentDate(null);
    setAuctionPaymentDate(null);
    setDateOfLoading(null);
    setSailDate(null);
    setEta(null);
    setFinalDestinationArrivalDate(null);
    //setPlannedWagonLading(null);
    //setWagonLoadingDate(null);
    //setWagonEstimateArrivalDate(null);
    //setWagonArrivalDate(null);
    // Selects
    setDealer(null);
    setBuyerId(null);
    setAuction(null);
    setOrderType(null);
    setYear(null);
    setPurchaseCity(null);
    setDeparturePort(null);
    setKeys(null);
    setTitleStatus(null);
    setInvoices(null);
    setDestinationPort(null);
    setTracking(null);
    setConsolidation(null);
  }

  // Search Dealer based on input
  const dealerLoadOptions = async input => {
    input = input ? input : '';

    // Min input length
    if (input && input.length < 2)
      return false;

    const response = await userProvider.search({group: User.ROLE_GROUP_DEALER, term: input});

    if (!response)
      return [];

    const options = response.map((item) => ({
      value: item.id,
      label: `${item.company} ${item.firstName} ${item.lastName} (ID: ${item.id})`,
    }));

    // Initial value
    if (dealer === undefined && customerZone?.data.dealer > 0) {
      const valueResponse = await userProvider.search({group: User.ROLE_GROUP_DEALER, id: customerZone.data.dealer});
      const item = valueResponse?.[0];
      setDealer(item ? {
        value: item.id,
        label: `${item.company} ${item.firstName} ${item.lastName} (ID: ${item.id})`,
      } : null);
    }

    return options;
  };

  // Search Buyer ID based on input
  const buyerIdLoadOptions = async input => {
    input = input ? input : '';

    // Min input length
    if (input && input.length < 2)
      return false;

    const response = (await axios.get(Config.api(`/buyer-id/search?term=${input}`))).data;

    if (!response.success)
      return [];

    const options = response.data.map((item) => ({
      value: item.id,
      label: `${item.login} - ${t(`admin:auction.${item.auction}`)}`,
    }));

    // Initial value
    if (buyerId === undefined && customerZone?.data.buyerId > 0) {
      const valueResponse = (await axios.get(Config.api(`/buyer-id/search?id=${customerZone.data.buyerId}`))).data;
      const item = valueResponse.success ? valueResponse.data[0] : null;
      setBuyerId(item ? {
        value: item.id,
        label: `${item.login} - ${t(`admin:auction.${item.auction}`)}`,
      } : null);
    }

    return options;
  };

  function onSubmit(e): void {
    if (!_eForm.current.checkValidity()) {
      return;
    }

    e.preventDefault();

    // Set `Save Process` flag
    setSaveProcess(true);

    const eForm = $(_eForm.current);
    const formData = new FormData();
    // Dealer
    formData.append('dealer', dealer?.value ?? '');
    // Buyer ID
    formData.append('buyer_id', buyerId?.value ?? '');
    // Auto purchase date
    formData.append('auto_purchase_date', autoPurchaseDate?.isValid()
        ? autoPurchaseDate.format(Config.DATE_FORMAT) : '');
    // Auction
    formData.append('auction', auction?.value ?? '');
    // Order type
    formData.append('order_type', orderType?.value ?? '');
    // Vin
    formData.append('vin', eForm.find('[name="vin"]').val());
    // Maker
    formData.append('maker', eForm.find('[name="maker"]').val());
    // Model
    formData.append('model', eForm.find('[name="model"]').val());
    // Year
    formData.append('year', year?.value ?? '');
    // Lot
    formData.append('lot', eForm.find('[name="lot"]').val());
    // Purchase city
    formData.append('purchase_city', purchaseCity?.value ?? '');
    // Shipping company
    formData.append('shipping_company', eForm.find('[name="shipping-company"]').val());
    // Point of loading
    formData.append('departure_port', departurePort?.value ?? '');
    // Auto price
    formData.append('auto_price', eForm.find('[name="auto-price"]').val());
    // Link to the lot
    formData.append('lot_link', eForm.find('[name="lot-link"]').val());
    // Dispatch
    formData.append('dispatch', dispatch ?? '');
    // Company of Delivering
    formData.append('delivery_company', eForm.find('[name="delivery-company"]').val());
    // Date of Dispatch
    formData.append('dispatch_date', dispatchDate?.isValid()
        ? dispatchDate.format(Config.DATE_FORMAT) : '');
    // Price for Delivery
    formData.append('delivery_price', eForm.find('[name="delivery-price"]').val());
    // Self Delivery
    formData.append('self_delivery', selfDelivery ?? '');
    // Promised to be Delivery to warehouse
    formData.append('warehouse', eForm.find('[name="warehouse"]').val());
    // Arrival date to Warehouse
    formData.append('warehouse_arrival_date', warehouseArrivalDate?.isValid()
        ? warehouseArrivalDate.format(Config.DATE_FORMAT) : '');
    // Arrival date to Warehouse
    formData.append('title_received_date', titleReceivedDate?.isValid()
        ? titleReceivedDate.format(Config.DATE_FORMAT) : '');
    // Notes for Delivery
    formData.append('delivery_notes', eForm.find('[name="delivery-notes"]').val());
    // Odometer
    formData.append('odometer', eForm.find('[name="odometer"]').val());
    // Condition
    formData.append('condition', eForm.find('[name="condition"]').val());
    // Keys
    formData.append('keys', keys?.value ?? '');
    // Title status
    formData.append('title_status', titleStatus?.value ?? '');
    // Notes for Delivery
    formData.append('auction_contact', eForm.find('[name="auction-contact"]').val());
    // Notes for Title
    formData.append('title_notes', eForm.find('[name="title-notes"]').val());
    // Title PDF
    title.forEach((file, i) => {
      const name = 'title';

      // File
      if (file.format === 'file') {
        formData.append(`files_${name}[${i}][file]`, file.file);
      }
      // URL
      else {
        formData.append(`${name}[${i}][url]`, (new URL(file.url)).pathname);
        formData.append(`${name}[${i}][original_name]`, file.originalName);
      }
    });
    // Payment date from Client
    formData.append('client_payment_date', clientPaymentDate?.isValid()
        ? clientPaymentDate.format(Config.DATE_FORMAT) : '');
    // Payment date to Auction
    formData.append('auction_payment_date', auctionPaymentDate?.isValid()
        ? auctionPaymentDate.format(Config.DATE_FORMAT) : '');
    // Destination port
    formData.append('destination_port', destinationPort?.value ?? '');
    // Notes
    formData.append('notes', eForm.find('[name="notes"]').val());
    // Date Of Loading
    formData.append('date_of_loading', dateOfLoading?.isValid()
        ? dateOfLoading.format(Config.DATE_FORMAT) : '');
    // Sail Date
    formData.append('sail_date', sailDate?.isValid()
        ? sailDate.format(Config.DATE_FORMAT) : '');
    // Container number
    formData.append('container_number', eForm.find('[name="container-number"]').val());
    // Booking Number
    formData.append('booking_number', eForm.find('[name="booking-number"]').val());
    // ETA
    formData.append('eta', eta?.isValid()
        ? eta.format(Config.DATE_FORMAT) : '');
    // Final destination arrival date
   /* formData.append('final_destination_arrival_date', finalDestinationArrivalDate?.isValid()
        ? finalDestinationArrivalDate.format(Config.DATE_FORMAT) : '');*/
    formData.append('final_destination_arrival_date', eta?.isValid()
        ? eta.format(Config.DATE_FORMAT) : '');
    //region Wagon
    /*// Wagon number
    formData.append('wagon_number', eForm.find('[name="wagon-number"]').val());
    // Wagon loading place
    formData.append('wagon_loading_place', eForm.find('[name="wagon-loading-place"]').val());
    // Destination
    formData.append('wagon_destination', eForm.find('[name="wagon-destination"]').val());
    // Planned wagon lading
    formData.append('planned_wagon_lading', plannedWagonLading?.isValid()
      ? plannedWagonLading.format(Config.DATE_FORMAT) : '');
    // Wagon loading date
    formData.append('wagon_loading_date', wagonLoadingDate?.isValid()
      ? wagonLoadingDate.format(Config.DATE_FORMAT) : '');
    // Wagon estimate arrival date
    formData.append('wagon_estimate_arrival_date', wagonEstimateArrivalDate?.isValid()
      ? wagonEstimateArrivalDate.format(Config.DATE_FORMAT) : '');
    // Wagon arrival date
    formData.append('wagon_arrival_date', wagonArrivalDate?.isValid()
      ? wagonArrivalDate.format(Config.DATE_FORMAT) : '');
    // Wagon notes
    formData.append('wagon_notes', eForm.find('[name="wagon-notes"]').val());
    // Wagon notes for yourself
    formData.append('wagon_notes_for_yourself', eForm.find('[name="wagon-notes-for-yourself"]').val());*/
    //endregion
    // Tracking
    formData.append('tracking', tracking?.value ?? '');
    // Final destination country
    formData.append('final_destination_country', eForm.find('[name="final-destination-country"]').val());
    // Final destination city
    formData.append('final_destination_city', eForm.find('[name="final-destination-city"]').val());
    // Consolidation
    formData.append('consolidation', consolidation?.value ?? '');
    // Shipping line
    formData.append('shipping_line', eForm.find('[name="shipping-line"]').val());
    // Tracking url
    formData.append('tracking_url', eForm.find('[name="tracking-url"]').val());
    // Photos Auction
    photosAuction.forEach((file, i) => {
      const name = 'photos_auction';

      // File
      if (file.format === 'file') {
        formData.append(`files_${name}[${i}][file]`, file.file);
      }
      // URL
      else {
        formData.append(`${name}[${i}][url]`, (new URL(file.url)).pathname);
        formData.append(`${name}[${i}][original_name]`, file.originalName);
      }
    });
    // Photos Before Loading
    photosBeforeLoading.forEach((file, i) => {
      const name = 'photos_before_loading';

      // File
      if (file.format === 'file') {
        formData.append(`files_${name}[${i}][file]`, file.file);
      }
      // URL
      else {
        formData.append(`${name}[${i}][url]`, (new URL(file.url)).pathname);
        formData.append(`${name}[${i}][original_name]`, file.originalName);
      }
    });
    // Photos After Uploading
    photosAfterUploading.forEach((file, i) => {
      const name = 'photos_after_uploading';

      // File
      if (file.format === 'file') {
        formData.append(`files_${name}[${i}][file]`, file.file);
      }
      // URL
      else {
        formData.append(`${name}[${i}][url]`, (new URL(file.url)).pathname);
        formData.append(`${name}[${i}][original_name]`, file.originalName);
      }
    });
    // Photos from the driver
    photosFromDriver.forEach((file, i) => {
      const name = 'photos_from_driver';

      // File
      if (file.format === 'file') {
        formData.append(`files_${name}[${i}][file]`, file.file);
      }
      // URL
      else {
        formData.append(`${name}[${i}][url]`, (new URL(file.url)).pathname);
        formData.append(`${name}[${i}][original_name]`, file.originalName);
      }
    });
    // Dock receipt
    dockReceipt.forEach((file, i) => {
      const name = 'dock_receipt';

      // File
      if (file.format === 'file') {
        formData.append(`files_${name}[${i}][file]`, file.file);
      }
      // URL
      else {
        formData.append(`${name}[${i}][url]`, (new URL(file.url)).pathname);
        formData.append(`${name}[${i}][original_name]`, file.originalName);
      }
    });
    // Bill of lading
    billOfLading.forEach((file, i) => {
      const name = 'bill_of_lading';

      // File
      if (file.format === 'file') {
        formData.append(`files_${name}[${i}][file]`, file.file);
      }
      // URL
      else {
        formData.append(`${name}[${i}][url]`, (new URL(file.url)).pathname);
        formData.append(`${name}[${i}][original_name]`, file.originalName);
      }
    });
    // Bill of sale
    billOfSale.forEach((file, i) => {
      const name = 'bill_of_sale';

      // File
      if (file.format === 'file') {
        formData.append(`files_${name}[${i}][file]`, file.file);
      }
      // URL
      else {
        formData.append(`${name}[${i}][url]`, (new URL(file.url)).pathname);
        formData.append(`${name}[${i}][original_name]`, file.originalName);
      }
    });
    // Carfax/Autocheck
    carfaxAutocheck.forEach((file, i) => {
      const name = 'carfax_autocheck';

      // File
      if (file.format === 'file') {
        formData.append(`files_${name}[${i}][file]`, file.file);
      }
      // URL
      else {
        formData.append(`${name}[${i}][url]`, (new URL(file.url)).pathname);
        formData.append(`${name}[${i}][original_name]`, file.originalName);
      }
    });
    // Conosament
    conosament.forEach((file, i) => {
      const name = 'conosament';

      // File
      if (file.format === 'file') {
        formData.append(`files_${name}[${i}][file]`, file.file);
      }
      // URL
      else {
        formData.append(`${name}[${i}][url]`, (new URL(file.url)).pathname);
        formData.append(`${name}[${i}][original_name]`, file.originalName);
      }
    });

    // Save form
    let url;

    if (customerZone.id > 0) {
      url = Config.api(`/customer-zone/auto/update/${customerZone.id}`);
    } else {
      url = Config.api('/customer-zone/auto/create');
    }

    axios.post(url, formData, {
      headers: {'Content-Type': 'multipart/form-data'},
    }).then((_response) => {
      const response: IHttpResponse = _response.data;

      if (response.code !== 200) {
        console.error(response.message);
        // Unset `Save Process` flag
        setSaveProcess(false);

        return;
      }

      const updatedCustomerZone = ICustomerZoneAuto.parse(response.data);

      // Mark as saved
      setSaveNotify(true);

      // Add customer zone to state after saving
      if (!(customerZone.id > 0)) {
        history.replace(`/admin/customer-zone/auto/form/${response.data.id}`);

        // Update customer zone
        setCustomerZone(updatedCustomerZone);
      }

      // Unset `Save Process` flag
      setSaveProcess(false);
      // Files
      setTitle(updatedCustomerZone.data?.title.map(item => ({
        format: 'url',
        url: item.url,
        originalName: item.original_name,
      })));
      setPhotosAuction(updatedCustomerZone.data?.photosAuction.map(item => ({
        format: 'url',
        url: item.url,
        originalName: item.original_name,
      })));
      setPhotosBeforeLoading(updatedCustomerZone.data?.photosBeforeLoading.map(item => ({
        format: 'url',
        url: item.url,
        originalName: item.original_name,
      })));
      setPhotosAfterUploading(updatedCustomerZone.data?.photosAfterUploading.map(item => ({
        format: 'url',
        url: item.url,
        originalName: item.original_name,
      })));
      setPhotosFromDriver(updatedCustomerZone.data?.photosFromDriver.map(item => ({
        format: 'url',
        url: item.url,
        originalName: item.original_name,
      })));
      setDockReceipt(updatedCustomerZone.data?.dockReceipt.map(item => ({
        format: 'url',
        url: item.url,
        originalName: item.original_name,
      })));
      setBillOfLading(updatedCustomerZone.data?.billOfLading.map(item => ({
        format: 'url',
        url: item.url,
        originalName: item.original_name,
      })));
      setBillOfSale(updatedCustomerZone.data?.billOfSale.map(item => ({
        format: 'url',
        url: item.url,
        originalName: item.original_name,
      })));
      setCarfaxAutocheck(updatedCustomerZone.data?.carfaxAutocheck.map(item => ({
        format: 'url',
        url: item.url,
        originalName: item.original_name,
      })));
      setConosament(updatedCustomerZone.data?.conosament.map(item => ({
        format: 'url',
        url: item.url,
        originalName: item.original_name,
      })));
    });
  }

  return (<>
    {customerZone && destinationPortsOptions && <div id="customer-zone-auto-component">
      <Head
          head={t('admin:customer_zone_auto.form.head')}
          subhead={t('admin:customer_zone_auto.form.subhead')}
          right={<div className="d-flex justify-content-start justify-content-lg-end train-buttons">
            {(customerZone.id > 0 && showMessage)  &&
                <span className="btn btn-message"


                >  {message}</span>

            }
            {customerZone.id > 0 &&
              <button
                  className="btn btn-api"
                  onClick={() => apiUpdate()}
                  disabled={inApiUpdate}
              >
                { !inApiUpdate && <span  style={{
                  fontSize: "10px",
                }}> Обновить через API </span>}
                { inApiUpdate && <span  style={{
                  fontSize: "10px",
                }}> Идет обновление </span>  }
              </button>
            }


            <button
                form="customer-zone-form-component"
                className="btn btn-save"
                disabled={saveProcess || inApiUpdate}
            >
              {t('buttons.save')}
            </button>
            <button
                type="button"
                className="btn btn-reload"
                onClick={() => reset()}
                disabled={saveProcess || inApiUpdate}
            >
              {t('buttons.reset')}
            </button>
            <Link
                to={'/admin/customer-zone/auto'}
                className="btn btn-close"
            >
              {t('buttons.close')}
            </Link>
          </div>}
          hr={{style: {marginTop: 20}}}
      />

      <form
          ref={_eForm}
          id="customer-zone-form-component"
          onSubmit={onSubmit}
      >
        <Accordion defaultExpanded={true}>
          <AccordionDetails className="pt-0">
            <div className="row d-flex flex-grow-1">
              <div className="col-12 col-sm-6 col-md-6 col-lg-4 col-xl-3">
                {/*Dealer*/}
                <Select
                    label={t('admin:customer_zone_auto.dealer')}
                    async={true}
                    select={{
                      cacheOptions: true,
                      loadOptions: dealerLoadOptions,
                      defaultOptions: true,
                      value: dealer,
                      components: {
                        DropdownIndicator: DownArrowIndicator,
                      },
                      styles: SelectStylesHideIndicatorSeparator,
                      onChange: option => setDealer(option),
                    }}
                    required={true}
                />
              </div>
              <div className="col-12 col-sm-6 col-md-6 col-lg-4 col-xl-3">
                {/*Buyer ID*/}
                <Select
                    label={t('admin:customer_zone_auto.buyer_id')}
                    async={true}
                    select={{
                      cacheOptions: true,
                      loadOptions: buyerIdLoadOptions,
                      defaultOptions: true,
                      value: buyerId,
                      components: {
                        DropdownIndicator: DownArrowIndicator,
                      },
                      styles: SelectStylesHideIndicatorSeparator,
                      onChange: option => setBuyerId(option),
                    }}
                    required={true}
                />
              </div>
              <div className="col-12 col-sm-6 col-md-6 col-lg-4 col-xl-3">
                {/*Auto purchase date*/}
                <KeyboardDatePicker
                    label={t('admin:customer_zone_auto.auto_purchase_date')}
                    KeyboardDatePicker={{
                      value: autoPurchaseDate,
                      required: true,
                      onChange: date => setAutoPurchaseDate(date),
                    }}
                />
              </div>
              <div className="col-12 col-sm-6 col-md-6 col-lg-4 col-xl-3">
                {/*Auction*/}
                <Select
                    label={t('admin:customer_zone_auto.auction')}
                    select={{
                      isSearchable: false,
                      options: auctionOptions,
                      value: auction,
                      components: {
                        DropdownIndicator: DownArrowIndicator,
                      },
                      styles: SelectStylesHideIndicatorSeparator,
                      onChange: option => setAuction(option),
                    }}
                    required={true}
                />
              </div>
              <div className="col-12 col-sm-6 col-md-6 col-lg-4 col-xl-3">
                {/*Order type*/}
                <Select
                    label={t('admin:customer_zone_auto.order_type')}
                    select={{
                      isSearchable: false,
                      options: orderTypeOptions,
                      value: orderType,
                      components: {
                        DropdownIndicator: DownArrowIndicator,
                      },
                      styles: SelectStylesHideIndicatorSeparator,
                      onChange: option => setOrderType(option),
                    }}
                />
              </div>
            </div>
          </AccordionDetails>
        </Accordion>

        <Accordion defaultExpanded={true}>
          <AccordionSummary>
            {t('admin:customer_zone_auto.form.info_about_vehicle')}
          </AccordionSummary>
          <AccordionDetails>
            <div className="row d-flex flex-grow-1">
              <div className="col-12 col-sm-6 col-md-6 col-lg-4 col-xl-3">
                {/*Vin*/}
                <Input
                    label={t('admin:customer_zone_auto.vin')}
                    input={{
                      name: 'vin',
                      defaultValue: customerZone.data.vin,
                      required: true,
                      inputProps: {
                        minLength: 17,
                        maxLength: 17,
                        pattern: '^[a-zA-Z0-9]+$',
                      },
                    }}
                />
              </div>
              <div className="col-12 col-sm-6 col-md-6 col-lg-4 col-xl-3">
                {/*Maker*/}
                <Input
                    label={t('admin:customer_zone_auto.maker')}
                    input={{
                      name: 'maker',
                      defaultValue: customerZone.data.maker,
                      required: true,
                      onChange: e => setMaker(e.target.value),
                    }}
                />
              </div>
              <div className="col-12 col-sm-6 col-md-6 col-lg-4 col-xl-3">
                {/*Model*/}
                <Input
                    label={t('admin:customer_zone_auto.model')}
                    input={{
                      name: 'model',
                      defaultValue: customerZone.data.model,
                      required: true,
                      onChange: e => setModel(e.target.value),
                    }}
                />
              </div>
              <div className="col-12 col-sm-6 col-md-6 col-lg-4 col-xl-3">
                {/*Year*/}
                <Select
                    label={t('admin:customer_zone_auto.year')}
                    select={{
                      isSearchable: false,
                      options: Cars.years().map((year) => ({
                        value: year,
                        label: year,
                      })),
                      value: year,
                      components: {
                        DropdownIndicator: DownArrowIndicator,
                      },
                      styles: SelectStylesHideIndicatorSeparator,
                      onChange: (option) => setYear(option),
                    }}
                    required={true}
                />
              </div>
              <div className="col-12 col-sm-6 col-md-6 col-lg-4 col-xl-3">
                {/*Lot*/}
                <Input
                    label={t('admin:customer_zone_auto.lot')}
                    input={{
                      name: 'lot',
                      defaultValue: customerZone.data.lot,
                    }}
                />
              </div>
              <div className="col-12 col-sm-6 col-md-6 col-lg-4 col-xl-3">
                {/*Purchase city*/}
                <Select
                    label={t('admin:customer_zone_auto.purchase_city')}
                    select={{
                      options: cities,
                      value: purchaseCity,
                      components: {
                        DropdownIndicator: DownArrowIndicator,
                      },
                      styles: SelectStylesHideIndicatorSeparator,
                      onChange: option => setPurchaseCity(option),
                    }}
                />
              </div>
              {/*Shipping company*/}
              <div className="col-12 col-sm-6 col-md-6 col-lg-4 col-xl-3">
                <Input
                    label={t('admin:customer_zone_auto.shipping_company')}
                    input={{
                      name: 'shipping-company',
                      defaultValue: customerZone.data.shippingCompany,
                    }}
                />
              </div>
              <div className="col-12 col-sm-6 col-md-6 col-lg-4 col-xl-3">
                {/*Point of loading*/}
                <Select
                    label={t('admin:customer_zone_auto.departure_port')}
                    select={{
                      options: departurePorts,
                      value: departurePort,
                      components: {
                        DropdownIndicator: DownArrowIndicator,
                      },
                      styles: SelectStylesHideIndicatorSeparator,
                      onChange: option => setDeparturePort(option),
                    }}
                    required={true}
                />
              </div>
              <div className="col-12 col-sm-6 col-md-6 col-lg-4 col-xl-3">
                {/*Auto price*/}
                <Input
                    label={t('admin:customer_zone_auto.auto_price')}
                    input={{
                      type: 'number',
                      name: 'auto-price',
                      defaultValue: customerZone.data.autoPrice,
                      required: true,
                    }}
                />
              </div>
              <div className="col-12 col-sm-6 col-md-6 col-lg-4 col-xl-3">
                {/*Link to the lot*/}
                <Input
                    label={t('admin:customer_zone_auto.lot_link')}
                    input={{
                      type: 'url',
                      name: 'lot-link',
                      defaultValue: customerZone.data.lotLink,
                    }}
                />
              </div>
            </div>
          </AccordionDetails>
        </Accordion>

        <Accordion defaultExpanded={true}>
          <AccordionSummary>
            {t('admin:customer_zone_auto.form.info_about_vehicle')}
          </AccordionSummary>
          <AccordionDetails>
            <div className="row d-flex flex-grow-1">
              {/*Dispatch*/}
              <div className="col-12 col-sm-6 col-md-6 col-lg-4 col-xl-3">
                <RadioGroup
                    className="row flex-row"
                    value={dispatch}
                    onChange={e => setDispatch(e.target.value)}
                >
                  <div className="item col-12 field-label">
                    {t('admin:customer_zone_auto.dispatch')}
                  </div>
                  <div className="item col-6">
                    {/*Yes*/}
                    <Radio
                        label={{
                          label: t('admin:customer_zone_auto.yes'),
                        }}
                        radio={{
                          name: 'dispatch',
                          value: '1',
                        }}
                        bottomOffset={0}
                    />
                  </div>
                  <div className="item col-6">
                    {/*No*/}
                    <Radio
                        label={{
                          label: t('admin:customer_zone_auto.no'),
                        }}
                        radio={{
                          name: 'dispatch',
                          value: '0',
                        }}
                        bottomOffset={0}
                    />
                  </div>
                </RadioGroup>
              </div>
              {/*Company of Delivering*/}
              <div className="col-12 col-sm-6 col-md-6 col-lg-4 col-xl-3">
                <Input
                    label={t('admin:customer_zone_auto.delivery_company')}
                    input={{
                      name: 'delivery-company',
                      defaultValue: customerZone.data.deliveryCompany,
                    }}
                />
              </div>
              {/*Date of Dispatch*/}
              <div className="col-12 col-sm-6 col-md-6 col-lg-4 col-xl-3">
                <KeyboardDatePicker
                    label={t('admin:customer_zone_auto.dispatch_date')}
                    KeyboardDatePicker={{
                      value: dispatchDate,
                      onChange: date => setDispatchDate(date),
                    }}
                />
              </div>
              {/*Price for Delivery*/}
              <div className="col-12 col-sm-6 col-md-6 col-lg-4 col-xl-3">
                <Input
                    label={t('admin:customer_zone_auto.delivery_price')}
                    input={{
                      type: 'number',
                      name: 'delivery-price',
                      defaultValue: customerZone.data.deliveryPrice,
                    }}
                />
              </div>
              {/*Self Delivery*/}
              <div className="col-12 col-sm-6 col-md-6 col-lg-4 col-xl-3">
                <RadioGroup
                    className="row flex-row"
                    value={selfDelivery}
                    onChange={e => setSelfDelivery(e.target.value)}
                >
                  <div className="item col-12 field-label">
                    {t('admin:customer_zone_auto.self_delivery')}
                  </div>
                  <div className="item col-6">
                    {/*Yes*/}
                    <Radio
                        label={{
                          label: t('admin:customer_zone_auto.yes'),
                        }}
                        radio={{
                          name: 'self-delivery',
                          value: '1',
                        }}
                        bottomOffset={0}
                    />
                  </div>
                  <div className="item col-6">
                    {/*No*/}
                    <Radio
                        label={{
                          label: t('admin:customer_zone_auto.no'),
                        }}
                        radio={{
                          name: 'self-delivery',
                          value: '0',
                        }}
                        bottomOffset={0}
                    />
                  </div>
                </RadioGroup>
              </div>
              {/*Promised to be Delivery to warehouse*/}
              <div className="col-12 col-sm-6 col-md-6 col-lg-4 col-xl-3">
                <Input
                    label={t('admin:customer_zone_auto.warehouse')}
                    input={{
                      name: 'warehouse',
                      defaultValue: customerZone.data.warehouse,
                    }}
                />
              </div>
              {/*Arrival date to Warehouse*/}
              <div className="col-12 col-sm-6 col-md-6 col-lg-4 col-xl-3">
                <KeyboardDatePicker
                    label={t('admin:customer_zone_auto.warehouse_arrival_date')}
                    KeyboardDatePicker={{
                      value: warehouseArrivalDate,
                      onChange: date => setWarehouseArrivalDate(date),
                    }}
                />
              </div>
              {/*Notes for Delivery*/}
              <div className="col-12 col-sm-6 col-md-6 col-lg-4 col-xl-3">
                <Textarea
                    label={t('admin:customer_zone_auto.delivery_notes')}
                    textarea={{
                      name: 'delivery-notes',
                      defaultValue: customerZone.data.deliveryNotes,
                      rowsMin: 3,
                      rowsMax: 3,
                    }}
                />
              </div>
            </div>
          </AccordionDetails>
        </Accordion>

        <Accordion defaultExpanded={true}>
          <AccordionSummary>
            {t('admin:customer_zone_auto.form.info_about_vehicle')}
          </AccordionSummary>
          <AccordionDetails>
            <div className="row d-flex flex-grow-1">
              <div className="col-12 col-sm-6 col-md-6 col-lg-4 col-xl-3">
                {/*Odometer*/}
                <Input
                    label={t('admin:customer_zone_auto.odometer')}
                    input={{
                      type: 'number',
                      name: 'odometer',
                      defaultValue: customerZone.data.odometer,
                    }}
                />
              </div>
              <div className="col-12 col-sm-6 col-md-6 col-lg-4 col-xl-3">
                {/*Condition*/}
                <Input
                    label={t('admin:customer_zone_auto.condition')}
                    input={{
                      name: 'condition',
                      defaultValue: customerZone.data.condition,
                    }}
                />
              </div>
              <div className="col-12 col-sm-6 col-md-6 col-lg-4 col-xl-3">
                {/*Keys*/}
                <Select
                    label={t('admin:customer_zone_auto.keys')}
                    select={{
                      isSearchable: false,
                      options: noYesOptions,
                      value: keys,
                      components: {
                        DropdownIndicator: DownArrowIndicator,
                      },
                      styles: SelectStylesHideIndicatorSeparator,
                      onChange: option => setKeys(option),
                    }}
                />
              </div>
              <div className="col-12 col-sm-6 col-md-6 col-lg-4 col-xl-3">
                {/*Title status*/}
                <Select
                    label={t('admin:customer_zone_auto.title_status')}
                    select={{
                      isSearchable: false,
                      options: titleStatusOptions,
                      value: titleStatus,
                      components: {
                        DropdownIndicator: DownArrowIndicator,
                      },
                      styles: SelectStylesHideIndicatorSeparator,
                      onChange: option => setTitleStatus(option),
                    }}
                />
              </div>
              <div className="col-12 col-sm-6 col-md-6 col-lg-4 col-xl-3">
                {/*Notes for Delivery*/}
                <Textarea
                    label={t('admin:customer_zone_auto.auction_contact')}
                    textarea={{
                      name: 'auction-contact',
                      defaultValue: customerZone.data.auctionContact,
                      rowsMin: 3,
                      rowsMax: 3,
                    }}
                />
              </div>
              <div className="col-12 col-sm-6 col-md-6 col-lg-4 col-xl-3">
                {/*Notes for Title*/}
                <Textarea
                    label={t('admin:customer_zone_auto.title_notes')}
                    textarea={{
                      name: 'title-notes',
                      defaultValue: customerZone.data.titleNotes,
                      rowsMin: 3,
                      rowsMax: 3,
                    }}
                />
              </div>
              {/*Title PDF*/}
              <div className="col-12 col-sm-6 col-md-6 col-lg-4 col-xl-3">
                <Upload
                    label={t('admin:customer_zone_auto.title')}
                    files={title}
                    placeholder={t('admin:customer_zone_auto.title_placeholder')}
                    accept={['application/pdf']}
                    maxFiles={1}
                    onLoad={(files) => setTitle(files)}
                    onDelete={(files) => setTitle(files)}
                />
              </div>
              {/*Title received date*/}
              <div className="col-12 col-sm-6 col-md-6 col-lg-4 col-xl-3">
                <KeyboardDatePicker
                    label={t('admin:customer_zone_auto.title_received_date')}
                    KeyboardDatePicker={{
                      value: titleReceivedDate,
                      onChange: date => setTitleReceivedDate(date),
                    }}
                />
              </div>
            </div>
          </AccordionDetails>
        </Accordion>

        <Accordion defaultExpanded={true}>
          <AccordionSummary>
            {t('admin:customer_zone_auto.form.info_about_vehicle')}
          </AccordionSummary>
          <AccordionDetails>
            <div className="row d-flex flex-grow-1">
              <div className="col-12 col-sm-6 col-md-6 col-lg-4 col-xl-3">
                {/*Invoice*/}
                <FormViewValue
                    label={t('admin:customer_zone_auto.invoices')}
                    value={invoices && <ul>
                      {invoices.map(invoice => <li key={invoice.id}>
                        {`${maker} ${model} ${year.label} - ${invoice.data.invoiceNumber}`}
                      </li>)}
                    </ul>}
                />
              </div>
              <div className="col-12 col-sm-6 col-md-6 col-lg-4 col-xl-3">
                {/*Payment date from Client*/}
                <KeyboardDatePicker
                    label={t('admin:customer_zone_auto.client_payment_date')}
                    KeyboardDatePicker={{
                      value: clientPaymentDate,
                      onChange: date => setClientPaymentDate(date),
                    }}
                />
              </div>
              <div className="col-12 col-sm-6 col-md-6 col-lg-4 col-xl-3">
                {/*Payment date to Auction*/}
                <KeyboardDatePicker
                    label={t('admin:customer_zone_auto.auction_payment_date')}
                    KeyboardDatePicker={{
                      value: auctionPaymentDate,
                      onChange: date => setAuctionPaymentDate(date),
                    }}
                />
              </div>
              <div className="col-12 col-sm-6 col-md-6 col-lg-4 col-xl-3">
                {/*Destination port*/}
                <Select
                    label={t('admin:customer_zone_auto.destination_port')}
                    select={{
                      isSearchable: false,
                      options: destinationPortsOptions,
                      value: destinationPort,
                      components: {
                        DropdownIndicator: DownArrowIndicator,
                      },
                      styles: SelectStylesHideIndicatorSeparator,
                      onChange: option => setDestinationPort(option),
                    }}
                />
              </div>
              <div className="col-12 col-sm-6 col-md-6 col-lg-4 col-xl-3">
                {/*Notes*/}
                <Textarea
                    label={t('admin:customer_zone_auto.notes')}
                    textarea={{
                      name: 'notes',
                      defaultValue: customerZone.data.notes,
                      rowsMin: 3,
                      rowsMax: 3,
                    }}
                />
              </div>
            </div>
          </AccordionDetails>
        </Accordion>

        <Accordion defaultExpanded={true}>
          <AccordionSummary>
            {t('admin:customer_zone_auto.form.loading_details')}
          </AccordionSummary>
          <AccordionDetails>
            <div className="row d-flex flex-grow-1">
             <div className="col-12 col-sm-6 col-md-6 col-lg-4 col-xl-3">
                {/*Date Of Loading*/}
               <KeyboardDatePicker
                    label={t('admin:customer_zone_auto.date_of_loading')}
                    KeyboardDatePicker={{
                      value: dateOfLoading,
                      onChange: date => setDateOfLoading(date),
                    }}
                />
              </div>
              <div className="col-12 col-sm-6 col-md-6 col-lg-4 col-xl-3">
                {/*Sail Date*/}
                <KeyboardDatePicker
                    label={t('admin:customer_zone_auto.sail_date')}
                    KeyboardDatePicker={{
                      value: sailDate,
                      onChange: date => setSailDate(date),
                    }}
                />
              </div>
              <div className="col-12 col-sm-6 col-md-6 col-lg-4 col-xl-3">
                {/*Container number*/}
                <Input
                    label={t('admin:customer_zone_auto.container_number')}
                    input={{
                      name: 'container-number',
                      defaultValue: customerZone.data.containerNumber,
                    }}
                />
              </div>
              <div className="col-12 col-sm-6 col-md-6 col-lg-4 col-xl-3">
                {/*Booking Number*/}
                <Input
                    label={t('admin:customer_zone_auto.booking_number')}
                    input={{
                      name: 'booking-number',
                      defaultValue: customerZone.data.bookingNumber,
                    }}
                />
              </div>
              <div className="col-12 col-sm-6 col-md-6 col-lg-4 col-xl-3">
                {/*ETA*/}
                <KeyboardDatePicker
                    label={t('admin:customer_zone_auto.eta')}
                    KeyboardDatePicker={{
                      value: eta,
                      onChange: date => setEta(date),
                    }}
                />
              </div>
           {/*   <div className="col-12 col-sm-6 col-md-6 col-lg-4 col-xl-3">*/}
                {/*Final destination arrival date*/}
               {/* <KeyboardDatePicker
                    label={t('admin:customer_zone_auto.final_destination_arrival_date')}
                    KeyboardDatePicker={{
                      value: finalDestinationArrivalDate,
                      onChange: date => setFinalDestinationArrivalDate(date),
                    }}
                />
              </div>*/}
            </div>
          </AccordionDetails>
        </Accordion>

        {/*<Accordion defaultExpanded={true}>
            <AccordionSummary>
              {t('admin:customer_zone_auto.wagon_railway')}
            </AccordionSummary>
            <AccordionDetails>
              <div className="row d-flex flex-grow-1">
                <div className="col-12 col-sm-6 col-md-6 col-lg-4 col-xl-3">
                  Wagon number
                  <Input
                      label={t('admin:customer_zone_auto.wagon_number')}
                      input={{
                        name: 'wagon-number',
                        defaultValue: customerZone.data.wagonNumber
                      }}
                  />
                </div>
                <div className="col-12 col-sm-6 col-md-6 col-lg-4 col-xl-3">
                  Wagon loading place
                  <Input
                      label={t('admin:customer_zone_auto.wagon_loading_place')}
                      input={{
                        name: 'wagon-loading-place',
                        defaultValue: customerZone.data.wagonLoadingPlace
                      }}
                  />
                </div>
                <div className="col-12 col-sm-6 col-md-6 col-lg-4 col-xl-3">
                  Destination
                  <Input
                      label={t('admin:customer_zone_auto.wagon_destination')}
                      input={{
                        name: 'wagon-destination',
                        defaultValue: customerZone.data.wagonDestination
                      }}
                  />
                </div>
                <div className="col-12 col-sm-6 col-md-6 col-lg-4 col-xl-3">
                  Planned wagon lading
                  <KeyboardDatePicker
                      label={t('admin:customer_zone_auto.planned_wagon_lading')}
                      KeyboardDatePicker={{
                        value: plannedWagonLading,
                        onChange: date => setPlannedWagonLading(date)
                      }}
                  />
                </div>
                <div className="col-12 col-sm-6 col-md-6 col-lg-4 col-xl-3">
                  Wagon loading date
                  <KeyboardDatePicker
                      label={t('admin:customer_zone_auto.wagon_loading_date')}
                      KeyboardDatePicker={{
                        value: wagonLoadingDate,
                        onChange: date => setWagonLoadingDate(date)
                      }}
                  />
                </div>
                <div className="col-12 col-sm-6 col-md-6 col-lg-4 col-xl-3">
                  Wagon estimate arrival date
                  <KeyboardDatePicker
                      label={t('admin:customer_zone_auto.wagon_estimate_arrival_date')}
                      KeyboardDatePicker={{
                        value: wagonEstimateArrivalDate,
                        onChange: date => setWagonEstimateArrivalDate(date)
                      }}
                  />
                </div>
                <div className="col-12 col-sm-6 col-md-6 col-lg-4 col-xl-3">
                  Wagon arrival date
                  <KeyboardDatePicker
                      label={t('admin:customer_zone_auto.wagon_arrival_date')}
                      KeyboardDatePicker={{
                        value: wagonArrivalDate,
                        onChange: date => setWagonArrivalDate(date)
                      }}
                  />
                </div>
                <div className="col-12 col-sm-6 col-md-6 col-lg-4 col-xl-3">
                  Wagon notes
                  <Textarea
                      label={t('admin:customer_zone_auto.wagon_notes')}
                      textarea={{
                        name: 'wagon-notes',
                        defaultValue: customerZone.data.wagonNotes,
                        rowsMin: 3,
                        rowsMax: 3
                      }}
                  />
                </div>
                <div className="col-12 col-sm-6 col-md-6 col-lg-4 col-xl-3">
                  Wagon notes for yourself
                  <Textarea
                      label={t('admin:customer_zone_auto.wagon_notes_for_yourself')}
                      textarea={{
                        name: 'wagon-notes-for-yourself',
                        defaultValue: customerZone.data.wagonNotesForYourself,
                        rowsMin: 3,
                        rowsMax: 3
                      }}
                  />
                </div>
              </div>
            </AccordionDetails>
          </Accordion>*/}

        <Accordion defaultExpanded={true}>
          <AccordionSummary>
            {t('admin:customer_zone_auto.section_tracking')}
          </AccordionSummary>
          <AccordionDetails>
            <div className="row d-flex flex-grow-1">
              <div className="col-12 col-sm-6 col-md-6 col-lg-4 col-xl-3">
                {/*Tracking*/}
                <Select
                    label={t('admin:customer_zone_auto.tracking')}
                    select={{
                      isSearchable: false,
                      options: trackingOptions,
                      value: tracking,
                      components: {
                        DropdownIndicator: DownArrowIndicator,
                      },
                      styles: SelectStylesHideIndicatorSeparator,
                      onChange: option => setTracking(option),
                    }}
                    required={true}
                />
              </div>
              <div className="col-12 col-sm-6 col-md-6 col-lg-4 col-xl-3">
                {/*Final destination country*/}
                <Input
                    label={t('admin:customer_zone_auto.final_destination_country')}
                    input={{
                      name: 'final-destination-country',
                      defaultValue: customerZone.data.finalDestinationCountry,
                      required: true,
                    }}
                />
              </div>
              <div className="col-12 col-sm-6 col-md-6 col-lg-4 col-xl-3">
                {/*Final destination city*/}
                <Input
                    label={t('admin:customer_zone_auto.final_destination_city')}
                    input={{
                      name: 'final-destination-city',
                      defaultValue: customerZone.data.finalDestinationCity,
                      required: true,
                    }}
                />
              </div>
              <div className="col-12 col-sm-6 col-md-6 col-lg-4 col-xl-3">
                {/*Consolidation*/}
                <Select
                    label={t('admin:customer_zone_auto.consolidation')}
                    select={{
                      isSearchable: false,
                      options: consolidationOptions,
                      value: consolidation,
                      components: {
                        DropdownIndicator: DownArrowIndicator,
                      },
                      styles: SelectStylesHideIndicatorSeparator,
                      onChange: option => setConsolidation(option),
                    }}
                    required={true}
                />
              </div>
              <div className="col-12 col-sm-6 col-md-6 col-lg-4 col-xl-3">
                {/*Shipping line*/}
                <Input
                    label={t('admin:customer_zone_auto.shipping_line')}
                    input={{
                      name: 'shipping-line',
                      defaultValue: customerZone.data.shippingLine,
                    }}
                />
              </div>
              <div className="col-12 col-sm-6 col-md-6 col-lg-4 col-xl-3">
                {/*Tracking url*/}
                <Input
                    label={t('admin:customer_zone_auto.tracking_url')}
                    input={{
                      name: 'tracking-url',
                      defaultValue: customerZone.data.trackingUrl,
                    }}
                />
              </div>
            </div>
          </AccordionDetails>
        </Accordion>

        <Accordion defaultExpanded={true}>
          <AccordionSummary>
            {t('admin:customer_zone_auto.section_photos')}
          </AccordionSummary>
          <AccordionDetails>
            <div className="row d-flex flex-grow-1">
              <div className="col-12 col-sm-6 col-md-6 col-lg-4 col-xl-3">
                {/*Photos Auction*/}
                <Upload
                    label={t('admin:customer_zone_auto.photos_auction')}
                    files={photosAuction}
                    placeholder={t('admin:customer_zone_auto.photos_placeholder')}
                    accept={['image/png', 'image/jpeg']}
                    maxFiles={-1}
                    multiple={true}
                    onLoad={(files) => setPhotosAuction(files)}
                    onDelete={(files) => setPhotosAuction(files)}
                />
              </div>
              <div className="col-12 col-sm-6 col-md-6 col-lg-4 col-xl-3">
                {/*Photos Before Loading*/}
                <Upload
                    label={t('admin:customer_zone_auto.photos_before_loading')}
                    files={photosBeforeLoading}
                    placeholder={t('admin:customer_zone_auto.photos_placeholder')}
                    accept={['image/png', 'image/jpeg']}
                    maxFiles={-1}
                    multiple={true}
                    onLoad={(files) => setPhotosBeforeLoading(files)}
                    onDelete={(files) => setPhotosBeforeLoading(files)}
                />
              </div>
              <div className="col-12 col-sm-6 col-md-6 col-lg-4 col-xl-3">
                {/*Photos After Uploading*/}
                <Upload
                    label={t('admin:customer_zone_auto.photos_after_uploading')}
                    files={photosAfterUploading}
                    placeholder={t('admin:customer_zone_auto.photos_placeholder')}
                    accept={['image/png', 'image/jpeg']}
                    maxFiles={-1}
                    multiple={true}
                    onLoad={(files) => setPhotosAfterUploading(files)}
                    onDelete={(files) => setPhotosAfterUploading(files)}
                />
              </div>
              <div className="col-12 col-sm-6 col-md-6 col-lg-4 col-xl-3">
                {/*Photos from the driver*/}
                <Upload
                    label={t('admin:customer_zone_auto.photos_from_driver')}
                    files={photosFromDriver}
                    placeholder={t('admin:customer_zone_auto.photos_placeholder')}
                    accept={['image/png', 'image/jpeg']}
                    maxFiles={-1}
                    multiple={true}
                    onLoad={(files) => setPhotosFromDriver(files)}
                    onDelete={(files) => setPhotosFromDriver(files)}
                />
              </div>
            </div>
          </AccordionDetails>
        </Accordion>

        <Accordion defaultExpanded={true}>
          <AccordionSummary>
            {t('admin:customer_zone_auto.section_documents')}
          </AccordionSummary>
          <AccordionDetails>
            <div className="row d-flex flex-grow-1">
              <div className="col-12 col-sm-6 col-md-6 col-lg-4 col-xl-3">
                {/*Dock receipt*/}
                <Upload
                    label={t('admin:customer_zone_auto.dock_receipt')}
                    placeholder={t('admin:customer_zone_auto.dock_receipt_placeholder')}
                    accept={['application/pdf']}
                    files={dockReceipt}
                    maxFiles={1}
                    onLoad={(files) => setDockReceipt(files)}
                    onDelete={(files) => setDockReceipt(files)}
                />
              </div>
              <div className="col-12 col-sm-6 col-md-6 col-lg-4 col-xl-3">
                {/*Bill of lading*/}
                <Upload
                    label={t('admin:customer_zone_auto.bill_of_lading')}
                    files={billOfLading}
                    placeholder={t('admin:customer_zone_auto.bill_of_lading_placeholder')}
                    accept={['application/pdf']}
                    maxFiles={1}
                    onLoad={(files) => setBillOfLading(files)}
                    onDelete={(files) => setBillOfLading(files)}
                />
              </div>
              <div className="col-12 col-sm-6 col-md-6 col-lg-4 col-xl-3">
                {/*Bill of sale*/}
                <Upload
                    label={t('admin:customer_zone_auto.bill_of_sale')}
                    files={billOfSale}
                    placeholder={t('admin:customer_zone_auto.bill_of_sale_placeholder')}
                    accept={['application/pdf']}
                    maxFiles={1}
                    onLoad={(files) => setBillOfSale(files)}
                    onDelete={(files) => setBillOfSale(files)}
                />
              </div>
              <div className="col-12 col-sm-6 col-md-6 col-lg-4 col-xl-3">
                {/*Carfax/Autocheck*/}
                <Upload
                    label={t('admin:customer_zone_auto.carfax_autocheck')}
                    files={carfaxAutocheck}
                    placeholder={t('admin:customer_zone_auto.carfax_autocheck_placeholder')}
                    accept={['application/pdf']}
                    multiple={false}
                    maxFiles={1}
                    onLoad={(files) => setCarfaxAutocheck(files)}
                    onDelete={(files) => setCarfaxAutocheck(files)}
                />
              </div>
              <div className="col-12 col-sm-6 col-md-6 col-lg-4 col-xl-3">
                {/*Conosament*/}
                <Upload
                    label={t('admin:customer_zone_auto.conosament')}
                    files={conosament}
                    placeholder={t('admin:customer_zone_auto.conosament_placeholder')}
                    accept={['application/pdf']}
                    multiple={false}
                    maxFiles={1}
                    onLoad={(files) => setConosament(files)}
                    onDelete={(files) => setConosament(files)}
                />
              </div>
            </div>
          </AccordionDetails>
        </Accordion>
      </form>

      {/*Share*/}
      {customerZone && customerZone.id > 0 && <Share
          className="mb-3"
          share={customerZone.id}
          module={'customer-zone-auto'}
      />}

      <BottomToolbar
          right={<>
            <div className="d-flex justify-content-lg-end justify-content-start train-buttons">
              <button
                  form="customer-zone-form-component"
                  className="btn btn-save"
                  disabled={saveProcess || inApiUpdate}
              >
                {t('buttons.save')}
              </button>
              <button
                  type="button"
                  className="btn btn-reload"
                  onClick={() => reset()}
                  disabled={saveProcess || inApiUpdate}
              >
                {t('buttons.reset')}
              </button>
              <Link
                  to={'/admin/customer-zone/auto'}
                  className="btn btn-close"
              >
                {t('buttons.close')}
              </Link>
            </div>
            <div className="d-flex justify-content-end">
              <Notify
                  type="save-form"
                  hide={!saveNotify}
              />
            </div>
          </>}
      />
    </div>}
  </>);
};

export default CustomerZoneAutoForm;
