import React, {FC, useContext, useEffect, useState} from 'react';
import {useTranslation} from 'react-i18next';
import './CustomerZoneAutoForm.scss';
import axios from 'axios';
import {useHistory, useParams} from 'react-router-dom';
import Config from '../../Providers/Config';
import ViewValue from '../../Core/Form/ViewValue/ViewValue';
import ViewFiles from '../../Core/Form/ViewFiles/ViewFiles';
import Head from '../Head/Head';
import ICustomerZoneAuto from '../../Interfaces/ICustomerZoneAuto';
import Cars from '../../Providers/Cars';
import CustomerZoneAutoProvider from '../../Providers/CustomerZoneAuto';
import User from '../../Providers/User';
import Auction from '../../Providers/Auction';
import IDestinationPort from '../../Interfaces/IDestinationPort';
import DestinationPortProvider from '../../Providers/DestinationPort';
import ISelectOption from '../../Interfaces/ISelectOption';
import DeparturePortProvider from '../../Providers/DeparturePort';
import AdminContext from '../AdminContext';
import IInvoice from '../../Interfaces/IInvoice';
import InvoiceProvider from '../../Providers/Invoice';

const customerZoneAutoProvider = new CustomerZoneAutoProvider();
const userProvider = new User();
const auctionCityProvider = new Auction.City();
const destinationPortProvider = new DestinationPortProvider();
const departurePortProvider = new DeparturePortProvider();
const invoiceProvider = new InvoiceProvider();

const CustomerZoneAutoForm: FC = () => {
  const {t} = useTranslation();
  const context = useContext(AdminContext);
  const user = context.auth.user;
  const auctionOptions = Cars.auctions().map(item => ({
    value: item,
    label: t(`admin:auction.${item}`),
  }));
  const orderTypeOptions = [
    {
      value: 'auto',
      label: t('admin:order_type.auto'),
    },
  ];
  const noYesOptions = [
    {
      value: '0',
      label: t('admin:customer_zone_auto.no'),
    },
    {
      value: '1',
      label: t('admin:customer_zone_auto.yes'),
    },
  ];
  const titleStatusOptions = Cars.titleStatuses.map(item => ({
    value: item,
    label: t(`admin:title_status.${item}`),
  }));
  const [destinationPortsOptions, setDestinationPortsOptions] = useState<ISelectOption[]>();
  const trackingOptions = [
    {
      value: 'require-trucking',
      label: 'Require trucking',
    },
    {
      value: 'arrange-trucking-myself',
      label: 'Will arrange trucking by myself',
    },
  ];
  const consolidationOptions = [
    {
      value: 'place_in_container',
      label: t('admin:customer_zone_auto.place_in_container'),
    },
    {
      value: 'own_container',
      label: t('admin:customer_zone_auto.own_container'),
    },
  ];
  const history = useHistory();
  const id = parseInt(useParams().id);
  const [customerZone, setCustomerZone] = useState<ICustomerZoneAuto>(null);
  const [dealer, setDealer] = useState('');
  const [buyerId, setBuyerId] = useState('');
  const [purchaseCity, setPurchaseCity] = useState('');
  const [departurePort, setDeparturePort] = useState('');
  const [invoices, setInvoices] = useState<IInvoice[]>();

  // Search Dealer based on input
  const dealerLoad = async id => {
    if (!(id > 0))
      return null;

    const valueResponse = await userProvider.search({group: User.ROLE_GROUP_DEALER, id});
    const item = valueResponse?.[0];

    return item ? `${item.company} ${item.firstName} ${item.lastName} (ID: ${item.id})` : null;
  };

  // Search Buyer ID based on input
  const buyerIdLoad = async id => {
    if (!(id > 0))
      return null;

    const valueResponse = (await axios.get(Config.api(`/buyer-id/search?id=${id}`))).data;
    const item = valueResponse.success ? valueResponse.data[0] : null;

    return item ? `${item.login} - ${t(`admin:auction.${item.auction}`)}` : null;
  };

  // Search auction cities based on input
  const purchaseCityLoad = async id => {
    if (!(id > 0))
      return null;

    const valueResponse = await auctionCityProvider.search({id});
    const item = valueResponse?.[0];

    return item ? `${item.city} – ${item.port}` : null;
  };

  // Get departure port
  const departurePortLoad = async id => {
    if (!(id > 0))
      return null;

    const item = await departurePortProvider.get(id);

    return item ? `${item.displayCity}${item.displayState ? `, ${item.displayState}` : ''}` : null;
  };

  // Search invoices based on input
  const invoiceLoad = async invoices => {
    if (!Array.isArray(invoices) || invoices.length === 0)
      return null;

    return ((await invoiceProvider.list({ids: invoices})) as any)?.data ?? null;
  };

  useEffect(() => {
    // Load form data
    (async () => {
      if (!(id > 0)) {
        history.push('/admin/customer-zone/auto');

        return;
      }

      const customerZone = await customerZoneAutoProvider.get(id);

      if (!customerZone) {
        history.push('/admin/customer-zone/auto');

        return;
      }

      // Destination ports
      setDestinationPortsOptions((((await destinationPortProvider.list()) ?? []) as IDestinationPort[]).map(
          item => ({
            value: item.id,
            label: `${item.displayCity}, ${item.displayCountry}`,
          })));

      setDealer(await dealerLoad(customerZone.data.dealer));
      setBuyerId(await buyerIdLoad(customerZone.buyerId));
      setPurchaseCity(await purchaseCityLoad(customerZone.data.purchaseCity));
      setDeparturePort(await departurePortLoad(customerZone.data.departurePort));
      setInvoices(await invoiceLoad(customerZone.invoices));

      setCustomerZone(customerZone);
    })();
    // eslint-disable-next-line
  }, []);

  return (
      <>
        {customerZone && <div
            id="customer-zone-auto-component"
        >
          <Head
              head={t('admin:customer_zone_auto.form.head')}
              subhead={'VIN - Auto'}
          />

          <form id="customer-zone-view-component">
            <div className="row">
              <div className="col-12 col-sm-6 col-md-6 col-lg-4 col-xl-3">
                {/*Dealer*/}
                <ViewValue
                    label={t('admin:customer_zone_auto.dealer')}
                    value={dealer}
                />
              </div>
              <div className="col-12 col-sm-6 col-md-6 col-lg-4 col-xl-3">
                {/*Buyer ID*/}
                <ViewValue
                    label={t('admin:customer_zone_auto.buyer_id')}
                    value={buyerId}
                />
              </div>
              <div className="col-12 col-sm-6 col-md-6 col-lg-4 col-xl-3">
                {/*Auto purchase date*/}
                <ViewValue
                    label={t('admin:customer_zone_auto.auto_purchase_date')}
                    value={customerZone.data.autoPurchaseDate?.format(Config.DATE_FORMAT)}
                />
              </div>
              <div className="col-12 col-sm-6 col-md-6 col-lg-4 col-xl-3">
                {/*Auction*/}
                <ViewValue
                    label={t('admin:customer_zone_auto.auction')}
                    value={auctionOptions.find(item => item.value === customerZone.data.auction)?.label}
                />
              </div>
              <div className="col-12 col-sm-6 col-md-6 col-lg-4 col-xl-3">
                {/*Order type*/}
                <ViewValue
                    label={t('admin:customer_zone_auto.order_type')}
                    value={orderTypeOptions.find(item => item.value === customerZone.data.orderType)?.label}
                />
              </div>
            </div>

            <div className="row">
              <div className="col-12 col-sm-6 col-md-6 col-lg-4 col-xl-3">
                {/*Vin*/}
                <ViewValue
                    label={t('admin:customer_zone_auto.vin')}
                    value={customerZone.data.vin}
                />
              </div>
              <div className="col-12 col-sm-6 col-md-6 col-lg-4 col-xl-3">
                {/*Maker*/}
                <ViewValue
                    label={t('admin:customer_zone_auto.maker')}
                    value={customerZone.data.maker}
                />
              </div>
              <div className="col-12 col-sm-6 col-md-6 col-lg-4 col-xl-3">
                {/*Model*/}
                <ViewValue
                    label={t('admin:customer_zone_auto.model')}
                    value={customerZone.data.model}
                />
              </div>
              <div className="col-12 col-sm-6 col-md-6 col-lg-4 col-xl-3">
                {/*Year*/}
                <ViewValue
                    label={t('admin:customer_zone_auto.year')}
                    value={customerZone.data.year}
                />
              </div>
              <div className="col-12 col-sm-6 col-md-6 col-lg-4 col-xl-3">
                {/*Lot*/}
                <ViewValue
                    label={t('admin:customer_zone_auto.lot')}
                    value={customerZone.data.lot}
                />
              </div>
              <div className="col-12 col-sm-6 col-md-6 col-lg-4 col-xl-3">
                {/*Purchase city*/}
                <ViewValue
                    label={t('admin:customer_zone_auto.purchase_city')}
                    value={purchaseCity}
                />
              </div>
              {/*Shipping company*/}
              {![User.ROLE_GROUP_DEALER].includes(user.roleGroup)
              && <div className="col-12 col-sm-6 col-md-6 col-lg-4 col-xl-3">
                <ViewValue
                    label={t('admin:customer_zone_auto.shipping_company')}
                    value={customerZone.data.shippingCompany}
                />
              </div>}
              <div className="col-12 col-sm-6 col-md-6 col-lg-4 col-xl-3">
                {/*Point of loading*/}
                <ViewValue
                    label={t('admin:customer_zone_auto.departure_port')}
                    value={departurePort}
                />
              </div>
              <div className="col-12 col-sm-6 col-md-6 col-lg-4 col-xl-3">
                {/*Auto price*/}
                <ViewValue
                    label={t('admin:customer_zone_auto.auto_price')}
                    value={customerZone.data.autoPrice}
                />
              </div>
              <div className="col-12 col-sm-6 col-md-6 col-lg-4 col-xl-3">
                {/*Link to the lot*/}
                <ViewValue
                    label={t('admin:customer_zone_auto.lot_link')}
                    value={customerZone.data.lotLink}
                />
              </div>
            </div>

            <div className="row">
              {/*Dispatch*/}
              {![User.ROLE_GROUP_DEALER].includes(user.roleGroup)
              && <div className="col-12 col-sm-6 col-md-6 col-lg-4 col-xl-3">
                <ViewValue
                    label={t('admin:customer_zone_auto.dispatch')}
                    value={noYesOptions.find(item => item.value === customerZone.data.dispatch)?.label}
                />
              </div>}
              {/*Company of Delivering*/}
              {![User.ROLE_GROUP_DEALER].includes(user.roleGroup)
              && <div className="col-12 col-sm-6 col-md-6 col-lg-4 col-xl-3">
                <ViewValue
                    label={t('admin:customer_zone_auto.delivery_company')}
                    value={customerZone.data.deliveryCompany}
                />
              </div>}
              {/*Date of Dispatch*/}
              {![User.ROLE_GROUP_DEALER].includes(user.roleGroup)
              && <div className="col-12 col-sm-6 col-md-6 col-lg-4 col-xl-3">
                <ViewValue
                    label={t('admin:customer_zone_auto.dispatch_date')}
                    value={customerZone.data.dispatchDate?.format(Config.DATE_FORMAT)}
                />
              </div>}
              {/*Price for Delivery*/}
              {![User.ROLE_GROUP_DEALER].includes(user.roleGroup)
              && <div className="col-12 col-sm-6 col-md-6 col-lg-4 col-xl-3">
                <ViewValue
                    label={t('admin:customer_zone_auto.delivery_price')}
                    value={customerZone.data.deliveryPrice}
                />
              </div>}
              {/*Self Delivery*/}
              {![User.ROLE_GROUP_DEALER].includes(user.roleGroup)
              && <div className="col-12 col-sm-6 col-md-6 col-lg-4 col-xl-3">
                <ViewValue
                    label={t('admin:customer_zone_auto.self_delivery')}
                    value={noYesOptions.find(item => item.value === customerZone.data.selfDelivery)?.label}
                />
              </div>}
              {/*Promised to be Delivery to warehouse*/}
              {![User.ROLE_GROUP_DEALER].includes(user.roleGroup)
              && <div className="col-12 col-sm-6 col-md-6 col-lg-4 col-xl-3">
                <ViewValue
                    label={t('admin:customer_zone_auto.warehouse')}
                    value={customerZone.data.warehouse}
                />
              </div>}
              {/*Arrival date to Warehouse*/}
              <div className="col-12 col-sm-6 col-md-6 col-lg-4 col-xl-3">
                <ViewValue
                    label={t('admin:customer_zone_auto.warehouse_arrival_date')}
                    value={customerZone.data.warehouseArrivalDate?.format(Config.DATE_FORMAT)}
                />
              </div>
              {/*Notes for Delivery*/}
              {![User.ROLE_GROUP_DEALER].includes(user.roleGroup)
              && <div className="col-12 col-sm-6 col-md-6 col-lg-4 col-xl-3">
                <ViewValue
                    label={t('admin:customer_zone_auto.delivery_notes')}
                    value={customerZone.data.deliveryNotes}
                />
              </div>}
            </div>

            <div className="row">
              <div className="col-12 col-sm-6 col-md-6 col-lg-4 col-xl-3">
                {/*Odometer*/}
                <ViewValue
                    label={t('admin:customer_zone_auto.odometer')}
                    value={customerZone.data.odometer}
                />
              </div>
              <div className="col-12 col-sm-6 col-md-6 col-lg-4 col-xl-3">
                {/*Condition*/}
                <ViewValue
                    label={t('admin:customer_zone_auto.condition')}
                    value={customerZone.data.condition}
                />
              </div>
              <div className="col-12 col-sm-6 col-md-6 col-lg-4 col-xl-3">
                {/*Keys*/}
                <ViewValue
                    label={t('admin:customer_zone_auto.keys')}
                    value={noYesOptions.find(item => item.value === customerZone.data.keys)?.label}
                />
              </div>
              <div className="col-12 col-sm-6 col-md-6 col-lg-4 col-xl-3">
                {/*Title status*/}
                <ViewValue
                    label={t('admin:customer_zone_auto.title_status')}
                    value={titleStatusOptions.find(item => item.value === customerZone.data.titleStatus)?.label}
                />
              </div>
              <div className="col-12 col-sm-6 col-md-6 col-lg-4 col-xl-3">
                {/*Notes for Delivery*/}
                <ViewValue
                    label={t('admin:customer_zone_auto.auction_contact')}
                    value={customerZone.data.auctionContact}
                />
              </div>
              <div className="col-12 col-sm-6 col-md-6 col-lg-4 col-xl-3">
                {/*Notes for Title*/}
                <ViewValue
                    label={t('admin:customer_zone_auto.title_notes')}
                    value={customerZone.data.titleNotes}
                />
              </div>
              {/*Title PDF*/}
              {![User.ROLE_GROUP_DEALER].includes(user.roleGroup)
              && <div className="col-12 col-sm-6 col-md-6 col-lg-4 col-xl-3">
                <ViewFiles
                    label={t('admin:customer_zone_auto.title')}
                    value={customerZone.data.title}
                    originalKeyName={'original_name'}
                />
              </div>}
            </div>

            <div className="row">
              <div className="col-12 col-sm-6 col-md-6 col-lg-4 col-xl-3">
                {/*Invoice*/}
                <ViewValue
                    label={t('admin:customer_zone_auto.invoices')}
                    value={invoices && <ul>
                      {invoices.map(invoice => <li key={invoice.id}>
                        {`${customerZone.data.maker} ${customerZone.data.model} ${customerZone.data.year} - \
                        ${invoice.data.invoiceNumber}`}
                      </li>)}
                    </ul>}
                />
              </div>
              <div className="col-12 col-sm-6 col-md-6 col-lg-4 col-xl-3">
                {/*Payment date from Client*/}
                <ViewValue
                    label={t('admin:customer_zone_auto.client_payment_date')}
                    value={customerZone.data.clientPaymentDate?.format(Config.DATE_FORMAT)}
                />
              </div>
              <div className="col-12 col-sm-6 col-md-6 col-lg-4 col-xl-3">
                {/*Payment date to Auction*/}
                <ViewValue
                    label={t('admin:customer_zone_auto.auction_payment_date')}
                    value={customerZone.data.auctionPaymentDate?.format(Config.DATE_FORMAT)}
                />
              </div>
              <div className="col-12 col-sm-6 col-md-6 col-lg-4 col-xl-3">
                {/*Destination port*/}
                <ViewValue
                    label={t('admin:customer_zone_auto.destination_port')}
                    value={destinationPortsOptions.find(
                        item => item.value === customerZone.data.destinationPort)?.label}
                />
              </div>
              <div className="col-12 col-sm-6 col-md-6 col-lg-4 col-xl-3">
                {/*Notes*/}
                <ViewValue
                    label={t('admin:customer_zone_auto.notes')}
                    value={customerZone.data.notes}
                />
              </div>
            </div>

            <div className="row">
              <div className="col-12 col-sm-6 col-md-6 col-lg-4 col-xl-3">
                {/*Date Of Loading*/}
                <ViewValue
                    label={t('admin:customer_zone_auto.date_of_loading')}
                    value={customerZone.data.dateOfLoading?.format(Config.DATE_FORMAT)}
                />
              </div>
              <div className="col-12 col-sm-6 col-md-6 col-lg-4 col-xl-3">
                {/*Sail Date*/}
                <ViewValue
                    label={t('admin:customer_zone_auto.sail_date')}
                    value={customerZone.data.sailDate?.format(Config.DATE_FORMAT)}
                />
              </div>
              <div className="col-12 col-sm-6 col-md-6 col-lg-4 col-xl-3">
                {/*Container number*/}
                <ViewValue
                    label={t('admin:customer_zone_auto.container_number')}
                    value={customerZone.data.containerNumber}
                />
              </div>
              <div className="col-12 col-sm-6 col-md-6 col-lg-4 col-xl-3">
                {/*Booking Number*/}
                <ViewValue
                    label={t('admin:customer_zone_auto.booking_number')}
                    value={customerZone.data.bookingNumber}
                />
              </div>
              <div className="col-12 col-sm-6 col-md-6 col-lg-4 col-xl-3">
                {/*ETA*/}
                <ViewValue
                    label={t('admin:customer_zone_auto.eta')}
                    value={customerZone.data.eta?.format(Config.DATE_FORMAT)}
                />
              </div>
             {/* <div className="col-12 col-sm-6 col-md-6 col-lg-4 col-xl-3">*/}
                {/*Final destination arrival date*/}
                {/*<ViewValue
                    label={t('admin:customer_zone_auto.final_destination_arrival_date')}
                    value={customerZone.data.finalDestinationArrivalDate?.format(Config.DATE_FORMAT)}
                />
              </div>*/}
            </div>

            {/*<div className="row">
            <div className="col-12 col-sm-6 col-md-6 col-lg-4 col-xl-3">
              Wagon number
              <ViewValue
                  label={t('admin:customer_zone_auto.wagon_number')}
                  value={customerZone.data.wagonNumber}
              />
            </div>
            <div className="col-12 col-sm-6 col-md-6 col-lg-4 col-xl-3">
              Wagon loading place
              <ViewValue
                  label={t('admin:customer_zone_auto.wagon_loading_place')}
                  value={customerZone.data.wagonLoadingPlace}
              />
            </div>
            <div className="col-12 col-sm-6 col-md-6 col-lg-4 col-xl-3">
              Destination
              <ViewValue
                  label={t('admin:customer_zone_auto.wagon_destination')}
                  value={customerZone.data.wagonDestination}
              />
            </div>
            <div className="col-12 col-sm-6 col-md-6 col-lg-4 col-xl-3">
              Planned wagon lading
              <ViewValue
                  label={t('admin:customer_zone_auto.planned_wagon_lading')}
                  value={customerZone.data.plannedWagonLading?.format(Config.DATE_FORMAT)}
              />
            </div>
            <div className="col-12 col-sm-6 col-md-6 col-lg-4 col-xl-3">
              Wagon loading date
              <ViewValue
                  label={t('admin:customer_zone_auto.wagon_loading_date')}
                  value={customerZone.data.wagonLoadingDate?.format(Config.DATE_FORMAT)}
              />
            </div>
            <div className="col-12 col-sm-6 col-md-6 col-lg-4 col-xl-3">
              Wagon estimate arrival date
              <ViewValue
                  label={t('admin:customer_zone_auto.wagon_estimate_arrival_date')}
                  value={customerZone.data.wagonEstimateArrivalDate?.format(Config.DATE_FORMAT)}
              />
            </div>
            <div className="col-12 col-sm-6 col-md-6 col-lg-4 col-xl-3">
              Wagon arrival date
              <ViewValue
                  label={t('admin:customer_zone_auto.wagon_arrival_date')}
                  value={customerZone.data.wagonArrivalDate?.format(Config.DATE_FORMAT)}
              />
            </div>
            <div className="col-12 col-sm-6 col-md-6 col-lg-4 col-xl-3">
              Wagon notes
              <ViewValue
                  label={t('admin:customer_zone_auto.wagon_notes')}
                  value={customerZone.data.wagonNotes}
              />
            </div>
            <div className="col-12 col-sm-6 col-md-6 col-lg-4 col-xl-3">
              Wagon notes for yourself
              <ViewValue
                  label={t('admin:customer_zone_auto.wagon_notes_for_yourself')}
                  value={customerZone.data.wagonNotesForYourself}
              />
            </div>
          </div>*/}

            <div className="row">
              <div className="col-12 col-sm-6 col-md-6 col-lg-4 col-xl-3">
                {/*Tracking*/}
                <ViewValue
                    label={t('admin:customer_zone_auto.tracking')}
                    value={trackingOptions.find(item => item.value === customerZone.data.tracking)?.label}
                />
              </div>
              <div className="col-12 col-sm-6 col-md-6 col-lg-4 col-xl-3">
                {/*Final destination country*/}
                <ViewValue
                    label={t('admin:customer_zone_auto.final_destination_country')}
                    value={customerZone.data.finalDestinationCountry}
                />
              </div>
              <div className="col-12 col-sm-6 col-md-6 col-lg-4 col-xl-3">
                {/*Final destination city*/}
                <ViewValue
                    label={t('admin:customer_zone_auto.final_destination_city')}
                    value={customerZone.data.finalDestinationCity}
                />
              </div>
              <div className="col-12 col-sm-6 col-md-6 col-lg-4 col-xl-3">
                {/*Consolidation*/}
                <ViewValue
                    label={t('admin:customer_zone_auto.consolidation')}
                    value={consolidationOptions.find(item => item.value === customerZone.data.consolidation)?.label}
                />
              </div>
            </div>

            <div className="row">
              <div className="col-12 col-sm-6 col-md-6 col-lg-4 col-xl-3">
                {/*Photos Auction*/}
                <ViewFiles
                    label={t('admin:customer_zone_auto.photos_auction')}
                    value={customerZone.data.photosAuction}
                    originalKeyName={'original_name'}
                />
              </div>
              <div className="col-12 col-sm-6 col-md-6 col-lg-4 col-xl-3">
                {/*Photos Before Loading*/}
                <ViewFiles
                    label={t('admin:customer_zone_auto.photos_before_loading')}
                    value={customerZone.data.photosBeforeLoading}
                    originalKeyName={'original_name'}
                />
              </div>
              <div className="col-12 col-sm-6 col-md-6 col-lg-4 col-xl-3">
                {/*Photos After Uploading*/}
                <ViewFiles
                    label={t('admin:customer_zone_auto.photos_after_uploading')}
                    value={customerZone.data.photosAfterUploading}
                    originalKeyName={'original_name'}
                />
              </div>
            </div>

            {![User.ROLE_GROUP_DEALER].includes(user.roleGroup)
            && <div className="row">
              <div className="col-12 col-sm-6 col-md-6 col-lg-4 col-xl-3">
                {/*Dock receipt*/}
                <ViewFiles
                    label={t('admin:customer_zone_auto.dock_receipt')}
                    value={customerZone.data.dockReceipt}
                    originalKeyName={'original_name'}
                />
              </div>
              <div className="col-12 col-sm-6 col-md-6 col-lg-4 col-xl-3">
                {/*Bill of lading*/}
                <ViewFiles
                    label={t('admin:customer_zone_auto.bill_of_lading')}
                    value={customerZone.data.billOfLading}
                    originalKeyName={'original_name'}
                />
              </div>
              <div className="col-12 col-sm-6 col-md-6 col-lg-4 col-xl-3">
                {/*Bill of sale*/}
                <ViewFiles
                    label={t('admin:customer_zone_auto.bill_of_sale')}
                    value={customerZone.data.billOfSale}
                    originalKeyName={'original_name'}
                />
              </div>
              <div className="col-12 col-sm-6 col-md-6 col-lg-4 col-xl-3">
                {/*Carfax/Autocheck*/}
                <ViewFiles
                    label={t('admin:customer_zone_auto.carfax_autocheck')}
                    value={customerZone.data.carfaxAutocheck}
                    originalKeyName={'original_name'}
                />
              </div>
              <div className="col-12 col-sm-6 col-md-6 col-lg-4 col-xl-3">
                {/*Conosament*/}
                <ViewFiles
                    label={t('admin:customer_zone_auto.conosament')}
                    value={customerZone.data.conosament}
                    originalKeyName={'original_name'}
                />
              </div>
            </div>}

            <hr style={{marginTop: '29px'}}/>
          </form>
        </div>}
      </>
  );
};

export default CustomerZoneAutoForm;
