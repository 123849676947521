import axios from 'axios';
import Config from './Config';
import IDeparturePort from '../Interfaces/IDeparturePort';

export default class DeparturePort {

  // Return all
  async list(data: any = {}): Promise<IDeparturePort[]|false> {
    const response = (await axios.get(Config.api('/departure-port/list'), {params: data})).data;

    if (!response.success) {
      console.error(response.message);

      return false;
    }

    //  Skip empty
    if (!response.data)
      return false;

    const responseData = response.data;

    return responseData.map(item => IDeparturePort.parse(item));
  }

  // Get by ID
  async get(id): Promise<IDeparturePort> {
    const response = (await axios.get(Config.api(`/departure-port/get/${id}`))).data;

    if (!response.success) {
      console.error(response.message);

      return null;
    }

    //  Skip empty
    if (!response.data)
      return null;

    return IDeparturePort.parse(response.data);
  }
  
}
