import Auction from './Auction';

export default class Cars {

  public static readonly startYear = 1998;

  // Title statuses
  public static readonly titleStatuses = [
    // No
    '0',
    // Yes
    '1',
    // In progress
    'in_progress',
    // Pending
    'pending',
    'bos_only',


  ];

  // Return years of available cars
  public static years(): number[] {
    return [...Array((new Date()).getFullYear() - 1998 + 1)].map((item, i) => Cars.startYear + i).reverse();
  }

  // Return all auction
  public static auctions(): string[] {
    return [
      Auction.COPART,
      Auction.IAAI,
      Auction.MANHEIM,
      Auction.GR8AUTO,
      Auction.IMPACTAUTO,
    ];
  }

}
