import IUser from '../Interfaces/IUser';
import * as EgoUtil from '@egofoxlab/util';

export default class Auth {

  public user: IUser = new IUser();

  constructor() {
    let user;

    try {
      user = JSON.parse(localStorage.getItem('user'));
    } catch (ex) {

    }

    if (!user) {
      return;
    }

    this.user.token = user.token;
    this.user.role = user.role;
  }

  /**
   * Save auth data
   */
  save(
    data: {
      token: string,
    }, remember?: boolean
  ): void {
    let aliveTo = null;

    if (remember) {
      aliveTo = (new Date()).getTime() + 86400000;
    }

    localStorage.setItem('user', JSON.stringify({
      token: data.token,
      aliveTo: aliveTo
    }));
  }

  /**
   * Clear auth session
   */
  clear(): void {
    localStorage.removeItem('user');
  }

  /**
   * Check user auth
   */
  check(): boolean {
    return !EgoUtil.empty(localStorage.getItem('user'));
  }

  /**
   * Return API token
   */
  token(): string {
    return this.user.token;
  }

}
