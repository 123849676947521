import React, {FC} from 'react';
import './FormViewValue.scss';

const FormViewValue: FC<{
  className?: string;
  style?: any;
  label: string;
  value: any;
}> = (props) => {
  return (
    <div
      className={`form-view-value ${props.className}`}
      style={props.style}
    >
      <div className="label">
        {props.label}
      </div>
      <div className="value">
        {props.value}
      </div>
    </div>
  );
};

export default FormViewValue;
