import moment from 'moment';

export default class IShare {

  id: number;
  user: number;
  module: string;
  share: string;
  createdAt: string;
  updatedAt: string;

  public static parse(data: any): IShare {
    if (!data) {
      return null;
    }

    const share = new IShare();

    share.id = data.id ? parseInt(data.id, 10) : null;
    share.user = parseInt(data.user) || null;
    share.module = data.module || null;
    share.share = data.share || null;
    share.createdAt = data.created_at ? moment(data.created_at, 'YYYY-MM-DD HH:mm:ss').format('DD.MM.YYYY') : null;
    share.updatedAt = data.updated_at ? moment(data.created_at, 'YYYY-MM-DD HH:mm:ss').format('DD.MM.YYYY') : null;

    return share;
  }

}
