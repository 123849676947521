import React, {FC, useEffect, useRef, useState} from 'react';
import './Table.scss';
import {usePagination, useRowSelect, useTable} from 'react-table';
import {Table as MuiTable, TableBody, TableCell, TableContainer, TableHead, TableRow} from '@material-ui/core';
import Pagination from '../Pagintation/Pagination';
import Select from '../Select/Select';
import {useTranslation} from 'react-i18next';

export {default as useRowActions} from './useRowActions';
export {default as useRowSelection} from './useRowSelection';

const ToolbarTop: FC<{
  pageSizeSelect?: any,
  right?: any
}> = (props) => {
  const {t} = useTranslation();

  return (
    <div className="d-flex mb-3 flex-column flex-md-row justify-content-between align-items-center toolbar-top">
      <div className="d-inline-flex mb-3 mb-md-0 align-items-center page-size">
        <span>
          {t('table.page_sizes_show')}
        </span>
        <Select
          className="mx-2"
          hideLabel={true}
          style={{width: 80, marginBottom: 0}}
          select={props.pageSizeSelect}
        />
        <span>
          {t('table.page_sizes_rows')}
        </span>
      </div>
      <div className="toolbar-top-right">
        {props?.right ?? null}
      </div>
    </div>
  );
};

const ToolbarBottom: FC<{
  pageIndex: number;
  pageSize: number;
  pageCount: number;
  rowsCount: number;
  count: number;
  pagination?: any;
}> = (props) => {
  const {t} = useTranslation();
  const {
    pageIndex,
    pageCount,
    pagination
  } = props;

  return (
    <div className="d-flex flex-column flex-lg-row justify-content-between align-items-lg-center toolbar-bottom">
      <div className="d-inline-flex mb-3 mb-lg-0 justify-content-center justify-content-lg-start">
        {t('table.show_status')
          .replace('__pageSize_', String(props.rowsCount))
          .replace('__rowsCount__', String(props.count))}
      </div>
      {pageCount > 0 && <Pagination
          className="d-inline-flex m-0 flex-grow-1 justify-content-center justify-content-lg-end"
          page={(pageIndex + 1) > pageCount ? pageCount : pageIndex + 1}
          {...pagination}
      />}
    </div>
  );
};

function getPageSizesOptions(pageSizes = []) {
  return pageSizes.map(item => ({
    value: item,
    label: item
  }));
}

const Table: FC<{
  tableRef?: any;
  table: any;
  count: number;
  tableHooks?: any[];
  pageSizes?: number[];
  defaultPageSize?: number;
  fetchData?: any;
  loading?: boolean;
  toolbar?: {
    top?: {
      right?: JSX.Element
    }
  };
  onPageLen?: (pageLen: number) => void;
  onChange?: (tableData: any) => void
}> = (props) => {
  const {t} = useTranslation();
  const [pageSizes] = useState(props.pageSizes ?? [10, 20, 50, 100]);
  const defaultPageSize = useRef(props.defaultPageSize ?? 20).current;
  const [pageSizesOptions, setPageSizesOptions] = useState(getPageSizesOptions(pageSizes));
  const tableData = useTable(
    {
      ...props.table,
      initialState: {
        pageSize: defaultPageSize
      }
    },
    usePagination,
    useRowSelect,
    ...(props.tableHooks ?? [])
  );
  const tableRef = useRef();
  tableRef.current = tableData;

  if (props.tableRef)
    props.tableRef.current = tableRef.current;

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    page,
    prepareRow,
    pageCount,
    gotoPage,
    setPageSize,
    state: {pageIndex, pageSize},
  } = tableData;

  // On page length
  useEffect(() => {
    if (typeof props.onPageLen === 'function')
      props.onPageLen(page.length);
    // eslint-disable-next-line
  }, [page]);

  // Page sizes
  useEffect(() => {
    if (!props.pageSizes)
      return;

    setPageSizesOptions(getPageSizesOptions(props.pageSizes));
  }, [props.pageSizes]);

  // Fetch data when change page
  useEffect(() => {
    if (props.table.manualPagination !== true)
      return;

    if (typeof props.fetchData !== 'function')
      throw new Error('"fetchData" function property is required if you use "manualPagination"');

    props.fetchData({pageIndex, pageSize});
    // eslint-disable-next-line
  }, [props.fetchData, pageIndex, pageSize]);

  return (
    <div className="table-component">
      <ToolbarTop
        pageSizeSelect={{
          isSearchable: false,
          options: pageSizesOptions,
          defaultValue: pageSizesOptions?.find(item => item.value === defaultPageSize) ?? null,
          onChange: option => {
            setPageSize(option.value);
          }
        }}
        right={props.toolbar.top.right}
      />
      <div className="table">
        <TableContainer>
          <MuiTable {...getTableProps()}>
            <TableHead>
              {headerGroups.map(headerGroup => (
                <TableRow {...headerGroup.getHeaderGroupProps()}>
                  {headerGroup.headers.map(column => {
                    return (
                      <TableCell {...column.getHeaderProps({
                        className: column.className
                      })}>
                        {column.render('Header')}
                      </TableCell>
                    )
                  })}
                </TableRow>
              ))}
            </TableHead>
            <TableBody {...getTableBodyProps}>
              {page.map((row) => {
                prepareRow(row);

                return (
                  <TableRow {...row.getRowProps()}>
                    {row.cells.map(cell => {
                      return (<TableCell {...cell.getCellProps({
                        className: cell.column.className
                      })}>
                        {cell.render('Cell')}
                      </TableCell>)
                    })}
                  </TableRow>
                );
              })}
            </TableBody>
          </MuiTable>
        </TableContainer>

        {props.table.manualPagination === true && props.loading && <div className="table-loading">
          {t('table.loading')}
        </div>}
      </div>
      <ToolbarBottom
        pageIndex={pageIndex}
        pageSize={pageSize}
        pageCount={pageCount}
        rowsCount={page.length}
        count={props.count}
        pagination={{
          pagination: {
            count: pageCount,
            onChange: (e, page) => {
              gotoPage(page - 1);
            }
          }
        }}
      />
    </div>
  );
};

export default Table;
