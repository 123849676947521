import React, {FC, useCallback, useContext, useMemo, useRef, useState} from 'react';
import {useTranslation} from 'react-i18next';
import './Invoices.scss';
import InvoiceProvider from '../../Providers/Invoice';
import Config from '../../Providers/Config';
import AdminContext from '../AdminContext';
import IRole from '../../Interfaces/IRole';
import {UserPermission} from '../../Providers/UserPermission';
import Select, {SelectStylesHideIndicatorSeparator} from '../../Core/Form/Select/Select';
import Input from '../../Core/Form/Input/Input';
import Head from '../Head/Head';
import Textarea from '../../Core/Form/Textarea/Textarea';
import DownArrowIndicator from '../../Core/Form/Select/Indicators/DownArrowIndicator';
import {RowActionMenuItemEdit, RowActionMenuItemRemove} from '../../Core/Form/Table/useRowActions';
import Table, {useRowActions} from '../../Core/Form/Table/Table';
import {Link} from 'react-router-dom';

const invoiceProvider = new InvoiceProvider();

const Invoices: FC = () => {
  const {t} = useTranslation();
  const context = useContext(AdminContext);
    const [filter, setFilter] = useState<{
        vin?: string;
    }>({
        vin: '',
    });
  const columns = useMemo(() => {
    const columns = [
      {
        Header: t('admin:invoices.col_id'),
        accessor: 'id'
      },
      {
        Header: t('admin:invoices.invoice'),
        accessor: 'id',
        id: 'invoice-id',
        Cell: ({value}) => <a
          href={Config.api(`/invoices/get-pdf/${value}?api_token=${context.auth.user.token}`)}
          target={'blank'}
          className="btn th-action th-action-open"
        >
          {t('admin:invoices.invoice')}
        </a>
      },
      {
        Header: t('admin:invoices.date_of_create'),
        accessor: 'createdAt',
        Cell: ({value}) => value?.format(Config.DATE_FORMAT),
      },
      {
        Header: t('admin:invoices.date_of_update'),
        accessor: 'updatedAt',
        Cell: ({value}) => value?.format(Config.DATE_FORMAT),
      },
      {
        Header: t('admin:invoices.col_year_maker_model'),
        accessor: 'customerZoneAuto',
        id: 'col_year_order_type_maker_model',
        Cell: ({value}) => `${value.data.year} ${value.data.maker} ${value.data.model}`,
      },
      {
        Header: t('admin:invoices.vin'),
        accessor: 'customerZoneAuto.data.vin',
      },
    ];

    if (IRole.checkPermission(context.auth.user.role, [
      UserPermission.INVOICES_FULL
    ])) {
      columns.push({
        Header: t('admin:invoices.amount'),
        accessor: 'data.amount'
      });
    }

    return columns;
    // eslint-disable-next-line
  }, []);
  const [data, setData] = useState([]);
  const [pageCount, setPageCount] = useState(0);
  const [count, setCount] = useState(0);
  const fetchIdRef = useRef(0);
  const _tableRef = useRef<any>();
  const [loading, setLoading] = useState(false);
  const menuItems = useMemo(() => {
    const items = [];

    if (IRole.checkPermission(context.auth.user.role, [
      UserPermission.INVOICES_FULL
    ])) {
      // Edit
      items.push(<RowActionMenuItemEdit
        link={rowIndex => {
          const table = _tableRef.current;
          const row = table.page[rowIndex].original;

          return {
            to: `/admin/invoices/form/${row.id}`
          };
        }}
      />);

      // Remove
      items.push(<RowActionMenuItemRemove
          menuItem={{
            onClick: (e, rowIndex) => {
              const table = _tableRef.current;
              let pageIndex = table.state.pageIndex;
              const row = table.page[rowIndex].original;

              if (table.page.length === 1)
                pageIndex = --pageIndex < 0 ? 0 : pageIndex;

              invoiceProvider.delete(row.id).then(() => {
                fetchData({pageIndex, pageSize: table.state.pageSize});
              });
            }
          }}
      />);
    }

    return items;
    // eslint-disable-next-line
  }, []);

  // Get table data
  const fetchData = useCallback(({pageIndex, pageSize, vin =''}) => {
    const fetchId = ++fetchIdRef.current;
    setLoading(true);

    if (fetchId !== fetchIdRef.current)
      return;

    (async () => {
      const list = (await invoiceProvider.list({
        page_index: pageIndex,
        page_size: pageSize,
          vin: vin
      }));

      if (!list)
        return;

      setData(list.data);
      setCount(list.count);
      setPageCount(Math.ceil(list.count / pageSize));
      setLoading(false);
    })();
  }, []);

  return (
    <div id="invoices-component">
      <Head
        head={t('admin:invoices.head')}
        hr={{style: {marginTop: 0}}}
      >
        <div className="row table-filter">
          <div className="col-12 col-md-6 col-lg-4 col-xl-4">
            <Select
              hideLabel={true}
              select={{
                isSearchable: false,
                options: [
                  {
                    value: '1111',
                    label: '1111'
                  },
                  {
                    value: '2222',
                    label: '2222'
                  },
                ],
                placeholder: 'Номер лота',
                components: {
                  DropdownIndicator: DownArrowIndicator,
                },
                styles: SelectStylesHideIndicatorSeparator,
              }}
            />
          </div>
          <div className="col-12 col-md-6 col-lg-2 col-xl-2">
            <Select
              hideLabel={true}
              select={{
                isSearchable: false,
                options: [
                  {
                    value: '=',
                    label: '='
                  },
                  {
                    value: '>',
                    label: '>'
                  },
                  {
                    value: '<',
                    label: '<'
                  },
                ],
                defaultValue: {
                  value: '=',
                  label: '='
                },
                components: {
                  DropdownIndicator: DownArrowIndicator,
                },
                styles: SelectStylesHideIndicatorSeparator,
              }}
            />
          </div>
          <div className="col-12 col-md-6 col-lg-4 col-xl-4">
            <Input
              hideLabel={true}
              input={{
                placeholder: 'Значение'
              }}
            />
          </div>
          <div className="col-12 col-md-6 col-lg-2 col-xl-2">
            <Select
              hideLabel={true}
              select={{
                isSearchable: false,
                options: [
                  {
                    value: 'and',
                    label: 'И'
                  },
                  {
                    value: 'or',
                    label: 'ИЛИ'
                  },
                ],
                defaultValue: {
                  value: 'and',
                  label: 'И'
                },
                components: {
                  DropdownIndicator: DownArrowIndicator,
                },
                styles: SelectStylesHideIndicatorSeparator,
              }}
            />
          </div>
          <div className="col-12 col-md-6 col-lg-4 col-xl-4">
            <Select
              hideLabel={true}
              select={{
                isSearchable: false,
                options: [
                  {
                    value: 'all-invoices',
                    label: 'Все инвойсы'
                  },
                ],
                defaultValue: {
                  value: 'all-invoices',
                  label: 'Все инвойсы'
                },
                components: {
                  DropdownIndicator: DownArrowIndicator,
                },
                styles: SelectStylesHideIndicatorSeparator,
              }}
            />
          </div>
            <div className="col-12 col-md-6 col-lg-4 col-xl-3">
                <Input
                    hideLabel={true}
                    input={{
                        placeholder: t('admin:filter.vin'),
                        onChange: e => setFilter({...filter, vin: e.target.value})
                    }}
                />
            </div>
          <div className="col-12 col-md-6 col-lg-2 col-xl-2 d-flex justify-content-center justify-content-md-start">
              <button
                  className="btn btn-submit mb-control"
                  onClick={() => {
                      fetchData({pageIndex:0,pageSize:20,vin: filter.vin})
                  }}
              >
                  {t('buttons.search')}
              </button>
          </div>
        </div>

        <div className="row table-filter send-message">
          <div className="col-12 col-md-6 col-lg-4 col-xl-4">
            <Input
              label="Email"
              input={{
                type: 'email'
              }}
            />
          </div>
          <div className="col-12 col-md-6 col-lg-4 col-xl-4">
            <Textarea
              label="Текст письма"
              textarea={{
                rowsMin: 3,
                rowsMax: 3
              }}
            />
          </div>
          <div className="col-12 col-lg-4 col-xl-3 d-flex justify-content-center justify-content-lg-start align-items-start">
            <button className="btn btn-submit mb-control">
              ОТПРАВИТЬ ПИСЬМО
            </button>
          </div>
        </div>
      </Head>

      <Table
        tableRef={_tableRef}
        table={{
          columns,
          data,
          initialState: {pageIndex: 0},
          manualPagination: true,
          pageCount
        }}
        count={count}
        tableHooks={[
          useRowActions({
            menuItems
          }),
        ].reverse()}
        fetchData={fetchData}
        loading={loading}
        toolbar={{
          top: {
            right: <>
              {IRole.checkPermission(context.auth.user.role, [
                UserPermission.INVOICES_FULL
              ]) && <div className="row">
                <div className="col-12 d-flex justify-content-end train-buttons">
                  <Link
                      to={'/admin/invoices/form'}
                      className="btn btn-create"
                  >
                    {t('buttons.create')}
                  </Link>
                </div>
              </div>}
            </>
          }
        }}
      />
    </div>
  );
};

export default Invoices;
