import moment, {Moment} from 'moment';
import ICustomerZoneAuto from './ICustomerZoneAuto';

export default class ICustomerZoneBalance {

  id: number;
  customerZoneAutoId: number;
  data: {
    deliveryByLand: number;
    freight: number;
    agentReward: number;
    auctionStorageFee: number;
    storageFeeGr8: number;
    loadingCosts: number;
    lateFee: number;
    insurance: number;
    currentBalance: number;
    payedAmount: number;
    balance: number;
  };
  customerZoneAuto?: ICustomerZoneAuto;
  createdAt: Moment;
  updatedAt: Moment;

  public static parse(data: any): ICustomerZoneBalance {
    if (!data) {
      return null;
    }

    const customerZoneBalance = new ICustomerZoneBalance();

    customerZoneBalance.id = data.id ? parseInt(data.id, 10) : null;
    customerZoneBalance.customerZoneAutoId = data.customer_zone_auto_id ? data.customer_zone_auto_id : null;
    customerZoneBalance.data = {
      deliveryByLand: data.data?.['delivery_by_land'] ?? null,
      freight: data.data?.['freight'] ?? null,
      agentReward: data.data?.['agent_reward'] ?? null,
      auctionStorageFee: data.data?.['auction_storage_fee'] ?? null,
      storageFeeGr8: data.data?.['storage_fee_gr8'] ?? null,
      loadingCosts: data.data?.['loading_costs'] ?? null,
      lateFee: data.data?.['late_fee'] ?? null,
      insurance: data.data?.['insurance'] ?? null,
      currentBalance: data.data?.['current_balance'] ?? null,
      payedAmount: data.data?.['payed_amount'] ?? null,
      balance: data.data?.['balance'] ?? null,
    };
    customerZoneBalance.customerZoneAuto = ICustomerZoneAuto.parse(data['customer_zone_auto']);
    customerZoneBalance.createdAt = moment(data.created_at).isValid() ? moment(data.created_at) : null;
    customerZoneBalance.updatedAt = moment(data.updated_at).isValid() ? moment(data.updated_at) : null;

    return customerZoneBalance;
  }

}
