import React, {FC, useEffect, useState} from 'react';
import './SignUp.scss';
import {useDispatch} from 'react-redux';
import {useTranslation} from 'react-i18next';
import Input from '../../Core/Form/Input/Input';
import {Link} from 'react-router-dom';
import Checkbox from '../../Core/Form/Checkbox/Checkbox';
import FbButton from '../../Core/Form/FbButton/FbButton';
import axios from 'axios';
import Config from '../../Providers/Config';
import {IHttpResponse} from '../../Interfaces/IHttpResponse';
import Auth from '../../Providers/Auth';
import FacebookLogin from 'react-facebook-login/dist/facebook-login-render-props'
import Errors from '../../Core/Form/Errors/Errors';
import {InputLabel} from '@material-ui/core';
import setBgImg from '../../Core/Store/Actions/Login/setBgImg';

const SignUp: FC = () => {
  const {t} = useTranslation();
  const dispatch = useDispatch();
  const [firstName, setFirstName] = useState('');
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');
  const [confirmPasswordValid, setConfirmPasswordValid] = useState(false);
  const [confirmPasswordInvalidMessage, setConfirmPasswordInvalidMessage] = useState(null);
  const [errors, setErrors] = useState(null);

  useEffect(() => {
    dispatch(setBgImg('/assets/images/sign-up/sign-up-bg-1.jpg'));
    // eslint-disable-next-line
  }, []);

  /**
   * Check passwords
   */
  function checkPasswords(): void {
    let confirmPasswordValid = true;
    let confirmPasswordInvalidMessage = null;

    if (password !== confirmPassword) {
      confirmPasswordValid = false;
      confirmPasswordInvalidMessage = t('login:sign_up.passwords_not_equal');
    }

    setConfirmPasswordValid(confirmPasswordValid);
    setConfirmPasswordInvalidMessage(confirmPasswordInvalidMessage);
  }

  /**
   * Register with Facebook
   */
  function onFbCallback(response): void {
    axios.post(Config.api('/auth/sign-up'), {fbToken: response.accessToken})
      .then((_response) => {
        const response: IHttpResponse = _response.data;

        if (response.code !== 200) {
          console.error(response.message);
          setErrors(response.data);

          return;
        }

        (new Auth()).save({
          token: response.data.api_token,
        }, true);

        window.location.href = '/admin';
      });
  }

  /**
   * Register user
   */
  function onSubmit(e): void {
    e.preventDefault();

    axios.post(Config.api('/auth/sign-up'), {
      firstName,
      email,
      password,
      confirmPassword
    })
      .then((_response) => {
        const response: IHttpResponse = _response.data;

        if (response.code !== 200) {
          console.error(response.message);
          setErrors(response.data);

          return;
        }

        (new Auth()).save({
          token: response.data.api_token,
        });

        window.location.href = '/admin';
      });
  }

  return (
    <div id="sign-up">
      <div className="content">
        <div className="header">
          <h5 className="title">
            {t('login:sign_up.title')}
          </h5>
          <p className="subtitle">
            {t('login:sign_up.subtitle')}
          </p>
        </div>

        <form
          className="form"
          autoComplete="off"
          onSubmit={onSubmit}
        >
          {/*Name*/}
          <Input
            label={t('login:sign_up.name_label')}
            input={{
              name: 'firstname',
              id: 'firstname',
              placeholder: t('login:sign_up.name_label'),
              autoComplete: 'off',
              required: true,
              onChange: (e) => setFirstName(e.target.value)
            }}
          />

          {/*Email*/}
          <Input
            label="Email"
            input={{
              type: 'email',
              name: 'email',
              id: 'email',
              placeholder: 'john@gmail.com',
              autoComplete: 'off',
              required: true,
              onChange: (e) => setEmail(e.target.value)
            }}
          />

          {/*Password*/}
          <Input
            label={t('login:sign_up.password_label')}
            input={{
              type: 'password',
              name: 'password',
              id: 'password',
              placeholder: t('login:sign_up.password_placeholder'),
              autoComplete: 'off',
              required: true,
              onChange: (e) => setPassword(e.target.value),
              onBlur: checkPasswords
            }}
          />

          {/*Confirm Password*/}
          <Input
            label={t('login:sign_up.confirm_password_label')}
            error={!confirmPasswordValid}
            input={{
              type: 'password',
              name: 'confirm-password',
              id: 'confirm-password',
              placeholder: t('login:sign_up.confirm_password_placeholder'),
              autoComplete: 'off',
              required: true,
              onChange: (e) => setConfirmPassword(e.target.value),
              onKeyUp: checkPasswords,
              onBlur: checkPasswords
            }}
            components={{
              label: <div className="d-flex flex-grow-1">
                <InputLabel
                  shrink
                  htmlFor="bootstrap-input"
                >
                  {t('login:sign_in.password_label')}
                </InputLabel>
                <div className="error-text">
                  {confirmPasswordInvalidMessage}
                </div>
              </div>
            }}
          />

          <div>
            <Checkbox
              label={{
                label: <span className="agree-with">
                  {t('login:sign_up.agree_with')}&nbsp;
                  <a
                    href="https://gr8auto.com.ua/policy"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    {t('login:sign_up.agree_with_terms')}&nbsp;
                  </a>
                  {t('login:sign_up.agree_with_terms_and')}
                  <br />
                  <a
                    href="https://gr8auto.com.ua/policy"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    {t('login:sign_up.agree_with_privacy_policy')}
                  </a>
                </span>
              }}
              checkbox={{
                name: 'remember-me',
                required: true
              }}
            />
          </div>

          <Errors
            errors={errors}
            nested={true}
          />

          <div style={{marginBottom: '26px'}}>
            <button className="w-100 th-submit">
              {t('login:sign_up.sign_up_btn')}
            </button>
          </div>

          <div
            style={{
              textAlign: 'center',
              color: '#7f8fa4',
              fontFamily: 'Lato-Light',
              fontSize: '13.51px',
              marginBottom: '39px'
            }}
          >
            {t('login:sign_up.or_sign_up_with')}
          </div>

          {/*Facebook*/}
          <div className="text-center">
            <FacebookLogin
              appId={Config.FB_ID}
              autoLoad={false}
              callback={onFbCallback}
              render={renderProps => (
                <FbButton
                  onClick={renderProps.onClick}
                />
              )}
            />
          </div>

          <div className="exist-account">
            <span>
              {t('login:sign_up.exist_account')}
            </span>
            <Link to="/">
              {t('login:sign_up.exist_account_sign_in')}
            </Link>
          </div>
        </form>
      </div>
    </div>
  );
};

export default SignUp;
