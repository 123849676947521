import React, {FC, useEffect, useState} from 'react';
import {useTranslation} from 'react-i18next';
import Select, {SelectStylesHideIndicatorSeparator} from '../../Core/Form/Select/Select';
import Input from '../../Core/Form/Input/Input';
import DownArrowIndicator from '../../Core/Form/Select/Indicators/DownArrowIndicator';
import Cars from '../../Providers/Cars';
import User from '../../Providers/User';
import ISelectOption from '../../Interfaces/ISelectOption';
import DeparturePortProvider from '../../Providers/DeparturePort';
import IDeparturePort from '../../Interfaces/IDeparturePort';

const userProvider = new User();
const departurePortProvider = new DeparturePortProvider();

const CustomerZoneAutoFilter: FC<{
  onFilter?: (filter: any) => void
}> = (props) => {
  const {t} = useTranslation();
  const [departurePorts, setDeparturePorts] = useState<ISelectOption[]>();
  const [filter, setFilter] = useState<{
    departure_port?: number;
    vin?: string;
    lot?: string;
    container_number?: string;
    year_from?: number;
    year_to?: number;
    maker?: string;
    model?: string;
    dealer?: number;
  }>({
    year_from: Cars.years().reverse()[0],
    year_to: Cars.years()[0]
  });
  const [departurePort, setDeparturePort] = useState<any>();
  const [dealer, setDealer] = useState<any>();

  useEffect(() => {
    (async () => {
      setDeparturePorts((((await departurePortProvider.list()) ?? []) as IDeparturePort[]).map(item => ({
        value: item.id,
        label: `${item.displayCity}${item.displayState ? `, ${item.displayState}` : ''}`,
      })));
    })();
  }, []);

  // Search Dealer based on input
  const dealerLoadOptions = async input => {
    input = input ? input : '';

    // Min input length
    if (input && input.length < 2)
      return false;

    const response = await userProvider.search({group: User.ROLE_GROUP_DEALER, term: input});

    if (!response)
      return [];

    return response.map((item) => ({
      value: item.id,
      label: `${item.company} ${item.firstName} ${item.lastName} (ID: ${item.id})`
    }));
  };

  return (<>
    {departurePorts && <div className="row table-filter">
      <div className="col-12 col-md-6 col-lg-4 col-xl-3">
        <Select
            hideLabel={true}
            select={{
              placeholder: t('admin:filter.departure_port'),
              options: departurePorts,
              value: departurePort,
              components: {
                DropdownIndicator: DownArrowIndicator,
              },
              styles: SelectStylesHideIndicatorSeparator,
              onChange: option => {
                setDeparturePort(option);
                setFilter({...filter, departure_port: option.value});
              }
            }}
            required={true}
        />
      </div>
      <div className="col-12 col-md-6 col-lg-4 col-xl-3">
        <Input
            hideLabel={true}
            input={{
              placeholder: t('admin:filter.vin'),
              onChange: e => setFilter({...filter, vin: e.target.value})
            }}
        />
      </div>
      <div className="col-12 col-md-6 col-lg-4 col-xl-3">
        <Input
            hideLabel={true}
            input={{
              placeholder: t('admin:filter.lot_number'),
              onChange: e => setFilter({...filter, lot: e.target.value})
            }}
        />
      </div>
      <div className="col-12 col-md-6 col-lg-4 col-xl-3">
        <Input
            hideLabel={true}
            input={{
              placeholder: t('admin:filter.container_number'),
              onChange: e => setFilter({...filter, container_number: e.target.value})
            }}
        />
      </div>
      <div className="col-12 col-md-6 col-lg-4 col-xl-3 d-flex align-items-center table-filter-with-label">
        <span className="table-filter-label">
          {t('admin:filter.year_from')}
        </span>
        <Select
            hideLabel={true}
            select={{
              isSearchable: false,
              options: Cars.years().map((year) => ({
                value: year,
                label: year
              })),
              defaultValue: {
                value: Cars.years().reverse()[0],
                label: Cars.years().reverse()[0]
              },
              components: {
                DropdownIndicator: DownArrowIndicator,
              },
              styles: SelectStylesHideIndicatorSeparator,
              onChange: option => setFilter({...filter, year_from: option.value})
            }}
        />
      </div>
      <div className="col-12 col-md-6 col-lg-4 col-xl-3 d-flex align-items-center table-filter-with-label">
        <span className="table-filter-label">
          {t('admin:filter.year_to')}
        </span>
        <Select
            hideLabel={true}
            select={{
              isSearchable: false,
              options: Cars.years().map((year) => ({
                value: year,
                label: year
              })),
              defaultValue: {
                value: Cars.years()[0],
                label: Cars.years()[0]
              },
              components: {
                DropdownIndicator: DownArrowIndicator,
              },
              styles: SelectStylesHideIndicatorSeparator,
              onChange: option => setFilter({...filter, year_to: option.value})
            }}
        />
      </div>
      <div className="col-12 col-md-6 col-lg-4 col-xl-3">
        <Input
            hideLabel={true}
            input={{
              placeholder: t('admin:filter.maker'),
              onChange: e => setFilter({...filter, maker: e.target.value})
            }}
        />
      </div>
      <div className="col-12 col-md-6 col-lg-4 col-xl-3">
        <Input
            hideLabel={true}
            input={{
              placeholder: t('admin:filter.model'),
              onChange: e => setFilter({...filter, model: e.target.value})
            }}
        />
      </div>
      <div className="col-12 col-md-6 col-lg-4 col-xl-3">
        <Select
            hideLabel={true}
            async={true}
            select={{
              placeholder: t('admin:filter.dealer'),
              cacheOptions: true,
              loadOptions: dealerLoadOptions,
              defaultOptions: true,
              value: dealer,
              components: {
                DropdownIndicator: DownArrowIndicator,
              },
              styles: SelectStylesHideIndicatorSeparator,
              onChange: option => {
                setDealer(option);
                setFilter({...filter, dealer: option.value});
              }
            }}
            required={true}
        />
      </div>
      <div className="col-12 col-md-12 col-lg-3 col-xl-2 d-flex justify-content-center justify-content-lg-start">
        <button
            className="btn btn-submit mb-control"
            onClick={() => {
              if (typeof props.onFilter === 'function')
                props.onFilter(filter);
            }}
        >
          {t('buttons.search')}
        </button>
      </div>
    </div>}
  </>);
}

export default CustomerZoneAutoFilter;
