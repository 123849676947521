import {createStore, combineReducers} from 'redux';
import {SET_MOBILE_MENU} from './Actions/App/setMobileMenu';
import {SET_HEAD_NOTIFY} from './Actions/App/setHeadNotify';
import {SET_BG_IMG} from './Actions/Login/setBgImg';

// App Reducer
const appReducer = function (state = {
  mobileMenu: false,
  headNotify: null
}, action): any {
  switch (action.type) {
    case SET_MOBILE_MENU:
      state.mobileMenu = action.payload;

      return {...state};

    case SET_HEAD_NOTIFY:
      state.headNotify = action.payload;

      return {...state};

    default:
      return state;
  }
};

const loginReducer = (state = {
  bgImg: null
}, action): any => {
  switch (action.type) {
    case SET_BG_IMG:
      state.bgImg = action.payload;

      return {...state};

    default:
      return state;
  }
};

// Combine Reducers
const reducers = combineReducers({
  app: appReducer,
  login: loginReducer
});

const store = createStore(reducers);

export const mapStoreApp = (store) => {
  return {
    appStore: store.app
  };
};


export default store;
