import React, {FC} from 'react';
import './Pagination.scss';
import {usePagination, UsePaginationProps} from '@material-ui/lab/Pagination';

const Pagination: FC<{
  className?: string;
  page?: number;
  pagination: UsePaginationProps;
}> = (props) => {
  const {items} = usePagination(props.pagination);
  let displayTextPages = false;
  const selectedPage = props.page > 0 ? props.page : items.find(({selected}) => selected)?.page;

  return (
    <ul className={`pagination-component ${props.className ?? ''}`}>
      {items.map(({page, type, selected, ...item}, index) => {
        selected = page === selectedPage;
        let children;
        let textPages = null;

        if (type === 'start-ellipsis' || type === 'end-ellipsis') {
          children = <button className="ellipsis">-</button>;
        } else if (type === 'page') {
          children = (
            <button
              type="button"
              className={selected ? 'active' : ''}
              {...item}
            >
              {page}
            </button>
          );

          if (!displayTextPages)
            textPages = <li className="text-pages">
              <button>
                {`${selectedPage}/${props.pagination.count}`}
              </button>
            </li>;

          displayTextPages = true;
        } else {
          children = (
            <button
              type="button"
              className={type}
              {...item}
            >
              {type === 'previous' ? '‹' : '›'}
            </button>
          );
        }

        return <React.Fragment key={index}>
          {textPages}
          <li className={type}>{children}</li>
        </React.Fragment>;
      })}
    </ul>
  );
};

export default Pagination;
