import React, {FC} from 'react';
import {RadioGroup as RadioGroupComponent} from '@material-ui/core';

const RadioGroup: FC<any> = (props) => {
  return (
    <RadioGroupComponent {...props} />
  );
};

export default RadioGroup;
