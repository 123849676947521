import React, {FC, useCallback, useEffect, useRef, useState} from 'react';
import {useTranslation} from 'react-i18next';
import {Link, useHistory, useParams} from 'react-router-dom';
import './InvoicesForm.scss';
import Input from '../../Core/Form/Input/Input';
import axios from 'axios';
import Config from '../../Providers/Config';
import $ from 'jquery';
import {IHttpResponse} from '../../Interfaces/IHttpResponse';
import * as EgoUtil from '@egofoxlab/util';
import Notify from '../../Core/Ui/Notify/Notify';
import IInvoice from '../../Interfaces/IInvoice';
import Radio from '../../Core/Form/Radio/Radio';
import {Moment} from 'moment';
import Share from '../Share/Share';
import Head from '../Head/Head';
import BottomToolbar from '../BottomToolbar/BottomToolbar';
import {Accordion, AccordionDetails, AccordionSummary} from '@material-ui/core';
import RadioGroup from '../../Core/Form/RadioGroup/RadioGroup';
import CustomerZoneAutoProvider from '../../Providers/CustomerZoneAuto';
import InvoiceProvider from '../../Providers/Invoice';
import ICustomerZoneAuto from '../../Interfaces/ICustomerZoneAuto';
import KeyboardDatePicker from '../../Core/Form/Pickers/KeyboardDatePicker/KeyboardDatePicker';
import Select, {SelectStylesHideIndicatorSeparator} from '../../Core/Form/Select/Select';
import DownArrowIndicator from '../../Core/Form/Select/Indicators/DownArrowIndicator';

const invoiceProvider = new InvoiceProvider();
const customerZoneAutoProvider = new CustomerZoneAutoProvider();

const InvoicesForm: FC = () => {
  const {t} = useTranslation();
  const history = useHistory();
  const id = parseInt(useParams().id) || null;
  const [createCustomerZoneAuto, setCreateCustomerZoneAuto] = useState(null);
  const [customerZoneAutos, setCustomerZoneAutos] = useState<ICustomerZoneAuto[]>(null);
  const [invoice, setInvoice] = useState<IInvoice>();
  const [customerZoneAuto, setCustomerZoneAuto] = useState<ICustomerZoneAuto>();
  const [buyerId, setBuyerId] = useState('');
  const [serviceType, setServiceType] = useState('');
  const [status, setStatus] = useState('');
  const [stuffType, setStuffType] = useState('');
  const [invoiceDate, setInvoiceDate] = useState<Moment>();
  const [saveProcess, setSaveProcess] = useState(false);
  const [saveNotify, setSaveNotify] = useState(false);
  const _eForm = useRef<HTMLFormElement>(null);

  // Search Buyer ID based on input
  const buyerIdLoad = async id => {
    if (!(id > 0))
      return null;

    const valueResponse = (await axios.get(Config.api(`/buyer-id/search?id=${id}`))).data;
    const item = valueResponse.success ? valueResponse.data[0] : null;

    return item ? `${item.login} - ${t(`admin:auction.${item.auction}`)}` : null;
  };

  useEffect(() => {
    (async () => {
      // Existed invoice
      if (id > 0) {
        await load(id);
      } else { // New invoice
        setCustomerZoneAutos(((await customerZoneAutoProvider.list({})) as any).data ?? null);
      }
    })();
    // eslint-disable-next-line
  }, []);

  const load = useCallback(async (id: number) => {
    let invoice = id > 0 ? await invoiceProvider.get(id) : IInvoice.parse({});

    if (!invoice) {
      history.push('/admin/invoices');

      return;
    }

    const customerZoneAuto = await customerZoneAutoProvider.get(invoice.customerZoneAutoId);
    setCustomerZoneAuto(customerZoneAuto);
    setBuyerId(await buyerIdLoad(customerZoneAuto.buyerId));

    // Dates
    setInvoiceDate(invoice.data.invoiceDate);

    setServiceType(invoice.data.serviceType ?? null);
    setStatus(invoice.status);
    setStuffType(invoice.data.stuffType ?? null);
    setInvoice(invoice);
    // eslint-disable-next-line
  }, []);

  function reset(): void {
    // Clear input field
    EgoUtil.clearFields(_eForm.current);
    $(_eForm.current).find('[name]').trigger('change');
    // Selects
    setCreateCustomerZoneAuto(null);
  }

  // Create invoice
  async function onCreate(e) {
    if (!e.target.checkValidity())
      return;

    e.preventDefault();

    const response: IHttpResponse = (await axios.post(Config.api(`/invoices/create-from-customer-zone-auto`), {
      customer_zone_auto_id: createCustomerZoneAuto.value,
    })).data;

    if (!response.success) {
      console.error(response.message);
      // Unset `Save Process` flag
      setSaveProcess(false);

      return;
    }

    //  Skip empty
    if (!response.data)
      return false;

    // Mark as saved
    setSaveNotify(true);
    // Update url
    history.replace(`/admin/invoices/form/${response.data.id}`);
    // Unset `Save Process` flag
    setSaveProcess(false);
    await load(response.data.id);
  }

  // Save form
  function onSubmit(e): void {
    if (!_eForm.current.checkValidity())
      return;

    e.preventDefault();

    // Set `Save Process` flag
    //setSaveProcess(true);

    const eForm = $(_eForm.current);
    const formData = new FormData();
    // Status
    formData.append('status', status);
    // Service Type
    formData.append('service_type', serviceType);
    // First Name
    formData.append('first_name', eForm.find('[name="first-name"]').val());
    // Last Name
    formData.append('last_name', eForm.find('[name="last-name"]').val());
    // Country
    formData.append('country', eForm.find('[name="country"]').val());
    // City
    formData.append('city', eForm.find('[name="city"]').val());
    // Street
    formData.append('street', eForm.find('[name="street"]').val());
    // House
    formData.append('house', eForm.find('[name="house"]').val());
    // Flat
    formData.append('flat', eForm.find('[name="flat"]').val());
    // Stuff Type
    formData.append('stuff_type', stuffType);
    // Invoice Number
    formData.append('invoice_number', eForm.find('[name="invoice-number"]').val());
    // Invoice Date
    formData.append('invoice_date', invoiceDate?.isValid()
        ? invoiceDate.format(Config.DATE_FORMAT) : '');
    // Amount
    formData.append('amount', eForm.find('[name="amount"]').val());

    // Save form
    let url;

    if (invoice && invoice.id > 0) {
      url = Config.api(`/invoices/update/${invoice.id}`);
    } else {
      url = Config.api('/invoices/create');
    }

    axios.post(url, formData, {
      headers: {'Content-Type': 'multipart/form-data'},
    })
        .then((_response) => {
          const response: IHttpResponse = _response.data;

          if (response.code !== 200) {
            console.error(response.message);
            // Unset `Save Process` flag
            setSaveProcess(false);

            return;
          }
          // Mark as saved
          setSaveNotify(true);

          // Add invoice to state after saving
          if (!invoice || !(invoice.id > 0)) {
            history.replace(`/admin/invoices/form/${response.data.id}`);

            // Update invoices
            setInvoice(IInvoice.parse(response.data));
          }

          // Unset `Save Process` flag
          setSaveProcess(false);
        });
  }

  const head = <Head
      head={t('admin:invoices.form.head')}
      subhead={'VIN - Auto'}
      right={<div className="d-flex justify-content-lg-end justify-content-start train-buttons">
        <button
            form="invoices-form"
            className="btn btn-save"
            disabled={saveProcess}
        >
          {t('buttons.save')}
        </button>
        <button
            type="button"
            className="btn btn-reload"
            onClick={() => reset()}
        >
          {t('buttons.reset')}
        </button>
        <Link
            to={'/admin/invoices'}
            className="btn btn-close"
        >
          {t('buttons.close')}
        </Link>
      </div>}
      hr={{style: {marginTop: 20}}}
  />;

  const bottomToolbar = <BottomToolbar
      right={<>
        <div className="d-flex justify-content-lg-end justify-content-start train-buttons">
          <button
              form="invoices-form"
              className="btn btn-save"
              disabled={saveProcess}
          >
            {t('buttons.save')}
          </button>
          <button
              type="button"
              className="btn btn-reload"
              onClick={() => reset()}
          >
            {t('buttons.reset')}
          </button>
          <Link
              to={'/admin/invoices'}
              className="btn btn-remove"
          >
            {t('buttons.close')}
          </Link>
        </div>
        <div className="d-flex justify-content-end">
          <Notify
              type="save-form"
              hide={!saveNotify}
          />
        </div>
      </>}
  />;

  return (
      <div
          id="invoices-form-component"
          className={'invoices-component'}
      >
        {/*Create form*/}
        {!(id > 0) && <>
          {head}

          <form id="invoices-form" onSubmit={onCreate}>
            <Accordion defaultExpanded={true}>
              <AccordionDetails className="pt-0">
                <div className="d-flex flex-column flex-grow-1">
                  <div className="col-12 col-sm-6 col-md-6 col-lg-4 col-xl-3">
                    {/*Customer Zone Auto*/}
                    <Select
                        label={t('admin:invoices.select_customer_zone_auto')}
                        select={{
                          isSearchable: true,
                          options: customerZoneAutos?.map(item => ({
                            value: item.id,
                            label: `${item.data.vin} - ${item.data.maker} ${item.data.model} ${item.data.year}`,
                          })) ?? [],
                          value: createCustomerZoneAuto,
                          required: true,
                          components: {
                            DropdownIndicator: DownArrowIndicator,
                          },
                          styles: SelectStylesHideIndicatorSeparator,
                          onChange: option => setCreateCustomerZoneAuto(option),
                        }}
                        required={true}
                    />
                  </div>
                </div>
              </AccordionDetails>
            </Accordion>
          </form>

          {bottomToolbar}
        </>}

        {/*Edit form*/}
        {invoice && <>
          {head}

          <Accordion defaultExpanded={true}>
            <AccordionDetails className="pt-0">
              <div className="row d-flex flex-grow-1">
                <div className="col-12 col-sm-6 col-md-6 col-lg-4 col-xl-3">
                  {/*Auto*/}
                  <Input
                      label={t('admin:customer_zone_balance.auto')}
                      input={{
                        defaultValue:
                            `${customerZoneAuto.data.year} ${customerZoneAuto.data.maker} ${customerZoneAuto.data.model}`,
                        readOnly: true,
                      }}
                  />
                </div>
                <div className="col-12 col-sm-6 col-md-6 col-lg-4 col-xl-3">
                  {/*Buyer ID*/}
                  <Input
                      label={t('admin:customer_zone_balance.buyer_id')}
                      input={{
                        defaultValue: buyerId,
                        readOnly: true,
                      }}
                  />
                </div>
                <div className="col-12 col-sm-6 col-md-6 col-lg-4 col-xl-3">
                  {/*Lot*/}
                  <Input
                      label={t('admin:customer_zone_balance.lot')}
                      input={{
                        defaultValue: customerZoneAuto.data.lot,
                        readOnly: true,
                      }}
                  />
                </div>
                <div className="col-12 col-sm-6 col-md-6 col-lg-4 col-xl-3">
                  {/*VIN*/}
                  <Input
                      label={t('admin:customer_zone_balance.vin')}
                      input={{
                        defaultValue: customerZoneAuto.data.vin,
                        readOnly: true,
                      }}
                  />
                </div>
              </div>
            </AccordionDetails>
          </Accordion>

          <form ref={_eForm} id="invoices-form" onSubmit={onSubmit}>
            <Accordion defaultExpanded={true}>
              <AccordionDetails className="pt-0">
                <div className="d-flex flex-column flex-grow-1">
                  {/*Service Type*/}
                  <RadioGroup
                      className="row"
                      value={serviceType}
                      onChange={e => setServiceType(e.target.value)}
                  >
                    <div className="col-12">
                      <div className="row d-flex justify-content-center mb-3">
                        {/*<div className="item col-12 col-md-2">
                              <Radio
                                  type="radio"
                                  name="service-type"
                                  id="service-type-g&g"
                                  defaultValue="g&g"
                                  labelAfter="G&G"
                                  required={true}
                                  bottomOffset={0}
                              />
                          </div>*/}

                        {/*IAAI*/}
                        <div className="item col-12 col-md-2">
                          <Radio
                              label={{
                                label: 'IAAI',
                              }}
                              radio={{
                                name: 'service-type',
                                value: 'iaai',
                                required: true,
                              }}
                              bottomOffset={0}
                          />
                        </div>

                        {/*Copart*/}
                        <div className="item col-12 col-md-2">
                          <Radio
                              label={{
                                label: 'Copart',
                              }}
                              radio={{
                                name: 'service-type',
                                value: 'copart',
                                required: true,
                              }}
                              bottomOffset={0}
                          />
                        </div>

                        {/*GR8 Logistics*/}
                        <div className="item col-12 col-md-3">
                          <Radio
                              label={{
                                label: 'GR8 Logistics',
                              }}
                              radio={{
                                name: 'service-type',
                                value: 'gr8-logistics',
                                required: true,
                              }}
                              bottomOffset={0}
                          />
                        </div>

                        {/*GR8 Canada*/}
                        <div className="item col-12 col-md-3">
                          <Radio
                              label={{
                                label: 'GR8 Canada',
                              }}
                              radio={{
                                name: 'service-type',
                                value: 'gr8-auto-canada',
                                required: true,
                              }}
                              bottomOffset={0}
                          />
                        </div>
                      </div>
                    </div>
                  </RadioGroup>
                  {/*Status*/}
                  <RadioGroup
                      className="row"
                      value={status}
                      onChange={e => setStatus(e.target.value)}
                  >
                    <div className="col-12">
                      <div className="row d-flex justify-content-center">
                        <div
                            className="col-12 col-md-2 d-flex align-items-center justify-content-start justify-content-md-end">
                        <span style={{fontWeight: 700}}>
                          {t('admin:invoices.form.status')}:
                        </span>
                        </div>

                        {/*Open*/}
                        <div className="col-12 col-md-2">
                          <Radio
                              label={{
                                label: t('admin:invoices.status_open'),
                              }}
                              radio={{
                                name: 'status',
                                value: 'open',
                                required: true,
                              }}
                              bottomOffset={0}
                          />
                        </div>

                        {/*Close*/}
                        <div className="col-12 col-md-2">
                          <Radio
                              label={{
                                label: t('admin:invoices.status_close'),
                              }}
                              radio={{
                                name: 'status',
                                value: 'close',
                                required: true,
                              }}
                              bottomOffset={0}
                          />
                        </div>
                      </div>
                    </div>
                    <div className="col-12 col-sm-6 col-md-6 col-lg-4 col-xl-3">
                    </div>
                  </RadioGroup>
                </div>
              </AccordionDetails>
            </Accordion>

            {/*User Form*/}
            <Accordion defaultExpanded={true}>
              <AccordionSummary>
                {t('admin:invoices.form.personal_data')}
              </AccordionSummary>
              <AccordionDetails>
                <div className="row d-flex flex-grow-1">
                  <div className="col-12 col-sm-6 col-md-6 col-lg-4 col-xl-3">
                    {/*Name*/}
                    <Input
                        label={t('admin:invoices.create_invoice.name')}
                        input={{
                          name: 'first-name',
                          id: 'first-name',
                          defaultValue: invoice ? invoice.data.firstName : null,
                          placeholder: t('admin:invoices.create_invoice.letters'),
                          required: true,
                          inputProps: {
                            minLength: 2,
                            pattern: '[A-Za-z\\D]*',
                          },
                        }}
                    />
                  </div>
                  <div className="col-12 col-sm-6 col-md-6 col-lg-4 col-xl-3">
                    {/*Surname*/}
                    <Input
                        label={t('admin:invoices.create_invoice.surname')}
                        input={{
                          name: 'last-name',
                          id: 'last-name',
                          defaultValue: invoice ? invoice.data.lastName : null,
                          placeholder: t('admin:invoices.create_invoice.letters'),
                          required: true,
                          inputProps: {
                            minLength: 2,
                            pattern: '^[a-zA-Z0-9\\D]+$',
                          },
                        }}
                    />
                  </div>
                  <div className="col-12 col-sm-6 col-md-6 col-lg-4 col-xl-3">
                    {/*Country*/}
                    <Input
                        label={t('admin:invoices.create_invoice.country')}
                        input={{
                          name: 'country',
                          id: 'country',
                          defaultValue: invoice ? invoice.data.country : null,
                          placeholder: t('admin:invoices.create_invoice.latin'),
                          required: true,
                          inputProps: {
                            minLength: 1,
                            pattern: '^[a-zA-Z0-9\\D]+$',
                          },
                        }}
                    />
                  </div>
                  <div className="col-12 col-sm-6 col-md-6 col-lg-4 col-xl-3">
                    {/*City*/}
                    <Input
                        label={t('admin:invoices.create_invoice.city')}
                        input={{
                          name: 'city',
                          id: 'city',
                          defaultValue: invoice ? invoice.data.city : null,
                          placeholder: t('admin:invoices.create_invoice.latin'),
                          required: true,
                          inputProps: {
                            minLength: 1,
                            pattern: '^[a-zA-Z0-9\\D]+$',
                          },
                        }}
                    />
                  </div>
                  <div className="col-12 col-sm-6 col-md-6 col-lg-4 col-xl-3">
                    {/*Street*/}
                    <Input
                        label={t('admin:invoices.create_invoice.street')}
                        input={{
                          name: 'street',
                          id: 'street',
                          defaultValue: invoice ? invoice.data.street : null,
                          placeholder: t('admin:invoices.create_invoice.latin_and_numbers'),
                          required: true,
                          inputProps: {
                            minLength: 2,
                            pattern: '[A-Za-z0-9 \\D]*',
                          },
                        }}
                    />
                  </div>
                  <div className="col-12 col-sm-6 col-md-6 col-lg-4 col-xl-3">
                    {/*House*/}
                    <Input
                        label={t('admin:invoices.create_invoice.house')}
                        input={{
                          name: 'house',
                          id: 'house',
                          defaultValue: invoice ? invoice.data.house : null,
                          placeholder: t('admin:invoices.create_invoice.numbers'),
                          required: true,
                          inputProps: {
                            minLength: 1,
                          },
                        }}
                    />
                  </div>
                  <div className="col-12 col-sm-6 col-md-6 col-lg-4 col-xl-3">
                    {/*Flat*/}
                    <Input
                        label={t('admin:invoices.create_invoice.flat')}
                        input={{
                          name: 'flat',
                          id: 'flat',
                          defaultValue: invoice ? invoice.data.flat : null,
                          placeholder: t('admin:invoices.create_invoice.optional'),
                        }}
                    />
                  </div>
                  <div className="col-12 col-sm-6 col-md-6 col-lg-4 col-xl-3">
                    <RadioGroup
                        className="row flex-row justify-content-center"
                        value={stuffType}
                        onChange={e => setStuffType(e.target.value)}
                    >
                      <div className="col-12 field-label">
                        &nbsp;
                      </div>

                      {/*Car*/}
                      <div className="item col-6">
                        <Radio
                            label={{
                              label: t('admin:invoices.create_invoice.car'),
                            }}
                            radio={{
                              name: 'stuff-type',
                              value: 'car',
                            }}
                        />
                      </div>

                      {/*Delivery*/}
                      <div className="item col-6">
                        <Radio
                            label={{
                              label: t('admin:invoices.create_invoice.delivery'),
                            }}
                            radio={{
                              name: 'stuff-type',
                              value: 'delivery',
                            }}
                        />
                      </div>
                    </RadioGroup>
                  </div>
                </div>
              </AccordionDetails>
            </Accordion>

            {/*Invoice Form*/}
            <Accordion defaultExpanded={true}>
              <AccordionSummary>
                {t('admin:invoices.form.invoice_info')}
              </AccordionSummary>
              <AccordionDetails>
                <div className="row d-flex flex-grow-1">
                  <div className="col-12 col-sm-6 col-md-6 col-lg-4 col-xl-3">
                    {/*Invoice Number*/}
                    <Input
                        label={t('admin:invoices.create_invoice.invoice_number')}
                        input={{
                          name: 'invoice-number',
                          id: 'invoice-number',
                          defaultValue: invoice ? invoice.data.invoiceNumber : null,
                          placeholder: t('admin:invoices.create_invoice.letters_and_numbers'),
                          required: true,
                        }}
                    />
                  </div>
                  <div className="col-12 col-sm-6 col-md-6 col-lg-4 col-xl-3">
                    {/*Invoice Date*/}
                    <KeyboardDatePicker
                        label={t('admin:customer_zone_auto.auto_purchase_date')}
                        KeyboardDatePicker={{
                          value: invoiceDate,
                          required: true,
                          onChange: date => setInvoiceDate(date),
                        }}
                    />
                  </div>
                  <div className="col-12 col-sm-6 col-md-6 col-lg-4 col-xl-3">
                    {/*Amount*/}
                    <Input
                        label={t('admin:invoices.create_invoice.amount')}
                        input={{
                          name: 'amount',
                          id: 'amount',
                          defaultValue: invoice ? invoice.data.amount : null,
                          placeholder: '0.00',
                          required: true,
                          inputProps: {
                            pattern: '\\d+(\\.\\d{2})?',
                          },
                        }}
                    />
                  </div>
                </div>
              </AccordionDetails>
            </Accordion>
          </form>

          {/*Share*/}
          {invoice && invoice.id > 0 && <Share
              className="mb-3"
              share={invoice.id}
              module={'invoice'}
          />}

          {bottomToolbar}
        </>}
      </div>
  );
};

export default InvoicesForm;
