import React, {FC, useCallback, useContext, useMemo, useRef, useState} from 'react';
import {useTranslation} from 'react-i18next';
import './CustomerZoneBalance.scss';
import CustomerZoneBalanceProvider from '../../Providers/CustomerZoneBalance';
import AdminContext from '../AdminContext';
import IRole from '../../Interfaces/IRole';
import {UserPermission} from '../../Providers/UserPermission';
import Select, {SelectStylesHideIndicatorSeparator} from '../../Core/Form/Select/Select';
import Input from '../../Core/Form/Input/Input';
import Head from '../Head/Head';
import DownArrowIndicator from '../../Core/Form/Select/Indicators/DownArrowIndicator';
import {RowActionMenuItemEdit, RowActionMenuItemView} from '../../Core/Form/Table/useRowActions';
import Table, {useRowActions} from '../../Core/Form/Table/Table';
import Config from '../../Providers/Config';
import Cars from '../../Providers/Cars';
import ISelectOption from "../../Interfaces/ISelectOption";

const customerZoneBalanceProvider = new CustomerZoneBalanceProvider();

const CustomerZoneBalance: FC = () => {
  const {t} = useTranslation();
  const auctionOptions = Cars.auctions().map(item => ({
    value: item,
    label: t(`admin:auction.${item}`)
  }));
    const [filter, setFilter]= useState<{
        vin?: string;
    }>({
        vin: '',
    });
  const context = useContext(AdminContext);
  const columns = useMemo(() => [
    {
      Header: t('admin:customer_zone_balance.id'),
      accessor: 'id',
    },
    {
      Header: t('admin:customer_zone_balance.col_buyer_id'),
      accessor: 'customerZoneAuto.buyerId'
    },
    {
      Header: t('admin:customer_zone_balance.col_date_created'),
      accessor: 'createdAt',
      Cell: ({value}) => value?.format(Config.DATE_FORMAT) ?? ''
    },
    {
      Header: t('admin:customer_zone_balance.auto_purchase_date'),
      accessor: 'customerZoneAuto.data.autoPurchaseDate',
      Cell: ({value}) => value?.format(Config.DATE_FORMAT) ?? ''
    },
    {
      Header: t('admin:customer_zone_balance.col_year_maker_model'),
      accessor: 'customerZoneAuto.data',
      id: 'year_maker_model',
      Cell: ({value}) => `${value.year} ${value.maker} ${value.model}`
    },
    {
      Header: t('admin:customer_zone_balance.lot'),
      accessor: 'customerZoneAuto.data.lot',
    },
    {
      Header: t('admin:customer_zone_balance.vin'),
      accessor: 'customerZoneAuto.data.vin',
    },
    {
      Header: t('admin:customer_zone_balance.departure_port'),
      accessor: 'customerZoneAuto.id',
      id: 'departure_port',
      Cell: ({value}) => fetchDataRef.current.addons[value].departure_port ?? '',
    },
    {
      Header: t('admin:customer_zone_balance.auction'),
      accessor: 'customerZoneAuto.data.auction',
      Cell: ({value}) => auctionOptions.find(item => item.value === value)?.label,
    },
    {
      Header: t('admin:customer_zone_balance.col_purchase_city'),
      accessor: 'customerZoneAuto.id',
      id: 'purchase_city',
      Cell: ({value}) => fetchDataRef.current.addons[value].purchase_city ?? '',
    },
    {
      Header: t('admin:customer_zone_balance.col_auto_price'),
      accessor: 'customerZoneAuto.data.autoPrice',
    },
    {
      Header: t('admin:customer_zone_balance.delivery_by_land'),
      accessor: 'data.deliveryByLand',
    },
    // eslint-disable-next-line
  ], []);
  const fetchDataRef = useRef<any>();
  const [data, setData] = useState([]);
  const [pageCount, setPageCount] = useState(0);
  const [count, setCount] = useState(0);
  const fetchIdRef = useRef(0);
  const _tableRef = useRef<any>();
  const [loading, setLoading] = useState(false);
  const menuItems = useMemo(() => {
    const items = [];

    // Edit
    if (IRole.checkPermission(context.auth.user.role, [
      UserPermission.CUSTOMER_ZONE_BALANCES_FULL
    ])) {
      items.push(<RowActionMenuItemEdit
        link={rowIndex => {
          const table = _tableRef.current;
          const row = table.page[rowIndex].original;

          return {
            to: `/admin/customer-zone/balance/form/${row.id}`
          };
        }}
      />);
    }

    // View
    if (IRole.checkPermission(context.auth.user.role, [
      UserPermission.CUSTOMER_ZONE_BALANCES_READONLY
    ])) {
      items.push(<RowActionMenuItemView
        link={rowIndex => {
          const table = _tableRef.current;
          const row = table.page[rowIndex].original;

          return {
            to: `/admin/customer-zone/balance/view/${row.id}`
          };
        }}
      />)
    }

    return items;
    // eslint-disable-next-line
  }, []);

  // Get table data
  const fetchData = useCallback(({pageIndex, pageSize,vin =''}) => {
    const fetchId = ++fetchIdRef.current;
    setLoading(true);

    if (fetchId !== fetchIdRef.current)
      return;

    (async () => {
      const list = (await customerZoneBalanceProvider.list({
        page_index: pageIndex,
        page_size: pageSize,
        vin: vin
      }));

      if (!list)
        return;

      fetchDataRef.current = list;
      setData(list.data);
      setCount(list.count);
      setPageCount(Math.ceil(list.count / pageSize));
      setLoading(false);
    })();
  }, []);

  return (
    <div id="customer-zone-balance-component">
      <Head
        head={t('admin:customer_zone_balance.head')}
        hr={{style: {marginTop: 0}}}
      >
        <div className="row table-filter">
          <div className="col-12 col-md-6 col-lg-4 col-xl-4">
            <Select
              hideLabel={true}
              select={{
                isSearchable: false,
                options: [
                  {
                    value: '2019',
                    label: '2019'
                  },
                  {
                    value: '2020',
                    label: '2020'
                  },
                ],
                placeholder: 'Год выпуска авто',
                components: {
                  DropdownIndicator: DownArrowIndicator,
                },
                styles: SelectStylesHideIndicatorSeparator
              }}
            />
          </div>
          <div className="col-12 col-md-6 col-lg-2 col-xl-2">
            <Select
              hideLabel={true}
              select={{
                isSearchable: false,
                options: [
                  {
                    value: '=',
                    label: '='
                  },
                  {
                    value: '>',
                    label: '>'
                  },
                  {
                    value: '<',
                    label: '<'
                  },
                ],
                defaultValue: {
                  value: '=',
                  label: '='
                },
                components: {
                  DropdownIndicator: DownArrowIndicator,
                },
                styles: SelectStylesHideIndicatorSeparator
              }}
            />
          </div>
          <div className="col-12 col-md-6 col-lg-4 col-xl-4">
            <Input
              hideLabel={true}
              input={{
                placeholder: 'Значение'
              }}
            />
          </div>
          <div className="col-12 col-md-6 col-lg-2 col-xl-2">
            <Select
              hideLabel={true}
              select={{
                isSearchable: false,
                options: [
                  {
                    value: 'and',
                    label: 'И'
                  },
                  {
                    value: 'or',
                    label: 'ИЛИ'
                  },
                ],
                defaultValue: {
                  value: 'and',
                  label: 'И'
                },
                components: {
                  DropdownIndicator: DownArrowIndicator,
                },
                styles: SelectStylesHideIndicatorSeparator
              }}
            />
          </div>

           <div className="col-12 col-md-6 col-lg-4 col-xl-3">
                <Input
                    hideLabel={true}
                    input={{
                        placeholder: t('admin:filter.vin'),
                        onChange: e => setFilter({...filter, vin: e.target.value})
                    }}
                />
            </div>
          <div className="col-6">
            <button className="btn btn-create mb-control">
              ДОБАВИТЬ
            </button>
          </div>
          <div className="col-6 d-flex justify-content-end">
           <button
                  className="btn btn-submit mb-control"
                  onClick={() => {
                      fetchData({pageIndex:0,pageSize:20,vin: filter.vin})
                  }}
              >
                  {t('buttons.search')}
              </button>

           {/* <button className="btn btn-submit mb-control">
              {t('buttons.search')}
            </button>*/}
          </div>
        </div>
      </Head>

      <Table
        tableRef={_tableRef}
        table={{
          columns,
          data,
          initialState: {pageIndex: 0},
          manualPagination: true,
          pageCount
        }}
        count={count}
        tableHooks={[
          useRowActions({
            menuItems
          }),
        ].reverse()}
        fetchData={fetchData}
        loading={loading}
        toolbar={{
          top: {
            right: <>
              {IRole.checkPermission(context.auth.user.role, [
                UserPermission.CUSTOMER_ZONE_BALANCES_FULL
              ]) && <div className="row">
                <div className="col-12 d-flex justify-content-end train-buttons" />
              </div>}
            </>
          }
        }}
      />
    </div>
  );
}

export default CustomerZoneBalance;
