import React from 'react';
import './Tooltip.scss';
import {Tooltip} from '@material-ui/core';

const TooltipComponent = (props) => {
  return (
    <div className="tooltip-component">
      <div className="tooltip-wrap">
        <Tooltip
          {...props}
          enterTouchDelay={props.enterTouchDelay ? props.enterTouchDelay : 0}
          leaveTouchDelay={props.leaveTouchDelay ? props.leaveTouchDelay : 30000}
        >
          <img
            src={'/assets/images/icon-info.svg'}
            alt={'info'}
            className={'icon-info'}
          />
        </Tooltip>
      </div>
    </div>
  );
};

export default TooltipComponent;
