import axios from 'axios';
import Config from './Config';
import IDestinationPort from '../Interfaces/IDestinationPort';

export default class DestinationPort {

  // Return all
  async list(data: any = {}): Promise<IDestinationPort[]|false> {
    const response = (await axios.get(Config.api('/destination-port/list'), {params: data})).data;

    if (!response.success) {
      console.error(response.message);

      return false;
    }

    //  Skip empty
    if (!response.data)
      return false;

    const responseData = response.data;

    return responseData.map(item => IDestinationPort.parse(item));
  }

}
